import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AppConfigService } from "../app-config/app-config.service";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class PropertyService {
  readonly apiUrl = `${this.config.serverUrl}/Property`;

  constructor(private http: HttpClient, private config: AppConfigService) {}

  public getByCustomrId(
    customerId: number,
    ownerId: number,
    IsForLeasingDirector: boolean = null,
    EntityTypeId: number = null
  ): Observable<any> {
    const url = `${this.apiUrl}/GetByCustomerId/${customerId}/${IsForLeasingDirector}/${EntityTypeId}`;
    const httpOpts = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        tid: ownerId.toString(),
      }),
    };
    return this.http.get<any>(url, httpOpts);
  }

  public getByUserId(userId: number, ownerId: number): Observable<any> {
    const url = `${this.apiUrl}/GetByUserId/${userId}`;
    const httpOpts = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        tid: ownerId.toString(),
      }),
    };
    return this.http.get<any>(url, httpOpts);
  }
}
