export class AuditableEntity {

    createdBy: string;
    createdOn: Date;
    modifiedBy: string;
    modifiedOn: Date | null;
    isDeleted: boolean;

    public constructor(init?: Partial<AuditableEntity>) {
        Object.assign(this, init);
    }
}
