import { AfterViewInit, Component, EventEmitter, NgModule, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
//import { AuthenticationService } from '@app/services/authentication/authentication.service';
// import { CustomersService } from '@app/services/customers/customers.service';
// import { LookupService } from '@app/services/lookup/lookup.service';
import { PlaidService } from 'src/app/services/plaid/plaid.service';
// import { AppConstants } from '@app/shared/app-constants/AppConstants';
 import { AccountType } from '../../app/shared/enum/account-type';
// import { LookupCategory } from '@app/shared/enums/lookup-category';
// import { OwnerWelcomeWizardStatus } from '@app/shared/enums/owner-welcome-wizard-status';
// import { Lookup } from '@app/shared/models/lookup/lookup.model';
// import { ImpersonatedUser, User } from '@app/shared/models/user/user.model';
//import { environment } from '../../../src/environments/environment';
import {AppConfigService} from  "../../app/services/app-config/app-config.service";

//import { NotificationService } from '@progress/kendo-angular-notification';

import { PlaidConfig } from 'src/app/bank-validation-setup/interface';
import { PlaidLinkHandler } from 'src/app/bank-validation-setup/ngx-plaid-link-handler';
import { NgxPlaidLinkService } from 'src/app/bank-validation-setup/ngx-plaid-link.service';

import { LeaseService } from '../services/lease/lease.service';
import { NgxPlaidLinkModule } from 'ngx-plaid-link/lib/ngx-plaid-link.module';

declare var Stripe: any;
declare var $: any;


@Component({
  selector: 'app-bank-validation-setup',
  templateUrl: './bank-validation-setup.component.html',
  styleUrls: ['./bank-validation-setup.component.scss']
})


export class BankValidationSetupComponent implements AfterViewInit, OnInit {
  @Output() valueChange = new EventEmitter();
  private plaidLinkHandler: PlaidLinkHandler;
  public accountAdded: boolean = false;
  public stepStatus: number;
  public step2Completed: boolean = false;
  public hideMsg: boolean = true;
  public config: PlaidConfig = {
    apiVersion: 'v2',
    env: 'sandbox',
    token: null,
    webhook: this.configService.plaidWebhookUrl,// 'https://devpayments.renttango.com/Plaid/Plaid_Verification',
    product: ['auth'],
    key: this.configService.plaidKey,//'8b2920c5dd49fb9b363ccc8e38eb40',
    onSuccess: (token: any, metadata: any) => this.onSuccess(token, metadata),
    onExit: (error: any, metadata: any) => this.onExit(error, metadata),
    onEvent: (eventName: any, metadata: any) => this.onEvent(eventName, metadata)
  };


  private customerId: number;
  public userName: string = "";
   public user: any;
  public initial = "";
  public PlaidAccountId: string;
  public plaid_publicToken: string;
  public HEADER_TANENT_ID: string = "tid";
  public BankId: string;
  public token: string;
  public msg: string = "";
  public isAlreadyExists: boolean;
  public addEditForm: FormGroup;
  // countryStates: Lookup[] = [];
  filteredStates: any[] = [];
  countries: any[] |null = null;
  submitted: boolean;
  country: string;
  public ownerId: number = 0;
  isFrameHide: boolean = false;
  isError: boolean = true;
  public totaltopay: number=0;
  public personId:number;
  public plaidWebhookUrl = `${this.configService.plaidWebhookUrl}`;
  public plaidKey = `${this.configService.plaidKey}`;
  public stripeKey = `${this.configService.stripeKey}`;
  constructor(
  //  private authService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private plaidApi: PlaidService, private plaidLinkService: NgxPlaidLinkService,
    // private api: CustomersService,
    //private notificationService: NotificationService,
    private formBuilder: FormBuilder,
    // private lookupService: LookupService,
    private leaseservice: LeaseService,
    private configService: AppConfigService


  ) {
    
    this.customerId =this.router.getCurrentNavigation()!.extras!.state!.cId;
    this.ownerId = this.router.getCurrentNavigation()!.extras!.state!.oId;
    this.totaltopay = Number((this.router.getCurrentNavigation()!.extras!.state!.example));
    this.personId =  this.router.getCurrentNavigation()!.extras!.state!.pId;
    sessionStorage.setItem("previouUrl", this.router.url);
   }


   ngOnInit() {
    
      // this.tenantID = this.router.getCurrentNavigation().extras.state.tId;
      // this.ownerId = this.router.getCurrentNavigation().extras.state.oId;
      // this.leaseId = this.router.getCurrentNavigation().extras.state.leaseId;
      // this.detaildata = this.router.getCurrentNavigation().extras.state.detaildata;
      

    this.isFrameHide = false;
    this.initializeForm();
    
    this.user=  this.leaseservice.getCustomerInfo(this.customerId, this.ownerId).subscribe(response => {
this.user= response;
if(this.user){
  this.userName = this.user.companyName;
    this.initial = this.userName;//this.user.firstName.substring(0, 1) + this.user.lastName.substring(0, 1);
    this.onLoad();
    //-- strip configuration for credit card integration -----------------

    // if (this.user.accountTypeId == AccountType.Owner) {
    //   this.ownerId = this.user.ownerId;
    // }

    var stripe = Stripe(this.stripeKey);

}

    });



    //this.user = //this.authService.CurrentUser;
    

  }

  ngAfterViewInit(): void {
     
    // if (this.stepStatus === 1) {
    //   this.plaidLinkService.createPlaid(Object.assign({}, this.config, {
    //     onSuccess: (token: any, metadata: any) => this.onSuccess(token, metadata),
    //     onExit: (error: any, metadata: any) => this.onExit(error, metadata),
    //     onEvent: (eventName: any, metadata: any) => this.onEvent(eventName, metadata)
    //   })).then((handler: PlaidLinkHandler) => {
    //     this.plaidLinkHandler = handler;
    //     this.open();
    //   });
    // }
    // else if (this.stepStatus === 2) {
    //   this.accountAdded = true;

    // }
  }

  open() {

    this.plaidLinkHandler.open();
  }

  exit() {

    this.plaidLinkHandler.exit();
  }

  // public nextPage() {
  //   this.api.UpdateCustomerWelcomeStep(OwnerWelcomeWizardStatus.Step3, this.user.customerId, this.user.customerStatusId, this.ownerId).subscribe(response => {
  //     window.location.href = AppConstants.Urls.OnboardingStep3;
  //   });
  // }

  // private showNotification(message: string): void {

  //   this.notificationService.show({
  //     content: message,
  //     cssClass: 'toast-notification',
  //     animation: { type: 'slide', duration: 400 },
  //     position: { horizontal: 'center', vertical: 'top' },
  //     type: { style: 'success', icon: true },
  //     hideAfter: 5000
  //   });
  // }

  public initializeForm() {
    this.addEditForm = this.formBuilder.group({
      name: ['', Validators.required],
      countryId: ['', Validators.required],
      address: ['', Validators.required],
      city: ['', Validators.required],
      state: ['', Validators.required],
      zipCode: ['', Validators.required]
    });
  }

  public get formFields() {
    return this.addEditForm.controls;
  }

  public  async getcustomerinfo(){

    
  }
  // private loadCountries() {
  //   this.lookupService.getCountryList().subscribe(response => {
  //     this.countries = response;
  //     this.countries.unshift({ name: 'Select One', lookupId: '' });

  //   });
  // }
  // private loadStates() {
  //   this.lookupService.getByCategoryId(LookupCategory.StateType).subscribe(response => {
  //     this.countryStates = response.lookups;
  //   });
  // }

  // public filterStates(countryId: number) {
  //   if (countryId) {
  //     this.filteredStates = this.countryStates.filter(x => x.parentLookupId == countryId);
  //   }
  //   else {
  //     this.filteredStates = [];
  //   }
  //   this.filteredStates.unshift({ name: 'Select One', lookupId: '' });
  // }

  // public onCountryChange(countryId: number) {
  //   this.formFields.state.setValue('');
  //   this.formFields.state.reset();
  //   if (countryId) {
  //     this.filterStates(countryId);
  //   }
  //   else {
  //     this.filteredStates = [];
  //   }
  // }

  public onStateChange(stateId: number) {
    this.formFields.state.setValue(stateId);
  }

  onSubmit() {
    this.submitted = true;
    this.addEditForm.updateValueAndValidity();
    if (this.addEditForm.invalid) {
      return false;
    }
  }

  public reloadPage() {
    
    if (this.isError) {
      this.createPlaid(null);
    }
    else {
      this.onLoad();
    }
    this.hideMsg = true;
  }

  onLoad() {
    this.plaidApi.getPublicTokenByCustomerId(this.customerId).subscribe(respone => {
      this.createPlaid(respone.public_token);
    },
      (error) => {
        console.log(error);
      });
  }

  createPlaid(token: any) {
    this.config.token = token;
    this.plaidLinkService.createPlaid(Object.assign({}, this.config, {
      onSuccess: (token: any, metadata: any) => this.onSuccess(token, metadata),
      onExit: (error: any, metadata: any) => this.onExit(error, metadata),
      onEvent: (eventName: any, metadata: any) => this.onEvent(eventName, metadata)
    })).then((handler: PlaidLinkHandler) => {
      this.plaidLinkHandler = handler;
      this.open();
    });
  }

  onSuccess(token: string, metadata: any) {

    
    console.log('plaid completed successfully.');
    console.log('metadata: '); console.log(metadata);
    this.BankId = metadata.account_id;
    var status = metadata.account.verification_status;
    this.token = token;

    this.plaidApi.CreateCustomerAndBankInfo(this.BankId, this.token, this.customerId,this.ownerId, status, this.user.userId,this.totaltopay, this.personId).subscribe(respone => {

        if (respone.Success) {
            // parent.location.reload();
            console.log(respone);
            this.hideMsg = true;
        }
        else {//if the response.Success==false;
          // if (respone.errorMessage == "account_already_exist") {
          //   parent.location.href = 'billing?exists=true';
          // }
          // else if (respone.errorMessage == "pending_manual_verification"  ) {
          //   parent.location.href = 'billing?pmv=1';
          // }
          // else if (respone.errorMessage == "pending_automatic_verification") {
          //   parent.location.href = 'billing?pamv=1';
          // }
          // else //if(respone.Status.startsWith("unexpected_error"))
          // {
          //   parent.location.href = 'billing?error=true';
          // }

        }
      });
    /*
    );
  }, response => {
    if(response.error =="The provided account number already exists")
    {
      this.isAlreadyExists = true;
      //parent.location.reload();
      
      parent.location.href = 'billing?exists=' + this.isAlreadyExists;
      this.hideMsg = true;
    }
  }
  
  */

  }

  onEvent(eventName: string, metadata: string) {
    console.log("We got an event:", eventName);
    console.log("We got metadata:", metadata);
  }

  onExit(error: any, metadata: any) {
    console.log("We exited:", error);
    console.log("We got metadata:", metadata);

    this.isFrameHide = true;
    this.isError = true;
    if (error == null) {
      this.isError = false;
      if (metadata.status == "requires_credentials" || metadata.status == "institution_not_found") {

        this.isError = true;
        // this.reloadPage();
      }
      // parent.location.reload();
      // return;
    }

    if (error != null) {
      if (error.error_code == "INCORRECT_DEPOSIT_AMOUNTS") {
        this.isError = false;
      }
      // window.parent.document.getElementById("mainIframe").style.display = "none" ;
      // window.parent.document.getElementById("bankAcctDetail").style.display = "block" ;
      // parent.location.reload();
    }




    //window.parent.document.getElementById("bankAcctDetail").style.display = "block" ;
    //window.document.getElementById("form-signUp").style.display = "none" ;

    //  $(id).removeAttr('hidden');
    // this.hideMsg = false;
    // this.msg = "Your bank information is required to use RentTango. Contact your account executive if you have any questions.";

  }

}
