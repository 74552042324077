import { AuditableEntity } from '../auditable-entity/auditable-entity.model';

export class Brokerage extends AuditableEntity {
    brokerageId: number
    name: string;
    phone1: string;
    phone2: string;
    email1: string;
    email2: string;
    address1: string;
    address2: string;
    location:string;
    zipCode: string;
    city: string;
    state: string;
    countryId: number;
    countryCode: string;
    fax: string;
    website: string;
    business: string;
    flag: number;
    propertyId:number;
    cutomerId:number;
    public constructor(init?: Partial<Brokerage>) {
        super(init);
        Object.assign(this, init);
    }
}