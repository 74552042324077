import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfigService } from '../app-config/app-config.service';

@Injectable({
  providedIn: 'root'
})
export class ApplicantService {
  readonly apiUrl = `${this.config.serverUrl}/Applicant`;
  constructor(private http: HttpClient, private config: AppConfigService) { }

  public SubmitApplicantByBroker(applicants: any, ownerId: number): Observable<any> {

    const url = `${this.apiUrl}/SubmitApplicantByBroker?applicants=${applicants}`;

    const httpOpts = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'tid': ownerId.toString()
      })
    };
    return this.http.get(url, httpOpts);
  }

  public removeApplicant(applicantId: number, screeningGroupId: number,ownerId:number, customerId: number, actorId: number, actorName: string): Observable<any> {
    const url = `${this.apiUrl}/RemoveApplicant?applicantId=${applicantId}&screeningGroupId=${screeningGroupId}&customerId=${customerId}&actorId=${actorId}&actorName=${actorName}`;
    const httpOpts = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'tid': ownerId.toString()
      })
    };
    return this.http.get(url, httpOpts);
  }
  public removeBrokerApplicant(applicantId: number,ownerId:number): Observable<any> {
    const url = `${this.apiUrl}/DeleteBorkerApplicant?applicantId=${applicantId}`;
    const httpOpts = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'tid': ownerId.toString()
      })
    };
    return this.http.get(url, httpOpts);
  }
}
