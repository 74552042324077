import { Component, OnInit, Input, Output, EventEmitter, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss']
})
export class ContextMenuComponent implements OnInit {

  public showUpPointer = true;
  public show = false;
  public offset: any;
  public currentElement: Element;

  public marginLeft: number;

  @Input()
  public width: number = 120;

  @Input() public set mouseEvent(mouseEvent: MouseEvent) {

    this.unsubscribeAnchorClick();
    this.subscribeAnchorClick(mouseEvent);
  }

  @Input()
  public data: any[] = [];

  @Input()
  public displayField: string;

  @Output()
  public select: EventEmitter<any> = new EventEmitter<any>();

  private unsubscribeWindowClick: any;
  private unsubscribeMouseWheelMove: any;

  constructor(private renderer: Renderer2) {
    this.subscribeWindowClick();
    this.subscribeMouseWheelMove();
  }

  public ngOnInit() {
  }

  public ngOnDestroy(): void {
    this.unsubscribe();
  }

  public menuItemSelected(item: any): void {

    this.select.emit(item);
  }

  private subscribeWindowClick(): void {

    this.unsubscribeWindowClick = this.renderer.listen('window', 'mouseup', ($event) => {

      if ($event.target.className.search('context-menu') !== -1) {
        return;
      }

      setTimeout(() => {
        this.show = false;
      }, 200);
    });
  }

  private subscribeAnchorClick(mouseEvent: MouseEvent) {

    if (mouseEvent && mouseEvent.currentTarget) {

      this.currentElement = mouseEvent.currentTarget as Element;

      this.currentElement.addEventListener<any>('click', ($event: MouseEvent) => {

        const windowHeight = window.innerHeight - $event.pageY;
        const menuHeight = (this.data.length * 40) + 40;
        const pageX = $event.pageX || mouseEvent.pageX;
        const pageY = $event.pageY || mouseEvent.pageY;

        if (menuHeight > windowHeight) {

          this.showUpPointer = false;
          this.offset = { left: pageX - this.width + 20, top: pageY - menuHeight + 7 };
        }
        else {
          this.offset = { left: pageX - this.width + 20, top: pageY + 20 };
          this.showUpPointer = true;
        }

        this.show = true;
      });

      (this.currentElement as HTMLElement).click();
    }
  }

  private unsubscribeAnchorClick() {
    if (this.currentElement) {
      this.currentElement.removeEventListener('click', () => { });
    }
  }

  private subscribeMouseWheelMove(): void {

    this.unsubscribeMouseWheelMove = this.renderer.listen('document', 'wheel', () => {
      this.show = false;
    });
  }

  private unsubscribe(): void {

    this.unsubscribeWindowClick();
    this.unsubscribeMouseWheelMove();
    this.unsubscribeAnchorClick();
  }
}
