import { AuditableEntity } from '../auditable-entity/auditable-entity.model';

export class BrokerContact extends AuditableEntity {
    brokerageId: number;
    brokerContactId: number;
    brokerId: number;
    customerId: number;
    ownerId: number;
    mobilePhone: string;
    phone1: string;
    phone2: string;
    email1: string;
    email2: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    countryId: number;
    countryCode: string;
    fax: string;
    website: string;
    business: string;
    flag: number;
    location: string;
    zipCode: string;
    isActive: number;
    brokerContactStatusId: number;
    public constructor(init?: Partial<BrokerContact>) {
        super(init);
        Object.assign(this, init);
    }
}
