import { Injectable } from '@angular/core';
// import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {AppConfigService} from  "../app-config/app-config.service";
declare var $: any;




@Injectable({
  providedIn: 'root'
})
export class SmartyStreetService {
public apiUrl:string="";
  constructor(private http : HttpClient,private configService:AppConfigService) {
    this.apiUrl = `${this.configService.serverUrl}/SearchAddress`;
   }

  public getSmartyStreetAddresses(address : string) : Observable<any>{
    const url = `${this.apiUrl}?address=${address}`
    return this.http.get<any>(url);
  }
  public async getSmartyStreetAddressesNew(address: string) {
    let result;
    try {
      result = await $.ajax({
        url:
          "https://us-autocomplete-pro.api.smartystreets.com/lookup?key=29026049131270615&search=" +
          address +
          "&prefer_geolocation=city&source=all",
        // data: {
        //  // "auth-id": "ea1a5e3a-3edc-6116-6519-d95bd8a182ee",
        //   key: 29026049131270615,
        //   search: address,
        //   //"auth-token": "krRlMmhopZYlUKKANLok",
        //   prefer_geolocation: "city",
        //   source: "all",
        // },
        dataType: "jsonp",
        type: "GET",
      });

      return result;
    } catch (error) {
      console.error(error);
    }
  }
  // public async getSmartyStreetAddressesNew(address: string) {
  
  //   let result;
  //   try {
  //     result = await $.ajax({
  //       url: "https://us-autocomplete-pro.api.smartystreets.com/lookup?",
  //       data: {
  //         "auth-id": `${this.configService.smartyStreet.authId}`,
  //         key: this.configService.smartyStreet.key,
  //         search: address,
  //         "auth-token": `${this.configService.smartyStreet.authToken}`,
  //         prefer_geolocation: "city",
  //         source: "all",
  //       },
  //       dataType: "jsonp",
  //       type: "GET",
  //     });

  //     return result;
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }


  public getSmartyStreetLongitudeLatitude(street : string, City: string, State: string, zipCode: string) : Observable<any>{
    const url = `${this.apiUrl}/AutoFillLongitudeLatitude?street=${street}&city=${City}&state=${State}&zipcode=${zipCode}`
    return this.http.get<any>(url);
  }

  public getSmartyStreetAddressN(address: string): Observable<any> {
    const url = `${this.apiUrl}/AutoFillUSAddress?address=${address}`;
    return this.http.get<any>(url);
  } 
  
}
