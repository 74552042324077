<app-multi-select-menu [anchor]="screeningProgress" [displayField]="'displayText'" [data]="progressItems"
    (select)="onProgressFilter($event)" [width]="200" [initialSelected]="initialSelectedProgressItems"
    *ngIf="checkData==true" id="status-filter-menu">
</app-multi-select-menu>

<app-context-menu [displayField]="'text'" [data]="actionItems" (select)="onActionPerform($event)" [width]="160"
    [mouseEvent]="mouseEvent">
</app-context-menu>
<app-multi-select-menu [anchor]="leasingProgress" [displayField]="'displayText'" [data]="leaseStatuses"
    (select)="onLeaseFilter($event)" [width]="200" [initialSelected]="initialSelectedStatusItems"
    *ngIf="checkData==true" id="status-filter-menu">
</app-multi-select-menu>

<app-nav-menu></app-nav-menu>

<div kendoDialogContainer></div>
<div class="brokerPortal">
    <div class="container-fluid h-100 p-0 screening-lister">
        <div class="contentHolder-NoHeight pb-0">
            <div class="pageHeader">
                <h1 class="pageTitle">Broker Portal</h1>
                <button type="button" id="btnAddNew" (click)="onChangeAppartment()" class="btn paymentBtn">
                    <img src="../../../assets/images/icon_addNewField.svg"> New Application</button>
            </div>
            <div class="leftArea border-bottom-1 custom-tab">
                <a class="active" [routerLink]="[]" id="lnkBrokerActivity">
                    All ({{countShownInTab}})
                </a>
                <div class="searchArea mr-4 search-group-prepend"  [ngClass]="searchTxt && searchTxt !== '' ? 'input-focus' : ''">
                    <div class="inner has-search d-flex">
                        <img src="assets/images/icon_zoom.svg" alt="search" class="img-fluid form-control-search" />
                        <input type="text" placeholder="Search" id="txtSearchData" kendoTextBox class="input-search"
                            (input)="onKeywordFilter()" #txtSearch />
                    </div>
                </div>
            </div>
            <!--  -->
            <div class="tabStripWrapper">

                <div class="grid-container batch-list">
                    <div class="grid-item">
                        <label class="label cursor-pointer" id="lblName">Name</label>
                        <!-- <span class="k-icon custom-sort" #nameSpanElem></span> -->
                    </div>
                    <div class="grid-item">
                        <label (click)="sortGrid(lastActionSpanElem, ['modifiedOn'])" class="label cursor-pointer"
                            id="lblLastAction">Last Action</label>
                        <span class="k-icon custom-sort k-i-sort-desc-sm" #lastActionSpanElem></span>
                    </div>
                    <div class="grid-item">
                        <label class="label" id="lblScreeningProgress">Screening Status
                            <strong *ngIf="selectedProgressItemList.length > 0">
                                ({{selectedProgressItemList.length}})
                            </strong>
                            <strong *ngIf="selectedProgressItemList.length === 0">&nbsp;&nbsp;&nbsp;&nbsp;</strong>
                            &nbsp;
                            <i class="fa fa-ellipsis-v cursor-pointer rt-theme-color" #screeningProgress
                                id="screeningProgress"></i>
                        </label>
                        
                    </div>
                    <div class="grid-item">
                        <label class="label" id="lblLeasingProgress">Lease Status <strong
                                *ngIf="selectedStatusItemsList.length > 0">
                                ({{selectedStatusItemsList.length}})</strong>
                            <strong *ngIf="selectedStatusItemsList.length === 0">&nbsp;&nbsp;&nbsp;&nbsp;</strong>
                            &nbsp;<i class="fa fa-ellipsis-v cursor-pointer rt-theme-color" #leasingProgress></i>
                        </label>
                    </div>
                    <div class="grid-item">
                        <label class="label" id="lblAction">Action</label>
                    </div>
                    <div class="grid-item">
                        <label (click)="sortGrid(addressSpanElem, ['propertyName', 'propertyAddress'])" id="lblAddress"
                            class="label cursor-pointer">Address</label>
                        <span class="k-icon custom-sort" #addressSpanElem></span>
                    </div>
                </div>
                <!-- <kendo-tabstrip>
                    <kendo-tabstrip-tab title="Open ({{countShownInTab}})" [selected]="true"> -->
                <div class="gridArea" (window:resize)="onResize($event)">
                    <!-- <kendo-grid [data]="gridView" [hideHeader]="true" [height]="innerHeight" id="dgGrid" [pageSize]="pageSize" (scrollBottom)="loadMore()"> -->

                    <kendo-grid [data]="gridView" [hideHeader]="true" id="dgGrid" [height]="innerHeight" [pageSize]="pageSize"
                        (scrollBottom)="loadMore()">

                        <kendo-grid-column field="screeningGroupId" hidden="true" title="ID" width="40">
                        </kendo-grid-column>

                        <kendo-grid-column field="applicantFullName" title="">
                            <ng-template kendoGridCellTemplate let-dataItem>
                                <div class="listWrapper">
                                    <ul>
                                        <li
                                            [ngClass]="getRowClassesForRecommendation(dataItem.recommendedStatusId, dataItem.decisionStatusId)">
                                            <div class="tbName d-flex align-items-center">
                                                <div class="w-100">
                                                    <ul>
                                                        <ng-container
                                                            *ngFor="let applicant of dataItem.applicants; let i = index;">
                                                            <li [class.m-0]="i === 0" class="cursor-pointer"
                                                                (click)="navigateToOpportunity(dataItem.opportunityId, applicant.personId)">
                                                                <div class="applicant-name d-flex">
                                                                    {{ applicant.fullName }}
                                                                    <div class="d-inline-blcok ml-2">
                                                                        <span *ngIf="applicant?.applicantTypeId===2002"
                                                                        class="bgBrownRust">G</span>
                                                                        <span *ngIf="applicant?.applicantTypeId===2003"
                                                                        class="char-tag-red">O</span>
                                                                    </div>
                                                                </div>

                                                                <ng-container *ngIf="applicant.email.length > 35">
                                                                    <div class="applicant-email">
                                                                        {{applicant.email.substring(0,
                                                                        applicant.email.indexOf("@"))}}
                                                                    </div>
                                                                    <div class="applicant-email">
                                                                        {{applicant.email.substring(applicant.email.indexOf("@"))}}
                                                                    </div>
                                                                </ng-container>

                                                                <ng-container *ngIf="applicant.email.length < 36">
                                                                    <div class="applicant-email">
                                                                        {{applicant.email}}</div>
                                                                </ng-container>
                                                            </li>
                                                        </ng-container>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="tbLastAction d-flex align-items-center">
                                                <div>
                                                    <ul>
                                                        <ng-container
                                                            *ngFor="let applicant of dataItem.applicants; let i = index;">
                                                            <li [class.m-0]="i === 0">
                                                                {{ applicant.modifiedOn | date : 'moments' }}
                                                            </li>
                                                        </ng-container>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="tbProgress d-flex align-items-center">
                                                <div>
                                                    <ul>
                                                        <ng-container
                                                            *ngFor="let applicant of dataItem.applicants; let i = index;">
                                                            <li [class.m-0]="i === 0">
                                                                <div class="float-left">
                                                                    <div class="placeholder screening-progressbar">
                                                                        <kendo-progressbar [label]="labelSettings"
                                                                            [value]="applicant.applicationProgress">
                                                                        </kendo-progressbar>
                                                                    </div>
                                                                </div>
                                                                &nbsp;
                                                                <span>{{getAppProgress(applicant.applicationProgress)}}</span>
                                                                <!-- <span>{{ getScreenStatus(applicant.screeningStatusId) }}</span> -->
                                                            </li>
                                                        </ng-container>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="tbProgress d-flex align-items-center">
                                                <div>
                                                    <ul>
                                                        <ng-container
                                                            *ngFor="let applicant of dataItem.applicants; let i = index;">
                                                            <li [class.m-0]="i === 0" *ngIf="applicant.workflowId===1 && dataItem.leaseStatusId != 35001 && dataItem.leaseStatusId != 35002 && dataItem.leaseStatusId != 35009">
                                                                <div class="float-left">
                                                                    <div class="placeholder" [ngClass]="getProgressBarColorUpdated(dataItem.leaseStatusId)">
                                                                        <kendo-progressbar [label]="labelSettings"
                                                                            [value]="getLeaseStatusPercent(dataItem.leaseStatusId)">
                                                                        </kendo-progressbar>
                                                                    </div>
                                                                </div>
                                                                &nbsp;
                                                                <span
                                                                    *ngIf="dataItem.leaseStatusId!=35007 && dataItem.leaseStatusId!=35008">{{getLeaseStatusPercent(dataItem.leaseStatusId)}}%</span>
                                                                {{getLeseStatusName(dataItem.leaseStatusId)}}
                                                            </li>
                                                            <!-- <li [class.m-0]="i === 0" *ngIf="applicant.workflowId===0">
                                                                <div class="float-left">
                                                                    <div class="placeholder progress-sushi">
                                                                        <kendo-progressbar [label]="labelSettings"
                                                                            [value]="0">
                                                                        </kendo-progressbar>
                                                                    </div>

                                                                </div>
                                                                &nbsp; <span>0%</span>
                                                            </li> -->
                                                        </ng-container>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="tbAction d-flex align-items-start">
                                                <ul>
                                                    <ng-container
                                                        *ngFor="let applicant of dataItem.applicants; let i = index;">
                                                        <li [class.m-0]="i === 0">
                                                            <span *ngIf="applicant.workflowId==0 || applicant.applicationProgress == 20"
                                                                class="icon fas fa-ellipsis-h context-menu rt-theme-color"
                                                                (mouseup)="showMenu($event, applicant,dataItem)"
                                                                #spanElem></span>
                                                        </li>
                                                    </ng-container>
                                                </ul>
                                                <!-- <span class="icon fas fa-ellipsis-h context-menu rt-theme-color"></span> -->
                                            </div>
                                            <div (click)="openDetail(dataItem.screeningGroupId)"
                                                class="tbAddress d-flex align-items-center justify-content-between cursor-pointer">
                                                <div>
                                                    <span class="bgBlueTag">{{ dataItem.unitNumber?
                                                        dataItem.unitNumber : '-' }}</span>
                                                    <div class="description">
                                                        <label class="lable">{{dataItem.propertyName}}</label>
                                                        <p>{{ dataItem.propertyAddress}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div (click)="openDetail(dataItem)"
                                                [ngClass]="getLeaseStatusClass(dataItem.leaseStatusId)"
                                                class="tbStatus d-flex align-items-center">
                                                <div class="text-center m-auto">
                                                    <span *ngIf="dataItem.leaseStatusId==35001"
                                                        [ngClass]="getIconClassesForRecommendation(dataItem.recommendedStatusId, dataItem.decisionStatusId)"></span>
                                                    <img *ngIf="dataItem.leaseStatusId!=35001"
                                                        [src]="getLeaseStatusIconPath(dataItem.leaseStatusId)"
                                                        class="img-small" />
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </ng-template>
                        </kendo-grid-column>
                    </kendo-grid>
                </div>
                <!-- </kendo-tabstrip-tab>
                </kendo-tabstrip> -->
            </div>
        </div>
        <div class="d-md">
            <p class="text-signing">Signing</p>
        </div>
    </div>
</div>