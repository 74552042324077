import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { debug } from 'console';
import { param } from 'jquery';
import { filter } from 'rxjs/internal/operators';
import { VerificationService } from '../services/verification/verification.service';
import { UtilityMethods } from "../../helper/utility-methods";
import { BrokerService } from '../services/broker/broker.service';
import { BrokerContactService } from '../services/broker-contact/broker-contact.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormSettingService } from '../services/form-setting/form-setting.service';
import { AppConfigService } from '../services/app-config/app-config.service';
import {Location} from '@angular/common';

declare var $: any;
let emailVerify = false;
let phoneVerify = false;
var iti="";

@Component({
  selector: 'app-broker-verification',
  templateUrl: './broker-verification.component.html',
  styleUrls: ['./broker-verification.component.scss']
})
export class BrokerVerificationComponent implements OnInit {
  public addEditForm: FormGroup;
  public customerId: number = 0;
  public ownerId: number = 0;
  public brokerId: number = 0;
  public applicantid: number = 0;
  public emailcodetoverify: number = 0;
  public smscodetoverify: number = 0;
  public pid: number = 0;
  public signerId: number = 0;
  public count: number = 0;
  public companycolor: string | null = "#880e4f";
  public prevousURL: string = "";
  public companyLogo: string | null = "assets/images/image.png";
  public userName: string | null = "";
  public initials: string | null = "";
  public mask = "(999) 000-0000";
  public brokenContractId: number = 0;
  public submitted: boolean = false;
  public baseUrl:string="";
  public companyColor:string="#880e4f" ;
  public vStatus:string="";
  public myVar:any;
  public fontColor:string ='white';
  public threshold :number=3;
  public companyName:string='';
  public companyPhone :string='';
  public leasingOfficePhone : string = "";
  public leasingOfficeEmail : string = "";

  // public phone:string="";

  @HostListener('window:unload', [ '$event' ])
    unloadHandler(event) {
      
      var onCloseEvent = event.currentTarget.onclose;
      if(onCloseEvent){
        sessionStorage.removeItem("verifiedstatus")
      }
    }


  constructor(private route: ActivatedRoute, private verificationservice: VerificationService,
    private router: Router, private formBuilder: FormBuilder, private utilityMethods: UtilityMethods,
     private brokerContactService: BrokerContactService,
     private formApi:FormSettingService,
     private configService:AppConfigService,
     private spinner: NgxSpinnerService,
     private location: Location,
     private brokerApi: BrokerService, ) {
    
  }

  ngOnInit() {
    ;
    this.initializeForm();

    setTimeout(() => {
      this.companycolor = localStorage.getItem("companyColor");      
      this.userName = localStorage.getItem("userName");
      this.initials = localStorage.getItem("initials");
    }, 3000);

    $('#emailCode1').pincodeInput({
      hidedigits: false, inputs: 6, complete: function (value: any, e = null, errorElement = null) {
        $("#pincode-callback").html("Complete callback from 6-digit test: Current value: " + value);

      }
    });
    $('#smsCode1').pincodeInput({
      hidedigits: false, inputs: 6, complete: function (value: any, e = null, errorElement = null) {
        $("#pincode-callback").html("Complete callback from 6-digit test: Current value: " + value);

      }
    });


    this.route.queryParams.subscribe(params => {
      
      this.customerId = Number(params['cid']);
      this.ownerId = Number(params['oid']);
      //snid is basically brokerid named as bid
      this.brokerId = Number(params['bid']);
      this.pid = Number(params['pid'] == undefined ? 0 : params['pid']);
      this.brokenContractId = Number(params["bcid"])
      this.companyLogo = "assets/images/image.png";
      // this.vStatus= Boolean(params["verifiedstatus"]);
      this.vStatus= params["dv"]
      var brokerInfo={cid:this.customerId,oid:this.ownerId,pid:this.pid,brid:this.brokerId,bcid:this.brokenContractId }
      sessionStorage.setItem("brokerInfo", JSON.stringify(brokerInfo));
      sessionStorage.setItem("brokerid", this.brokerId.toString());
   
      this.brokerApi.updateCallConnection(this.brokerId, this.ownerId, this.customerId, false, null).subscribe(response => {
        if (response) {
        }
      });

      this.verificationservice.GetCustomerData(this.customerId, this.ownerId).subscribe(response => {
        if (response) {
          if(response.customer != null){
            var value =  response.customer.phone1;
            if(value != null && value != ""){
              this.leasingOfficePhone =  "(" + value.substring(0, 3) + ")" + " " + value.substring(3, 6) + "-" + value.substring(6);
            }
            this.leasingOfficeEmail = response.customer.email;
          }
        }
      });

    this.baseUrl = this.configService.blobStorageBaseUrl;
    this.brokerContactService.getCustomerInfo( this.customerId,this.ownerId).subscribe(respose => {
      
      if (respose != null) {
        this.companyName=respose.companyName;
        if(respose.phone1.includes("+1")){

          respose.phone1= respose.phone1.toString().split('+1')[1];
        }
        var cleaned = ('' + respose.phone1).replace(/\D/g, '');
        var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        if (match) {
          var intlCode = (match[1] ? '+1 ' : '');
          this.companyPhone = [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
        } else{
          this.companyPhone = "";
        }
        
      }
    });
    this.formApi.getFormSettingsByCustomerIdOrBuildingId(this.customerId,this.pid, this.ownerId).subscribe(response => {
      if(response !=null)
      {
        
        if(response.logo1 != "")
        {
            this.companyLogo = this.configService.blobStorageBaseUrl + response.logo1;
            this.companyColor = response.backgroundColor;
            this.fontColor= response.fontColor;
            this.spinner.hide();
        }
      }
    });

if(this.vStatus == 'Sx512_21123HD@112AHGDS'){
  sessionStorage.setItem("verifiedstatus", 'verified');
  }
  
      var verifiedstatus = sessionStorage.getItem("verifiedstatus");
      var isnancehck = isNaN(this.brokerId);

      if ((verifiedstatus === "verified" || this.vStatus === 'Sx512_21123HD@112AHGDS') && this.brokerId !== null && this.brokerId !== 0 && isnancehck == false) {
        this.router.navigateByUrl(`broker/activity?cid=${this.customerId}&oid=${this.ownerId}`);

      }
      if (this.brokerId !== null && this.brokerId !== 0 && isnancehck == false) {

        this.brokerContactService.getById(this.brokenContractId, this.brokerId, this.ownerId, this.customerId).subscribe(respose => {
          
            this.intelInput();
          if (respose != null) {
            if(respose.createEnabled== false){
              $("#loginbtn").hide();
              $("#permission").show();
              this.addEditForm.controls['phone'].setValue(respose.brokerContact.mobilePhone);
              this.addEditForm.controls['email'].setValue(respose.brokerContact.email1);
              this.applicantid = respose.applicantId;
              $("#Email").attr("disabled", true);
              $("#cellphone_1").attr("disabled", true);
            } else{
              $(".heading-tertiary").hide();
              this.addEditForm.controls['phone'].setValue(respose.brokerContact.mobilePhone);
              this.addEditForm.controls['email'].setValue(respose.brokerContact.email1);
              this.applicantid = respose.applicantId;
              $("#Email").attr("disabled", true);
              $("#cellphone_1").attr("disabled", true);
            }
            
          }


        });

      }
    });




  }

  ngAfterViewInit(){
    this.intelInput();
  }

  public async enablebtn() {
    
let phone = this.addEditForm.controls['phone'].value
if(!phone.includes('+')){
  phone = (<any>iti).getNumber();;
}
    // var phone = (<any>iti).getNumber();

    // VerifyEmailandPhone
    this.verificationservice.VerifyEmailandPhone(this.addEditForm.controls['email'].value,this.ownerId,this.customerId,phone).subscribe(respose => {
      
      if(respose.bid>0 && respose.bcid>0){
        var currentURL = this.router.url;
        if(!currentURL.includes('bid') && !currentURL.includes('bcid')){
          var bid = respose.bid;
          var bcid = respose.bcid;
          this.location.replaceState(`${currentURL}&bid=${bid}&bcid=${bcid}`);

        }
        
        this.submitted= true;
        if(this.addEditForm.valid){
         $("#verificationdiv").show();
         $("#permission").hide();
         var brokerInfo={cid:this.customerId,oid:this.ownerId,pid:this.pid,brid:respose.bid,bcid:respose.bcid }
          sessionStorage.setItem("brokertest", JSON.stringify(brokerInfo));
          sessionStorage.setItem("brokerid", respose.bid.toString());

        } 
      }
      else{
        this.submitted= false;
        $("#permission").show();
      }
    });
     
    
  };
  
  public startTimerEmail(duration, display) {
    var timer = duration;
    var minutes;
    var seconds;
    this.myVar=setInterval(function () {
        minutes = parseInt((timer  / 60).toString()) ;
        seconds = parseInt((timer % 60).toString());

        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;

        display.textContent ="Code expires in "+ minutes + ":" + seconds;

        if (--timer < 0) {
            timer = 0;
            // clearInterval(1);
           var isVisible = document.getElementById("spanid").style.display == "none";
             if(isVisible== false){
              display.textContent = "Code Expired";
              $("#againemail").show();
              $("#emailverifybtn").hide(); 
             }
        }
    }, 1000);
    return this.myVar;
}
public startTimerPhone(duration, display) {
  var timer = duration;
  var minutes;
  var seconds;
  this.myVar=setInterval(function () {
      minutes = parseInt((timer  / 60).toString()) ;
      seconds = parseInt((timer % 60).toString());

      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;

      display.textContent ="Code expires in "+ minutes + ":" + seconds;

      if (--timer < 0) {
        // clearInterval(1);
          timer = 0;
          //display.textContent = "Code Expired";
          $("#phoneCode-div").hide();
          $("#callCode-div").show();
          $("#againphone").hide();
          $("#smsverifybtn").hide();
      }
  }, 1000);
  return this.myVar;
}
  public countdownEmail() {
    this.startTimerEmail(180,document.querySelector('#spanid'))
    // var seconds = 60;
    // function tick() {
    //   var timerEmail = $("#spanid");
    //   if (emailVerify == false) {
    //     seconds--;
    //     timerEmail.html("Code expires in 0:" + (seconds < 10 ? "0" : "") + String(seconds));
    //     if (seconds > 0) {
    //       setTimeout(tick, 1000);
    //     } else {
    //       timerEmail.html("Code Expired");
    //       $("#againemail").show();
    //       $("#emailverifybtn").hide();
    //     }

    //   }
    //   else {
    //     timerEmail.html("");
    //     $("#againemail").hide();
    //   }
    // }
    // tick();
    
  }

  public countdownPhone() {
    this.startTimerPhone(180,document.querySelector('#spanidphone'))

    // var phoneseconds = 60;
    // function tick() {
    //   var timerPhone = $("#spanidphone");
    //   if (phoneVerify == false) {
    //     phoneseconds--;
    //     timerPhone.html("Code expires in 0:" + (phoneseconds < 10 ? "0" : "") + String(phoneseconds));
    //     if (phoneseconds > 0) {
    //       setTimeout(tick, 1000);
    //     } else {
    //       timerPhone.html("Code Expired");
    //       $("#againphone").show();
    //       $("#smsverifybtn").hide();
    //     }
    //   }
    //   else {
    //     timerPhone.html("");
    //     $("#againphone").hide();
    //   }
    // }
    // tick();
  }

  public Onclick() {
    
    this.spinner.show();
    $("#sendemailbtn").attr("disabled", true);
    if (this.brokerId) {
      $("#emailverifybtn").show();
      $("#loginbtn").hide();
      $("#sendemailbtn").hide();
      this.countdownEmail();

      this.verificationservice.SendEmail(this.brokenContractId, this.brokerId, this.ownerId, this.pid).subscribe(respose => {
        
        this.emailcodetoverify = parseInt(respose);
        this.spinner.hide();
        this.utilityMethods.showSuccessNotification("Verification code has been sent to the email");
      });
    } else {

      var emailto = this.addEditForm.controls['email'].value;
      $("#verificationdiv").show();
      $("#loginbtn").hide();
      $("#emailverifybtn").show();
      $("#sendemailbtn").hide();
      this.countdownEmail();
      this.verificationservice.SendEmailTOEmail(emailto, this.ownerId, this.customerId).subscribe(respose => {
        
        this.emailcodetoverify = parseInt(respose);
        this.spinner.hide();
        this.utilityMethods.showSuccessNotification("Verification code has been sent to the email");
      });
    }

  }
  public verifyEmail() {
    this.spinner.show();
    let val1 = $("#emailCode1").val();
    let code = val1.toString();
    if (this.emailcodetoverify === parseInt(code)) {
      $("#emailverifybtn").attr("disabled", true);
      $("#emailcodeerror").hide();
      $("#emailCheck").show();
      $("#emailDeny").hide();
      $("#againemail").hide();
      $("#smsverifybtn").attr("disabled", false);
      $("#spanid").hide();
      $("#againemail").hide();
      emailVerify = true;
      let phone = this.addEditForm.controls['phone'].value
if(!phone.includes('+')){
  phone = (<any>iti).getNumber();;
}
      var toNumber = phone;
      this.verificationservice.SendSms(toNumber, "", this.ownerId).subscribe(respose => {
        
        this.spinner.hide();
        this.utilityMethods.showSuccessNotification("Verification code has been sent to the phone");
        this.smscodetoverify = parseInt(respose);
        this.countdownPhone();
      });
    } else {
      this.spinner.hide();
      $("#emailcodeerror").show();
      $("#emailDeny").show();
      this.threshold--;
      if(this.threshold<=0){
        $("#loginbtn").hide();
        $("#blocker").show();
        $("#verificationdiv").hide();
      }
    }
  }



  public verifySMS() {
    let val1 = $("#smsCode1").val();
    let code = val1.toString();
    if (this.smscodetoverify === parseInt(code)) {
      $("#smsverifybtn").attr("disabled", true);
      $("#phoneerror").hide();
      $("#phoneCheck").show();
      $("#phoneDeny").hide();
      $("#againphone").hide();
      $("#spanidphone").hide();
      phoneVerify = true;

      sessionStorage.setItem("verifiedstatus", "verified")
      var brokerverif= sessionStorage.getItem("brokertest");
      let parseddata=JSON.parse(brokerverif);
      let previousUrl: string | null = sessionStorage.getItem("previouUrl");
      // this.router.navigateByUrl(previousUrl!);
      var brokerInfo={cid:this.customerId,oid:this.ownerId,pid:this.pid,brid:parseddata.brid,bcid:parseddata.bcid }
          sessionStorage.setItem("brokerInfo", JSON.stringify(brokerInfo));
          sessionStorage.setItem("brokerid", parseddata.brid.toString());

          sessionStorage.removeItem("brokertest");
      this.router.navigateByUrl(`broker/activity?cid=${this.customerId}&oid=${this.ownerId}`);
    } else {
      this.spinner.hide();
      $("#phoneerror").hide();
      $("#phoneDeny").show();
      $("#againphone").hide();
      this.threshold--;
      if(this.threshold<=0){
        $("#loginbtn").hide();
        $("#blocker").show();
        $("#verificationdiv").hide();
      }

    }
  }


  public sendsmsagain() {
    this.spinner.show();
    $("#smsverifybtn").attr("disabled", false)
    $("#emailverifybtn").attr("disabled", true);
    $("#emailcodeerror").hide();
    $("#againphone").hide();
    $("#phoneerror").text("Code Sent Successfully");
    $("#phoneDeny").hide();
    $("#smsverifybtn").show();
    clearInterval(this.myVar);
    this.countdownPhone();
    
    let phone = this.addEditForm.controls['phone'].value
    if(!phone.includes('+')){
      phone = (<any>iti).getNumber();;
    }
    var toNumber = phone;
    this.verificationservice.SendSms(toNumber, "", this.ownerId).subscribe(respose => {
      
      this.spinner.hide();
      this.smscodetoverify = parseInt(respose);
      this.utilityMethods.showSuccessNotification("Verification code has been sent to the phone");

    });
  }
  public sendemailagain() {
    this.spinner.show();
    $("#emailverifybtn").attr("disabled", false);
    $("#emailDeny").hide();
    $("#emailverifybtn").show();
    $('#againemail').hide()
        clearInterval(this.myVar);
    this.countdownEmail();
    this.verificationservice.SendEmail(this.brokenContractId, this.brokerId, this.ownerId, this.pid).subscribe(respose => {
      
      this.emailcodetoverify = parseInt(respose);
      $("#emailcodeerror").text("Code Sent Successfully");
      this.spinner.hide();
      this.utilityMethods.showSuccessNotification("Verification code has been sent to the email");

    });
  }

  initializeForm(): void {
    this.addEditForm = this.formBuilder.group({
      phone: ['', Validators.required],
      email: ['', [Validators.required,Validators.pattern("^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$")]]

    });
  }
  get formFields() {
    return this.addEditForm.controls;
  }


  public redirect() {
    if(isNaN(this.customerId)){
      this.customerId=2;
    }
     if(isNaN(this.ownerId)){
       this.ownerId=2;
     } if(isNaN(this.pid)){
        this.pid=8;
     }
    const url= `broker/signUp?cId=${this.customerId}&oId=${this.ownerId}`
    this.router.navigateByUrl(url);

  }

  public intelInput() {
    $(document).ready(function ($: any) {
      var phoneInputID = "#cellphone_1";
      var input = document.querySelector(phoneInputID);
      iti = (<any>window).intlTelInput(input, {
        hiddenInput: "full_number",
        preferredCountries: ["us"],
        utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/19.2.14/js/utils.js",
        
      });

      $(phoneInputID).on("countrychange", function (event: any) {
        
        var selectedCountryData = (<any>iti).getSelectedCountryData();
        var newPlaceholder = (window as any).intlTelInputUtils.getExampleNumber(
          selectedCountryData.iso2,
          true,
          (window as any).intlTelInputUtils.numberFormat.INTERNATIONAL
        );
        (<any>iti).getSelectedCountryData();
        (<any>iti).setNumber((<any>iti).getNumber());
        var mask = newPlaceholder.replace(/[1-9]/g, "0");
      if(newPlaceholder?.length > 0){
        $(phoneInputID).attr('maxlength',newPlaceholder?.length);
        var num = (<any>iti).getNumber();
        if (selectedCountryData.iso2 === 'pk' && !/^(\+923|03)/.test(num)) {
          $(phoneInputID).attr('maxlength', (newPlaceholder?.length + 2) || undefined);
        }
      }

       // $(phoneInputID).mask(mask);
      });
      (<any>iti).promise.then(function () {
        $(phoneInputID).trigger("countrychange");
      });
       
      $(phoneInputID).on('input', function() {
        var number = (<any>iti).getNumber()
        $(phoneInputID).trigger("countrychange",number);
      });
      $(document).on("click", ".k-dialog-close", function () {
        $(".loading").hide();
      });
    });
  }

  
  retryCall = 0;
  sendcallToNumber(){
    this.checkTimer = 0;  
    $("#sendCallCode").hide();
    $("#callingCode").show();
    $(".errorCall").html("");
    $(".timerCall").html("");

    this.retryCall++;
    if (this.retryCall > 3) {
        $("#verificationdiv").hide();
        $("#retryCallExpire").show();
        this.retryCall = 0;
        return;
    }

    let phone = this.addEditForm.controls['phone'].value
    if(!phone.includes('+')){
      phone = (<any>iti).getNumber();;
    }
    var toNumber = phone;

    if (this.retryCall <= 3) {
        this.sendCallVerification(this.brokerId, toNumber);
    }
  }

  sendCallVerification(brokerId, cellPhone) {
     $("#callingCodeVerify").hide();
     $("#callCheck").hide();
     this.verificationservice.SendCallVerificationForBrokerPortal(cellPhone.toString(), this.customerId, brokerId, this.ownerId).subscribe(response => {
        if (response) {
          this.countdownCallConnectedStoper();
          this.callConnectedStatus(brokerId);
        }
        else {
            $("#callingCode").hide();
            $("#sendCallCode").show();
            $(".errorCall").html("Unable to Connect");
        }
    });
 }

 public callConnectedSecCount: number = 0;

 callConnectedStatus(brokerId) {
    this.brokerApi.getBrokerDataById(brokerId, this.ownerId, this.customerId).subscribe(response => {   
      
        if(response != null){
          if (response.isCallConnect) {
            this.countdownCallPhone();
            this.countdownCallStoper();
            this.callVerificationStatus(brokerId);
        }
        else {
          if(this.checkTimer == 0){
              if (this.callConnectedSecCount != 1) {
                  this.callConnectedStatus(brokerId);
              }
              if (this.callConnectedSecCount == 1) {
                  $("#callingCode").hide();
                  $("#sendCallCode").show();
                  $(".errorCall").html("Unable to Connect");
              }
          }
        }
      }      
    });
 }

callSecCount: any = 0;

callVerificationStatus(brokerId) {
  this.brokerApi.getBrokerDataById(brokerId, this.ownerId, this.customerId).subscribe(response => {   
    
    this.checkTimer = 0; 
    if(response != null){
      if (response.isCallVerified) {  
          this.brokerApi.updateCallConnection(this.brokerId, this.ownerId, this.customerId, true, true).subscribe(response => {
            if (response) {
              this.checkTimer = 2;
            }
          });
        
        this.checkTimer = 2;
        phoneVerify = true;
        $(".loading").hide();
        $("#callingCode").hide();
        $("#sendCallCode").hide();
        $(".timerCall").html("");
        $("#callCheck").show();
        $("#callingCodeVerify").show();
        $("#callingCodeVerify").attr("disabled", true);
        $("#sendCallCode").attr("disabled", "disabled");      
        $("#smsverifybtn").attr("disabled", true);
        $("#phoneerror").hide();
        $("#phoneCheck").show();
        $("#phoneDeny").hide();
        $("#againphone").hide();
        $("#spanidphone").hide();
        phoneVerify = true;
  
        sessionStorage.setItem("verifiedstatus", "verified")
        var brokerverif= sessionStorage.getItem("brokertest");
        let parseddata=JSON.parse(brokerverif);
        let previousUrl: string | null = sessionStorage.getItem("previouUrl");
        // this.router.navigateByUrl(previousUrl!);
        var brokerInfo={cid:this.customerId,oid:this.ownerId,pid:this.pid,brid:parseddata.brid,bcid:parseddata.bcid }
            sessionStorage.setItem("brokerInfo", JSON.stringify(brokerInfo));
          sessionStorage.setItem("brokerid", parseddata.brid.toString());

            sessionStorage.removeItem("brokertest");
        this.router.navigateByUrl(`broker/activity?cid=${this.customerId}&oid=${this.ownerId}`);
    }
    else if (response.isCallVerified == false) {
        this.checkTimer = 1;       
      }
    else if (response.isCallVerified == null) {
        if (this.callSecCount != 1) {
        this.callVerificationStatus(brokerId);
        }
      }
    }     
  });
}

minutes: any;
seconds: any;
checkTimer: number = 0;
 countdownCallPhone() {
     var val: any = this;
     var timer = 60;
     function tick() {
         var timerCall = $(".timerCall");
         if (phoneVerify == false) {

          if(val.checkTimer == 0){
            val.minutes = parseInt((timer / 60).toString());
            val.seconds = parseInt((timer % 60).toString());
  
            val.minutes = val.minutes < 10 ? "0" + val.minutes : val.minutes;
            val.seconds = val.seconds < 10 ? "0" + val.seconds : val.seconds;
               timer--;
               timerCall.html("Expires in " + val.minutes + ":" + val.seconds);
               if (timer > 0) {
                   setTimeout(tick, 1000);
               } else {
                      val.brokerApi.updateCallConnection(val.brokerId, val.ownerId, val.customerId, false, null).subscribe(response => {
                      if (response) {
                        clearInterval(val.tick);
                        timerCall.html("Expired");
                        $("#sendCallCode").show();
                        $("#callingCode").hide();
                      }
                    });
               }
          }
          else if(val.checkTimer == 1){
            val.brokerApi.updateCallConnection(val.brokerId, val.ownerId, val.customerId, false, null).subscribe(response => {
              if (response) {
                clearInterval(val.tick);
                timerCall.html("");
                $("#sendCallCode").show();
                $("#callingCode").hide();
              }
            });
          }

          
         }
         else {
             timerCall.html("");
         }
     }
     tick();
}

 countdownCallStoper() {
 var timer = 59;
 var val: any = this;
 function tick() {
     val.callSecCount = parseInt((timer % 60).toString());
     val.callSecCount =  val.callSecCount < 10 ? "0" +  val.callSecCount :  val.callSecCount;
     timer--;
     if (timer > 0) {
         setTimeout(tick, 1000);
     } else {
         clearInterval( val.tick);
     }
 }
 tick();
}

countdownCallConnectedStoper() {
    var timer = 40;
    var val: any = this;
    function tick() {
      val.callConnectedSecCount = parseInt((timer % 60).toString());
      val.callConnectedSecCount =  val.callConnectedSecCount < 10 ? "0" +  val.callConnectedSecCount :  val.callConnectedSecCount;
        timer--;
        if (timer > 0) {
            setTimeout(tick, 1000);
        } else {
            clearInterval(val.tick);
        }
    }
    tick();
  }

  public inputValidator(event: any) {
    const pattern = /^[0-9+]*$/;
 
    if (event.type === 'input') {
      if (!pattern.test(event.target.value)) {
        event.target.value = event.target.value.replace(/[^0-9+]/g, "");
      }
    }
 
  }

}
