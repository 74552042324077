import { Component, OnInit, ViewChild } from "@angular/core";
import {
  ComboBoxComponent,
  AutoCompleteComponent,
  DropDownFilterSettings,
} from "@progress/kendo-angular-dropdowns";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Subscription } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { BrokerService } from "../services/broker/broker.service";
import { Broker } from "../shared/models/broker/broker.model";
import { BrokerageService } from "../services/brokerage/brokerage.service";
import { Brokerage } from "../shared/models/brokerage/brokerage.model";
import { UtilityMethods } from "../shared/helpers/utility-methods";
import { BrokerStatus } from "../shared/enum/broker-status.enum";
import { BrokerContactStatus } from "../shared/enum/broker-contact-status.enum";
import { BrokerContact } from "../shared/models/broker-contact/broker-contact.model";
import { BrokerContactService } from "../services/broker-contact/broker-contact.service";
import { LookupService } from "../services/lookup/lookup.service";
import { LookupCategory } from "../shared/enum/lookup-category";
import { Lookup } from "../shared/models/lookup/lookup.model";
import { SmartyStreetService } from "../services/smarty-street/smarty-street.service";
import { NgxSpinnerService } from "ngx-spinner";
import { AppConfigService } from "../../app/services/app-config/app-config.service";
import { FormSettingService } from "../services/form-setting/form-setting.service";
declare var $: any;
var iti = "";

@Component({
  selector: "app-broker-signup",
  templateUrl: "./broker-signup.component.html",
  styleUrls: ["./broker-signup.component.scss"],
})
export class BrokerSignupComponent implements OnInit {
  public isLogin: boolean = false;
  public isSaved: boolean = false;
  public addEditForm: FormGroup;
  public submitted = false;
  public addressData: any[];
  public phoneMask = "(000) 000-0000";
  public customerId: number;
  public ownerId: number;
  public pid: number = 0;
  public emailExist = false;
  public phoneExist = false;
  public currentEmail = "";
  public currentPhone = "";
  public brokeageNameExist = false;
  public currentBrokerageName: any;
  public broker: Broker = new Broker();
  public brokerAge: Brokerage = new Brokerage();
  public brokerContact: BrokerContact = new BrokerContact();
  public countryStates: Lookup[] = [];
  public countries = [] as Lookup[];
  private subscriptions: Subscription[] = [];
  public filteredStates: any[] = [];
  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: "startsWith",
  };
  public usZipCodeMinValue: boolean;
  public nonUsZipCodeMaxValue: boolean;
  public usZipCodeMaxValue: boolean;
  public nonUsZipMinValue: boolean;
  public disableFormSubmit: boolean = false;
  public currentSearch: string;
  @ViewChild("brokerageSearchControl", { static: true })
  searchControl: ComboBoxComponent;
  public searchInterval: any;
  public brokerages: any[] = [];
  public companyLogo: string | null = "assets/images/image.png";
  public baseUrl: string = "";
  public companyColor: string = "white";
  public customer: any;
  public fontColor: string = "white";

  constructor(
    private formBuilder: FormBuilder,
    private _activatedRouter: ActivatedRoute,
    private router: Router,
    private brokerApi: BrokerService,
    private brokerageApi: BrokerageService,
    private utilityMethods: UtilityMethods,
    private brokerContactApi: BrokerContactService,
    private lookupApi: LookupService,
    private addressApi: SmartyStreetService,
    private spinner: NgxSpinnerService,
    private configService: AppConfigService,
    private formApi: FormSettingService
  ) {
    this.initializeForm();
  }

  ngOnInit(): void {
    this._activatedRouter.queryParams.subscribe((params) => {
      this.customerId = Number(params["cId"]);
      this.ownerId = Number(params["oId"]);
      this.pid = Number(params["pid"] == undefined ? 0 : params["pid"]);
    });
    this.spinner.hide();
    this.companyLogo = "assets/images/image.png";
    this.baseUrl = this.configService.blobStorageBaseUrl;

    this.formApi
      .getFormSettingsByCustomerIdOrBuildingId(
        this.customerId,
        this.pid,
        this.ownerId
      )
      .subscribe((response) => {
        if (response != null) {
          
          if (response.logo1 != "") {
            this.companyLogo =
              this.configService.blobStorageBaseUrl + response.logo1;
            this.companyColor = response.backgroundColor;
            this.fontColor = response.fontColor;
          }
        }
      });
    this.getcustomerid();
    this.loadCountries();
    this.loadStates();
  }

  ngAfterViewInit() {
    this.intelInput();
  }

  public async onEmailChange(val: any) {
    this.emailExist = false;
    this.emailExist = await this.brokerApi
      .CheckBrokerExistByEmail(
        this.addEditForm.value.email1,
        this.ownerId,
        this.customerId
      )
      .toPromise();
    if (this.emailExist || this.phoneExist) {
      this.isLogin = true;
    } else {
      this.isLogin = false;
    }
  }

  public async onPhoneChange(val: any) {
    this.phoneExist = false;
    this.phoneExist = await this.brokerApi
      .CheckBrokerExistByPhone(
        this.addEditForm.value.mobilePhone,
        this.ownerId,
        this.customerId
      )
      .toPromise();
    if (this.emailExist || this.phoneExist) {
      this.isLogin = true;
    } else {
      this.isLogin = false;
    }
  }

  public get formFields() {
    return this.addEditForm.controls;
  }

  initializeForm() {
    this.addEditForm = this.formBuilder.group({
      brokerId: [null],
      brokerageId: ["", Validators.required],
      brokerContactId: [null],
      customerId: this.customerId,
      ownerId: this.ownerId,
      firstName: ["", Validators.required],
      lastName: ["", Validators.required],
      email1: ["", [Validators.required, Validators.email]],
      mobilePhone: ["", Validators.required],
      address1: ["", Validators.required],
      address2: [""],
      location: ["", Validators.required],
      zipCode: ["", Validators.required],
      countryId: [null, Validators.required],
      city: [null, Validators.required],
      state: [null, Validators.required],
    });
  }

  private loadCountries(): void {
    const subscription = this.lookupApi
      .getCountryList()
      .subscribe((response) => {
        this.countries = response;
        this.countries.unshift(new Lookup({ name: "Select One" }));
      });

    this.subscriptions.push(subscription);
  }

  public onCountryChange(countryId: number) {
    this.formFields.state.setValue("");
    this.formFields.state.reset();
    if (countryId) {
      this.filterStates(countryId);
    } else {
      this.filteredStates = [];
    }
  }

  public filterStates(countryId: number) {
    if (countryId) {
      this.filteredStates = this.countryStates.filter(
        (x: any) => x.parentLookupId == countryId
      );
    } else {
      this.filteredStates = [];
    }
    this.filteredStates.unshift({ name: "Select One", lookupId: "" });
  }

  private async loadStates() {
    let response = await this.lookupApi
      .getByCategoryId(LookupCategory.StateType)
      .toPromise();
    this.countryStates = response.lookups;
  }

  public changeFilterOperator(operator: string): void {
    if (this.formFields.countryId.value == 8232) {
      if (operator.length >= 3) {
        this.spinner.show();
        this.addressApi
        .getSmartyStreetAddressN(operator).subscribe((response) => {
            this.spinner.hide();
            if (response.suggestions.length > 0) {
              this.addressData = response.suggestions;
              this.addressData.forEach((element) => {
                element.secondary =
                  element.street_line +
                  ", " +
                  element.city +
                  ", " +
                  element.state +
                  ", " +
                  element.zipcode;
              });
            }
          });
        // this.addressApi.getSmartyStreetAddresses(operator + ":8232").subscribe(response => {
        //   this.spinner.hide();
        //   this.addressData = response;
        //   this.addressData.forEach(element => {
        //     element.secondary = element.street_line + ", " + element.city + ", " + element.state + ", " + element.zipcode;
        //   });

        // });
      }
    }
  }

  public onChangeAddress(selectedAddress: any) {
    let item = this.addressData.find((x) => x.secondary == selectedAddress);
    this.formFields.address1.setValue(item.street_line);
    if (item.city) {
      this.formFields.city.setValue(item.city);
    }
    this.formFields.state.setValue(item.state);
    this.formFields.zipCode.setValue(item.zipcode);
  }

  public onStateChange(stateId: number) {
    this.formFields.state.setValue(stateId);
  }

  public usZipMinLengthCheck(value: any) {
    var valuezip = this.formFields.zipCode.value.length;
    if (this.formFields.countryId.value == 8232) {
      if (valuezip < 5 && valuezip > 0) {
        this.usZipCodeMinValue = true;
        this.disableFormSubmit = true;
      } else {
        this.usZipCodeMinValue = false;
        this.disableFormSubmit = false;
      }
    } else {
      if (valuezip < 1 && valuezip > 0) {
        this.nonUsZipMinValue = true;
        this.disableFormSubmit = true;
      } else {
        this.nonUsZipMinValue = false;
        this.disableFormSubmit = false;
      }
    }
  }

  public allowNum(event: any) {
    if (event.key == "_" || event.key == ">") {
      event.preventDefault();
      return;
    }

    var allowed = [46, 8, 9, 27];
    if (
      allowed.includes(event.which) ||
      (event.which == 65 && event.ctrlKey === true) ||
      (event.which >= 35 && event.which <= 39)
    ) {
      return;
    }

    if (
      (event.shiftKey || event.which < 48 || event.which > 57) &&
      (event.which < 96 || event.which > 105)
    ) {
      event.preventDefault();
    }
  }

  public usZipCodeCheck(event: any) {
    var valuezip = this.formFields.zipCode.value.length;
    if (this.formFields.countryId.value == 8232) {
      if (valuezip == 9) {
        return false;
      }
    } else {
      if (valuezip == 16) {
        return false;
      }
    }
  }

  public onBrokerageSearch(
    searchText: string,
    searchControl: ComboBoxComponent
  ) {
    this.currentSearch = searchText;
    searchControl.toggle(false);

    if (this.searchInterval) {
      clearInterval(this.searchInterval);
    }

    this.searchInterval = setTimeout(() => {
      this.loadBrokerages(searchText, searchControl);
    }, 500);
  }

  private loadBrokerages(
    searchText: string,
    searchControl: ComboBoxComponent
  ): void {
    if (searchText.length > 2) {
      searchControl.loading = true;
      this.spinner.show();
      const subscription = this.brokerageApi
        .SearchByKey(searchText, this.ownerId, this.customerId)
        .subscribe(
          (response) => {
            this.spinner.hide();
            searchControl.loading = false;
            this.brokerages = response.brokerages;

            if (this.brokerages.length > 0) {
              searchControl.toggle(true);
            }
          },
          (error) => {
            this.spinner.hide();
            searchControl.loading = false;
          }
        );

      this.subscriptions.push(subscription);
    }
  }

  onBrokerageChange(event: any) {
    this.brokeageNameExist = false;
    this.brokerAge.brokerageId = null;
    this.addEditForm.controls["brokerageId"].setValidators(Validators.required);
    this.addEditForm.controls["brokerageId"].updateValueAndValidity();
    this.brokerAge = new Brokerage();
    if (this.brokerages.length > 0) {
      var object = this.brokerages.find((x: any) => x.brokerageId == event);
      if (object != null) {
        this.brokerAge.brokerageId = object.brokerageId;
      } else {
        this.brokerAge.name = event;
        this.addEditForm.controls["brokerageId"].setValue(null);

        this.addEditForm.controls["brokerageId"].clearValidators();
        this.addEditForm.controls["brokerageId"].updateValueAndValidity();
      }
    } else if (event == undefined) {
      this.addEditForm.controls["brokerageId"].setValue(null);

      this.brokerAge.name = event;
      this.addEditForm.controls["brokerageId"].clearValidators();
      this.addEditForm.controls["brokerageId"].updateValueAndValidity();
    } else {
      this.brokerAge.name = event;
    }
  }

  public async onSubmit() {
    this.spinner.show();
    this.submitted = true;
    this.broker = new Broker();
    var existBroker = new ExistBroker();
    var phone = (<any>iti).getNumber();
    existBroker.phone = phone;
    existBroker.email = this.addEditForm.value.email1;
    existBroker.brokerageName = this.addEditForm.value.brokerageId; //this.brokerAge.name;

    if (
      this.addEditForm.valid &&
      (await this.isValidEmailAndPhone(existBroker))
    ) {
      if (
        this.brokerAge.brokerageId == null ||
        this.brokerAge.brokerageId == undefined
      ) {
        this.brokerAge.countryId = this.formFields.countryId.value;
        this.brokerAge.state = this.formFields.state.value;
        this.brokerAge.city = this.formFields.city.value;
        this.brokerAge.zipCode = this.formFields.zipCode.value;
        this.brokerAge.address1 = this.formFields.address1.value;
        this.brokerAge.address2 = this.formFields.address2.value;
        this.brokerAge.location = this.formFields.location.value;
        this.brokerAge.propertyId = this.pid;
        this.brokerAge.cutomerId = this.customerId;
        var phone = (<any>iti).getNumber();
        this.brokerAge.phone1 = phone;
        this.brokerAge.email1 = this.addEditForm.value.email1;

        if (this.brokerAge.name != null) {
          await this.brokerageApi
            .add(this.brokerAge, this.ownerId, this.customerId)
            .subscribe((response) => {
              // this.utilityMethods.showSuccessNotification('Brokerage successfully added')

              if (response != null) {
                this.submitBroker(response.brokerageId);
              }
            });
        } else {
          this.submitBroker(null);
        }
      } else {
        this.submitBroker(this.brokerAge.brokerageId);
      }
    } else {
      this.spinner.hide();
    }
  }

  public async submitBroker(brokerAgeId: any) {
    // Setting broker data
    this.broker.accountStatus = BrokerStatus.Pending;
    this.broker.firstName = this.addEditForm.value.firstName;
    this.broker.lastName = this.addEditForm.value.lastName;
    this.broker.email1 = this.addEditForm.value.email1;
    var phone = (<any>iti).getNumber();
    this.broker.phone1 = phone;
    this.broker.zipCode = this.formFields.zipCode.value;
    this.broker.address1 = this.formFields.address1.value;
    this.broker.address2 = this.formFields.address2.value;
    this.broker.location = this.formFields.location.value;
    this.broker.countryId = this.formFields.countryId.value;
    this.broker.state = this.formFields.state.value;
    this.broker.city = this.formFields.city.value;

    this.broker.flag = 1;
    this.broker.brokerageId = brokerAgeId;
    this.broker.customerId = this.customerId;
    this.broker.propertyId = this.pid;
    // *********** end***********

    // Broker Contact Data Set
    this.brokerContact = this.addEditForm.value;
    this.brokerContact.brokerageId = brokerAgeId;
    this.brokerContact.brokerContactStatusId = BrokerContactStatus.Registered;
    var phone = (<any>iti).getNumber();
    this.brokerContact.mobilePhone = phone;

    this.brokerApi
      .add(this.broker, this.ownerId, this.customerId)
      .subscribe((response) => {
        if (response != null) {
          this.submitBrokerContract(response.brokerId);
        }
      });
  }

  public submitBrokerContract(brokerId: any) {
    this.brokerContact.brokerId = brokerId;
    this.brokerContact.ownerId = this.ownerId;
    this.brokerContact.customerId = this.customerId;

    this.brokerContactApi
      .add(this.brokerContact, this.ownerId, this.customerId)
      .subscribe((response) => {
        this.utilityMethods.showSuccessNotification(
          "Broker Information Submitted Successful"
        );
        this.isSaved = true;
        this.spinner.hide();
      });
  }

  private async isValidEmailAndPhone(broker: ExistBroker): Promise<boolean> {
    if (broker.email.toLowerCase() != this.currentEmail.toLocaleLowerCase()) {
      this.emailExist = await this.brokerApi
        .CheckBrokerExistByEmail(broker.email, this.ownerId, this.customerId)
        .toPromise();
    }

    if (broker.phone != this.currentPhone) {
      this.phoneExist = await this.brokerApi
        .CheckBrokerExistByPhone(broker.phone, this.ownerId, this.customerId)
        .toPromise();
    }
    if (broker.brokerageName != null) {
      if (broker.brokerageName != this.currentBrokerageName) {
        this.brokeageNameExist = await this.brokerApi
          .CheckBrokerExistByBrokerageName(
            broker.brokerageName,
            this.ownerId,
            this.customerId
          )
          .toPromise();
      }
    }

    if (this.emailExist == true || this.phoneExist == true) {
      this.isLogin = true;
    }

    return (
      this.emailExist === false &&
      this.phoneExist === false &&
      this.brokeageNameExist === false
    );
  }

  redirectToLogin() {
    const url = `broker/verification?cid=${this.customerId}&oid=${this.ownerId}`;
    this.router.navigateByUrl(url);
  }
  public getcustomerid() {
    const subscription = this.brokerApi
      .GetCustomerById(this.ownerId, this.customerId)
      .subscribe((response) => {
        this.customer = response;
      });
  }

  public intelInput() {
    $(document).ready(function ($: any) {
      var phoneInputID = "#cellphone_1";
      var input = document.querySelector(phoneInputID);
      iti = (<any>window).intlTelInput(input, {
        hiddenInput: "full_number",
        preferredCountries: ["us"],
        utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/19.2.14/js/utils.js",
        
      });

      $(phoneInputID).on("countrychange", function (event: any) {
        
        var selectedCountryData = (<any>iti).getSelectedCountryData();
        var newPlaceholder = (window as any).intlTelInputUtils.getExampleNumber(
          selectedCountryData.iso2,
          true,
          (window as any).intlTelInputUtils.numberFormat.INTERNATIONAL
        );
        (<any>iti).getSelectedCountryData();
        (<any>iti).setNumber((<any>iti).getNumber());
        var mask = newPlaceholder.replace(/[1-9]/g, "0");
      if(newPlaceholder?.length > 0){
        $(phoneInputID).attr('maxlength',newPlaceholder?.length);
        var num = (<any>iti).getNumber();
        if (selectedCountryData.iso2 === 'pk' && !/^(\+923|03)/.test(num)) {
          $(phoneInputID).attr('maxlength', (newPlaceholder?.length + 2) || undefined);
        }
      }

       // $(phoneInputID).mask(mask);
      });
      (<any>iti).promise.then(function () {
        $(phoneInputID).trigger("countrychange");
      });
       
      $(phoneInputID).on('input', function() {
        var number = (<any>iti).getNumber()
        $(phoneInputID).trigger("countrychange",number);
      });
      $(document).on("click", ".k-dialog-close", function () {
        $(".loading").hide();
      });
    });
  }

  public inputValidator(event: any) {
    const pattern = /^[0-9+]*$/;
 
    if (event.type === 'input') {
      if (!pattern.test(event.target.value)) {
        event.target.value = event.target.value.replace(/[^0-9+]/g, "");
      }
    }
 
  }

}

export class ExistBroker {
  email: string;
  phone: any;
  brokerageName: string;
}
