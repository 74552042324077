import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router, NavigationEnd } from "@angular/router";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { AppConfigService } from "../../services/app-config/app-config.service";
import { NgxSpinnerService } from "ngx-spinner";
import { BrokerService } from "src/app/services/broker/broker.service";

@Component({
  selector: "app-edit-application",
  templateUrl: "./edit-application.component.html",
  styleUrls: ["./edit-application.component.scss"],
})
export class EditApplicationComponent implements OnInit {
  public isLoading: boolean = false;
  public applicantId: string | null;
  public customerId: string | null;
  public tenantId: string | null;
  public applicationURL: string | null;
  public activityURL: string | null;
  public screeningGroupId: string | null;
  public propertyId: string | null;
  public UnitId: string | null;
  public urlSafe: SafeResourceUrl = "";
  public brokerId: number = 0;
  public brokerContactId: number = 0;
  public brokerName: string = "";
  public ownerId: number;
  public customerID: number;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private sanitizer: DomSanitizer,
    private spinner: NgxSpinnerService,
    private config: AppConfigService,
    private brokerApi: BrokerService
  ) {
    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.spinner.show();
      }
    });
  }

  async ngOnInit() {

    var brokerInfo = JSON.parse(sessionStorage.getItem("brokerInfo"));
    if(brokerInfo !== null){
      this.customerID = brokerInfo.cid == null ? 0 : brokerInfo.cid;
      this.ownerId = brokerInfo.oid == null ? 0 : brokerInfo.oid;
      this.brokerId = brokerInfo.brid == null ? 0 : brokerInfo.brid;
    }
    var customerId = 0;
    var ownerId = 0;
  customerId = Number(this.route.snapshot.queryParamMap.get("cid"));
  ownerId = Number(this.route.snapshot.queryParamMap.get("tid"));
   
    if(this.ownerId == null || this.ownerId == 0){
      this.ownerId = ownerId;
    }

    if(this.customerId == null || this.customerID == 0){
      this.customerID = customerId;
    }
    if(this.brokerId == null || this.brokerId == 0){
    // var brokerId = sessionStorage.getItem("brokerid");
    this.brokerId = parseInt(this.route.snapshot.queryParamMap.get("actorId"));
    }
    if(this.brokerContactId == null || this.brokerContactId == 0){
      // var brokerId = sessionStorage.getItem("brokerid");
      if(this.brokerId > 0 ){
        let response = await this.brokerApi.getById(this.brokerId ,0, this.ownerId, this.customerID).toPromise();
        this.brokerContactId = response.broker.brokerContactId;
      }

      }
    
    var verstatus = sessionStorage.getItem("verifiedstatus");
    if (verstatus == "verified") {
      this.spinner.show();
      this.propertyId = this.route.snapshot.queryParamMap.get("pid");
      this.UnitId = this.route.snapshot.queryParamMap.get("uid");
      this.applicantId = this.route.snapshot.queryParamMap.get("aid");
      this.customerId = this.route.snapshot.queryParamMap.get("cid");
      this.tenantId = this.route.snapshot.queryParamMap.get("tid");
      this.brokerId = parseInt(this.route.snapshot.queryParamMap.get("actorId"));
      this.brokerName = this.route.snapshot.queryParamMap.get("usname");
      this.screeningGroupId = this.route.snapshot.queryParamMap.get("sid");
      this.applicationURL = `${this.config.formBaseUrl}?aid=${
        this.applicantId
      }&cid=${this.customerId}&tid=${this.tenantId}&isAdminEditMode=true&sid=${
        this.screeningGroupId
      }&pid=${this.propertyId}&uid=${this.UnitId}&usname=${
        this.brokerName
      }&actorId=${this.brokerId}&ttlStamp=${Date.now()}&isBrokerMode=true`;
      ("https://localhost:44388/?aid=1951&cid=2&tid=2&isAdminEditMode=true&sid=903&pid=8&uid=56&usname=Usman Amjad&actorId=158&ttlStamp=1618906709653"); //`https://localhost:44388/?aid=1930&cid=2&tid=2&isAdminEditMode=true&sid=886&pid=8&uid=56&usname=Usman&actorId=158&ttlStamp=1618915969459`;
      this.activityURL = `/activity/screening/${this.screeningGroupId}`;
      
      this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(
        this.applicationURL
      );
      this.spinner.hide();
      this.isLoading = false;
    } else {
      if(this.brokerId > 0 && this.brokerContactId > 0){
        // var url = `/broker/verification?cid=${this.customerID}&oid=${this.ownerId}&bid=${this.brokerId}&bcid=${this.brokerContactId}` ;
        // this.sanitizer.bypassSecurityTrustResourceUrl(
        //   url
        // );
        ;
        var url = `/broker/verification?cid=${this.customerID}&oid=${this.ownerId}&bid=${this.brokerId}&bcid=${this.brokerContactId}` ;
        // this.router.navigateByUrl(
        //   url
        // );
         window.location.href =url;
      }else{
    
        var url = `/broker/verification?cid=${this.customerID}&oid=${this.ownerId}` ;
        // this.router.navigateByUrl(
        //   url
        // );
         window.location.href =url;
      
      }
    
    }

  }

  public getSafeUrl(url: string) {
    //if (!this.isLoading) {
    //  this.spinner.show();
    //  this.isLoading = true;
    //}
    
    url = url + "&ttl=" + Date.now();
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  public formLoaded() {
    //this.spinner.hide();
  }
  public intitFormLoad() {
     ///started
  }
  
  
}
