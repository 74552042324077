export enum LookupCategory {

    CountryType = 1,
    EmailTemplateCategory = 4,
    ApplicantType = 7,
    ProductCategory = 9,
    ProductType = 10,
    CurrencyType = 11,
    ProductRangeFrom = 12,
    ProductRangeTo = 13,
    StateType = 19,
    ScreeningProgress = 32,
    DecisionStatus = 33,
    RecommendationStatus = 34,
    PromotionGroupType = 35,
    NoOfMonth = 36,
    OwnerPaysNoOfMonths = 50,
    leaseTerm = 37,
    OpportunityStatus = 38,
    LeadStatus = 39,
    LeadMaxSize = 40,
    LeadSource = 47,
    TimeZone = 51,
    PortalSettings = 54,
    Baths = 60,
    Beds = 59,
    Rooms = 58,
    Category = 57,
    ContractDocumentCategory = 63,
    ContractDocumentLeaseType = 61,
    LeaseStatus = 64,
    LeaseModificationStatus = 65,
    TenantStatus = 66,
    LeaseType = 67,
    ContractDocumentRenewalType = 77,
    CustomTagCategory = 78,
    ContractTagType = 79,
    ReportType = 80,
    SyndicationNetwork = 83,
    SyndicationCategory = 84,
    SyndicationAcceptsFeatured = 85,
    SyndicationBillable = 86,
    
}
