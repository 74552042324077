import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  title = 'app';
/**
 *
 */
constructor(private router: Router, private location: Location,private spinner: NgxSpinnerService) {
  // 
  // var previousUrl= this.location.path();
  // sessionStorage.setItem("previouUrl", previousUrl);
}

public verifiedstatus = sessionStorage.getItem("verifiedstatus");
ngOnInit() {
  this.spinner.show();
if(this.verifiedstatus=="verified"){
  let previousUrl: string | null = sessionStorage.getItem("previouUrl");
  if(!previousUrl.toLowerCase().includes('nan')){
    this.router.navigateByUrl(previousUrl!);
  }

} else{
  //this.router.navigateByUrl("broker/verification?cid=2&oid=2&pid=8");
}

}
}



