import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormSettingService } from '../services/form-setting/form-setting.service';


import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { debug } from 'console';
import { param } from 'jquery';
import { filter } from 'rxjs/internal/operators';
import { VerificationService } from '../services/verification/verification.service';
import { UtilityMethods } from "../../helper/utility-methods";
import { BrokerService } from '../services/broker/broker.service';
import { BrokerContactService } from '../services/broker-contact/broker-contact.service';

import { AppConfigService } from '../services/app-config/app-config.service';
@Component({
  selector: 'app-maintenance-page',
  templateUrl: './maintenance-page.component.html',
  styleUrls: ['./maintenance-page.component.scss'],
})
export class MaintenancePage implements OnInit {
  param: any;
  isshowDisplay: boolean;

  constructor(
    private api: FormSettingService,
    private spinner: NgxSpinnerService
  ) {

  }

  ngOnInit() {
    this.getPortalSettings();
  }
  public getPortalSettings() {
    
    this.api.getPortalSettingDataBySettingCode("BROKER-MAINMODE").subscribe(response => {
      this.spinner.hide();

      if (response != null) {
        if (response.portalSetting.settingValue == 'true') {
          this.isshowDisplay = true;

        }
        if (response.portalSetting.settingValue == '' || response.portalSetting.settingValue == 'false') {
          this.isshowDisplay = false;
          var url = localStorage.getItem('oldURL');
          if (url && !url.includes('maintenance')) {
            window.location.href = url
          } else {
            window.location.href = '/';

          }

        }

      }

    });

  }

}

