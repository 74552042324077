import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
// import { environment } from 'src/environments/environment';
import {AppConfigService} from  "../app-config/app-config.service";


@Injectable({
  providedIn: 'root'
})
export class SignerService {
public apiUrl:string="";
public apiUrlPayment:string="";
  constructor(private http: HttpClient, private serviceConfig:AppConfigService) {
    
      this.apiUrl = `${this.serviceConfig.serverUrl}/Signer`;
      this.apiUrlPayment = `${this.serviceConfig.serverUrl}/LeasePaymentDetail`;
   }

  public getById(signerId: number, ownerId: number){
    const url = `${this.apiUrl}/GetBySignerIdAndOwnerId/${signerId}/${ownerId}`;
    return this.http.get<any>(url);
  }

  public getTenantInfo(id : number, signerTypeId: number, ownerId: number){
    const url = `${this.apiUrl}/GetTenantInfo/${id}/${signerTypeId}/${ownerId}`;
    return this.http.get<any>(url);
  }

  public getSignerStatusByLeaseId(leaseId : number, ownerId: number, entityTypeId: number, signerId: number){
    const url = `${this.apiUrlPayment}/GetSignerStatusByLeaseId/${leaseId}/${ownerId}/${entityTypeId}/${signerId}`;
    return this.http.get<any>(url);
  }
}
