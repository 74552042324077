import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-login-verification',
  templateUrl: './login-verification.component.html',
  styleUrls: ['./login-verification.component.scss']
})
export class LoginVerificationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
