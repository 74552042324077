<section id="form-signUp" class="bg-color">

  <div class="contentArea ml-0">


    <div class="mainWrapper">
      <div class="mainInner">
        <div class="error-wrap p-5" [hidden]="hideMsg">
          <h6 class="weight-400 text-display-14" [hidden]="hideMsg" style="text-align: left; color: red;">
            {{msg}}
          </h6>
          <!-- <div class="retryBtn text-right">
            <button [hidden]="hideMsg" kendoButton (click)="reloadPage()" class="btn-secondary rounded px-3">Try
              Again</button>
          </div> -->
        </div>

      </div>

    </div>

  </div>

</section>
