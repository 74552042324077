// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { env } from './env';

export const environment = {
  production: false,
  version: env.npm_package_version + '-dev',
  // serverUrl: 'https://localhost:44376/api',
  // formBaseUrl: 'http://localhost:65150/',
  // defaultLanguage: 'en-US',

  // blobStorageBaseUrlUpdated: 'https://rtwebapi.azurewebsites.net/api/BlobStorage/DownloadFile?filePath=',
  // plaidWebhookUrl: 'https://devpayments.renttango.com/Plaid/Plaid_Verification',
  // plaidKey: '8b2920c5dd49fb9b363ccc8e38eb40',
  // stripeKey: 'pk_test_Gr82XKpcuhJlzahqs19qpHLH00onJPTHOy',
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
