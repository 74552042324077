export class Adb2c {
  instance: string;
  clientId: string;
  domain: string;
  signinPolicy: string;
  passwordResetPolicy: string;
  callbackPathBase: string;
  scope: string;
  public constructor(init?: Partial<Adb2c>) {
    Object.assign(this, init);
  }
}

export class AppInsights {
  instrumentationKey: string;
  public constructor(init?: Partial<AppInsights>) {
    Object.assign(this, init);
  }
}

export class SmartyStreet {
  authId: string;
  key: number;
  authToken: string;
  public constructor(init?: Partial<SmartyStreet>) {
    Object.assign(this, init);
  }
}

export abstract class AppSettings {
  production: boolean;
  hmr: boolean;
  version: string;
  serverUrl: string;
  formBaseUrl: string;
  defaultLanguage: string;
  supportedLanguages: string[];
  adb2c: Adb2c;
  blobStorageBaseUrl: string;
  sessionTimeoutMins: number;
  appInsights: AppInsights;
  plaidWebhookUrl: string;
  plaidKey: string;
  stripeKey: string;
  blobStorageBaseUrlUpdated:string;
  smartyStreet: SmartyStreet;

  public constructor(init?: Partial<AppInsights>) {
    Object.assign(this, init);
  }
}




