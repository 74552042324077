import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { IconsModule } from '@progress/kendo-angular-icons';
import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { CounterComponent } from './counter/counter.component';
import { FetchDataComponent } from './fetch-data/fetch-data.component';
import { FooterComponent } from './footer/footer.component';
import { LoginVerificationComponent } from './login-verification/login-verification.component'
import { DocumentComponent } from './document/document.component';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BankValidationSetupComponent } from './bank-validation-setup/bank-validation-setup.component';
import { AppConfigService } from './services/app-config/app-config.service';
import { BrokerSignupComponent } from './broker-signup/broker-signup.component';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { UnitSelectorComponent } from './unit-selector/unit-selector.component';
import { BrokerListerComponent } from './broker-lister/broker-lister.component';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { ProgressBarModule } from '@progress/kendo-angular-progressbar';
import { BrokerVerificationComponent } from './broker-verification/broker-verification.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { BrokerApplicationComponent } from './broker-application/broker-application.component';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { GridModule } from '@progress/kendo-angular-grid';
import { ContextMenuComponent } from './common/context-menu/context-menu.component';
import { MultiSelectMenuComponent } from './common/multi-select-menu/multi-select-menu.component';
import { ExtendedDatePipe } from './shared/pipes/extended-date-pipe';
import { PopupModule } from '@progress/kendo-angular-popup';
import { EditApplicationComponent } from './application/edit-application/edit-application.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { MaintenancePage } from './maintenance-page/maintenance-page.component';
import { AuthGuard } from './auth-guard.guard';
import { FloatingLabelModule } from "@progress/kendo-angular-label";
// load configuration
const appInitializerFn = (configService: AppConfigService) => {
  return () => {
    return configService.setConfig();
  };
};

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    CounterComponent,
    FetchDataComponent,
    FooterComponent,
    LoginVerificationComponent,
    DocumentComponent,
    BankValidationSetupComponent,
    BrokerSignupComponent,
    UnitSelectorComponent,
    BrokerListerComponent,
    BrokerVerificationComponent,
    BrokerApplicationComponent,
    ContextMenuComponent,
    MultiSelectMenuComponent,
    ExtendedDatePipe,
    EditApplicationComponent,
    ConfirmDialogComponent,
    MaintenancePage
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    InputsModule,
    DropDownsModule,
    LayoutModule,
    IconsModule,
    ProgressBarModule,
    PopupModule,
    NotificationModule,
    NgxSpinnerModule,
    DialogsModule,
    GridModule,
    FloatingLabelModule,
    RouterModule.forRoot([
      { path: 'maintenance', component: MaintenancePage },
      { path: '', redirectTo: "broker/verification", pathMatch: 'full'  },
      { path: 'counter', component: CounterComponent , canActivate: [AuthGuard]},
      { path: 'fetch-data', component: FetchDataComponent , canActivate: [AuthGuard]},
      { path: 'login', component: LoginVerificationComponent , canActivate: [AuthGuard]},
      { path: 'document', component: DocumentComponent , canActivate: [AuthGuard]},
      { path: 'broker/signUp', component: BrokerSignupComponent, pathMatch: "full" , canActivate: [AuthGuard]},
      { path: 'broker/unitselector', component: UnitSelectorComponent, pathMatch: "full", canActivate: [AuthGuard] },
      { path: 'broker/activity', component: BrokerListerComponent, pathMatch: "full" , canActivate: [AuthGuard]},
      { path: 'bank-validation-inner', component: BankValidationSetupComponent, pathMatch: "full" , canActivate: [AuthGuard]},
      { path: 'broker/verification', component: BrokerVerificationComponent,pathMatch: "full", canActivate: [AuthGuard] },
      { path: 'editapplictaion', component: EditApplicationComponent,pathMatch: "full", canActivate: [AuthGuard] }
    ], {})
  ],
  providers: [
    AuthGuard,
    AppConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFn,
      multi: true,
      deps: [AppConfigService]
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
