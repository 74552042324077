import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import { environment } from 'src/environments/environment';
import {AppConfigService} from  "../app-config/app-config.service";



@Injectable({
  providedIn: 'root'
})
export class PlaidService {
public apiUrl:string="";
  constructor(private http: HttpClient,private configService:AppConfigService) { 
    const apiUrl = `${this.configService.serverUrl}/Plaidinfo`;

  }

  public CreateCustomerAndBankInfo(accountId: string, token: string, customerId: number, ownerId: number, status: string, userId: number, amount: number, personId:number): Observable<any> {
    
    const url = `${this.apiUrl}/CreateCustomerAndBankInfoTenant?accountId=${accountId}&customerId=${customerId}&token=${token}&ownerId=${ownerId}&status=${status}&userId=${userId}&AmountPaid=${amount}&PersonId=${personId}`;
    const httpOpts = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'tid': ownerId.toString()
      })
    };
    return this.http.get(url, httpOpts);
  }

  public UpdateCustomerAndConnectedAccount(accountId: string, token: string, customerId: number, ownerId: number, status: string, userId: number): Observable<any> {
    const url = `${this.apiUrl}/UpdateCustomerAndConnectedAccountInfo?accountId=${accountId}&customerId=${customerId}&token=${token}&ownerId=${ownerId}&status=${status}&userId=${userId}`;
    return this.http.patch(url, null);
  }
  public SaveCreditCard(token: string, customerId: number, ownerId: number): Observable<any> {
    const url = `${this.apiUrl}/SaveCreditCard?customerId=${customerId}&token=${token}&ownerId=${ownerId}`;
    return this.http.patch(url, null);
  }

  public getByCustomerId(customerId: number): Observable<any> {
    const url = `${this.apiUrl}/GetByCustomerId/${customerId}`;
    return this.http.get<any>(url);
  }
  public getlinkByCustomerId(customerId: number): Observable<any> {
    const url = `${this.apiUrl}/GetlinkByCustomerId/${customerId}`;
    return this.http.get<any>(url);
  }

  public getPublicTokenByCustomerId(customerId: number): Observable<any> {
    const url = `${this.apiUrl}/GetPublicTokenByCustomerId/${customerId}`;
    return this.http.get<any>(url);
  }

  public UpdateCreditCard(token: string, customerId: number, ownerId: number, creditCardId: string): Observable<any> {
    const url = `${this.apiUrl}/UpdateCreditCard?customerId=${customerId}&token=${token}&ownerId=${ownerId}&creditCardId=${creditCardId}`;
    return this.http.patch(url, null);
  }

}
