import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { retry } from 'rxjs/internal/operators';
import { FormSetting } from './../../shared/models/form-setting/form-setting.model';
import {AppConfigService} from  "../app-config/app-config.service";



@Injectable({
  providedIn: 'root'
})
export class FormSettingService {
public formSettingAPIUrl:string="";
  constructor(private http: HttpClient, private configService:AppConfigService) { 
    this.formSettingAPIUrl = `${this.configService.serverUrl}/FormSetting`;
  }
  httpOptions = { 
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'jwt-token'
    })
  };


  getFormSettingsByCustomerIdOrBuildingId(customerId: number, propertyId: number, ownerId: number): Observable<any> {
    const url = `${this.formSettingAPIUrl}/GetByCustomerIdAndOwnerId/${customerId}/${ownerId}?buildingId=${propertyId}`;
    this.httpOptions.headers.set('Authorization', 'my-new-auth-token');
    return this.http
      .get<FormSetting>(url, this.httpOptions)
      .pipe();
  }

  public getPortalSettingDataBySettingCode(settingCode: any): Observable<any> {
    const url = `${this.configService.serverUrl}/PortalSetting/GetPortalSettingDataBySettingCode?settingCode=${settingCode}`;
    return this.http.get<any>(url);
  }
}
