export class Lookup {
  lookupId: number | null;
  lookupCategoryID: number;
  name: string;
  keyValue1: string;
  keyValue2: string;
  displayText: string;
  description: string;
  isActive: boolean;
  parentLookupId: number;

  public constructor(init?: Partial<Lookup>) {
    Object.assign(this, init);
  }
}
