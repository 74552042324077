<div class="loginVerificationWrapper">
  <section class="loginVerification">
    <div class="inner">
        <div class="logoWrapper">
            <img src="assets/images/demo-company-logo.png" alt="" class="img-fluid">
        </div>
        <div class="sectionHeader">
            <h2>Login to our Document Portal</h2>
            <p class="signUptextouter">Dont have an account? <a href="" class="signUptext">Register here</a> </p>
        </div>
        <div class="fromWrapper">
              <form>
                <div class="form-row">
                  <div class="col-6 pr-3">
                    <div class="form-group mb-4">
                        <label for="Email">Email</label>
                        <input type="email" class="form-control" id="Email" aria-describedby="Email" placeholder="xsteuber@hotmail.com">
                      </div>
                  </div>
                  <div class="col-6 ">
                    <div class="form-group mb-4">
                        <label for="Phone">Phone</label>
                        <input type="text" class="form-control" id="phone" aria-describedby="Phone" placeholder="(964) 848-7436">
                      </div>
                  </div>
                  <div class="col-12 mb-3 emailVerify">
                    <div class="highlightedArea">
                        <div class="phoneIcon">
                            <img src="assets/images/email@2x.png"  class="img-fluid">
                        </div>
                        <div class="phoneCodeWrapper">
                                <p class="codeLable pl-3 pt-3">Email Code</p>
                                <div class="pl-3">
                                  <input class="ml-0" type="text" maxLength="1" size="1" id="txtEmailCode1" min="0" max="9" pattern="[0-9]{1}" value="4" />
                                        <input type="text" maxLength="1" size="1" id="txtEmailCode2" min="0" max="9" pattern="[0-9]{1}" value="4" />
                                        <input type="text" maxLength="1" size="1" id="txtEmailCode3" min="0" max="9" pattern="[0-9]{1}" value="4" />
                                        <input type="text" maxLength="1" size="1" id="txtEmailCode4" min="0" max="9" pattern="[0-9]{1}" value="4" />
                                        <input type="text" maxLength="1" size="1" id="txtEmailCode5" min="0" max="9" pattern="[0-9]{1}" value="4" />
                                        <input type="text" maxLength="1" size="1" id="txtEmailCode6" min="0" max="9" pattern="[0-9]{1}" value="4" />
                                </div>
                                <span class="alert"> &nbsp;</span>
                              
                            </div>
                        <div class="verifyBtnWrapper">
                            <button class="btn verifyBtn verifyBtnDis" id="btnVerifyEmail">Verify</button>
                            
                                <img src="assets/images/check-e.svg" class="img-fluid" style="width: 20px; margin-left: 8px;">
                            

                        </div>
                        <div class="text" style="  color: #290A1D;
                        font-family: Hind;
                        font-size: 14px;
                        font-weight: bold;">
                          Code will expire in :35
                            
                        </div>
                    </div>
                    
                  </div>
                  <div class="col-12">
                    <div class="highlightedArea">
                        <div class="phoneIcon">
                            <img src="assets/images/phone-button.png"  class="img-fluid">
                        </div>
                        <div class="phoneCodeWrapper">
                                <p class="codeLable pl-3 pt-3">Phone Code</p>
                                <div class="pl-3">
                                  <input class="ml-0" type="text" maxLength="1" size="1" id="txtPhoneCode1" min="0" max="9" pattern="[0-9]{1}" value="4" />
                                        <input type="text" maxLength="1" size="1" min="0" id="txtPhoneCode2" max="9" pattern="[0-9]{1}" value="4" />
                                        <input type="text" maxLength="1" size="1" min="0" id="txtPhoneCode3" max="9" pattern="[0-9]{1}" value="4" />
                                        <input type="text" maxLength="1" size="1" min="0" id="txtPhoneCode4" max="9" pattern="[0-9]{1}" value="4" />
                                        <input type="text" maxLength="1" size="1" min="0" id="txtPhoneCode5" max="9" pattern="[0-9]{1}" value="4" />
                                        <input type="text" maxLength="1" size="1" min="0" id="txtPhoneCode6" max="9" pattern="[0-9]{1}" value="4" />
                                </div>
                                <span class="alert">The code does not match</span>
                              
                            </div>
                        <div class="verifyBtnWrapper">
                            <button class="btn verifyBtn" id="btnVerify">Verify</button>
                            
                                <img src="assets/images/cancel.png" class="img-fluid" style="width: 30px; margin-left: 8px;">
                            

                        </div>
                        <div class="text">
                            <a href="" id="lnkResend">Resend Code to Phone</a>
                            
                        </div>
                    </div>
                    
                  </div>
                  <div class="text-center w-100 ">
                    <button class="btn loginBtn" id="btnLogin">Log in</button>
                  </div>
                  
                </div>
              </form>
        </div>
    </div>
</section>
</div>
