import { Injectable } from '@angular/core';
import { Dropdown } from '../../shared/models/dropdown.model';
import { Observable } from 'rxjs';
import { Lookup } from '../../shared/models/lookup/lookup.model';
import { retry } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import {AppConfigService} from  "../app-config/app-config.service";

@Injectable({
  providedIn: 'root'
})

export class LookupService {
  readonly apiUrl = `${this.config.serverUrl}/Lookup`;
  constructor(private http: HttpClient, private config: AppConfigService) {
  }

  public getByCategoryId(categoryId: number): Observable<any> {

    let catIds = [categoryId] as number[];
    return this.getByCategoryIds(catIds);
  }

  public getByCategoryIds(categoryIds: number[]): Observable<any> {

    const url = `${this.apiUrl}/GetLookupListByCatIds`;
    return this.http.post(url, categoryIds);
  }

  public getLookupListByCategoryIds(categoryId: number , ownerId): Observable<any> {
    const url = `${this.apiUrl}/GetLookupListByCategoryIds/${categoryId}/${ownerId}`;
    return this.http.get(url);
  }

  public getLookupNameById(lookupData: Dropdown[], id: number) {
    const filtered = lookupData.filter(x => x.value == id);
    return filtered[0] ? filtered[0].name : '';
  }

  public getLookupNameByIdNew(lookupData: Lookup[], id: number) {
    const filtered = lookupData.filter(x => x.lookupId == id);
    return filtered[0] ? filtered[0].keyValue1 : '';
  }

  public getCountryList(): Observable<any> {
    const url = `${this.apiUrl}/GetCountryList`;
    return this.http.get(url);
  }

  public getStates(): Dropdown[] {

    let states: Dropdown[] = new Array();
    states.push({ name: "Alabama", value: 1 });
    states.push({ name: "Alaska", value: 2 });
    states.push({ name: "American Samoa", value: 3 });
    states.push({ name: "Arizona", value: 4 });
    states.push({ name: "Arkansas", value: 5 });
    states.push({ name: "California", value: 6 });
    states.push({ name: "Colorado", value: 7 });
    states.push({ name: "Connecticut", value: 8 });
    states.push({ name: "Delaware", value: 9 });
    states.push({ name: "District Of Columbia", value: 10 });
    states.push({ name: "Federated States Of Micronesia", value: 11 });
    states.push({ name: "Florida", value: 12 });
    states.push({ name: "Georgia", value: 13 });
    states.push({ name: "Guam", value: 14 });
    states.push({ name: "Hawaii", value: 15 });
    states.push({ name: "Idaho", value: 16 });
    states.push({ name: "Illinois", value: 17 });
    states.push({ name: "Indiana", value: 18 });
    states.push({ name: "Iowa", value: 19 });
    states.push({ name: "Kansas", value: 20 });
    states.push({ name: "Kentucky", value: 21 });
    states.push({ name: "Louisiana", value: 22 });
    states.push({ name: "Maine", value: 23 });
    states.push({ name: "Marshall Islands", value: 24 });
    states.push({ name: "Maryland", value: 25 });
    states.push({ name: "Massachusetts", value: 26 });
    states.push({ name: "Michigan", value: 27 });
    states.push({ name: "Minnesota", value: 28 });
    states.push({ name: "Mississippi", value: 29 });
    states.push({ name: "Missouri", value: 30 });
    states.push({ name: "Montana", value: 31 });
    states.push({ name: "Nebraska", value: 32 });
    states.push({ name: "Nevada", value: 33 });
    states.push({ name: "New Hampshire", value: 34 });
    states.push({ name: "New Jersey", value: 35 });
    states.push({ name: "New Mexico", value: 36 });
    states.push({ name: "New York", value: 37 });
    states.push({ name: "North Carolina", value: 38 });
    states.push({ name: "North Dakota", value: 39 });
    states.push({ name: "Northern Mariana Islands", value: 40 });
    states.push({ name: "Ohio", value: 41 });
    states.push({ name: "Oklahoma", value: 42 });
    states.push({ name: "Oregon", value: 43 });
    states.push({ name: "Palau", value: 44 });
    states.push({ name: "Pennsylvania", value: 45 });
    states.push({ name: "Puerto Rico", value: 46 });
    states.push({ name: "Rhode Island", value: 47 });
    states.push({ name: "South Carolina", value: 48 });
    states.push({ name: "South Dakota", value: 49 });
    states.push({ name: "Tennessee", value: 50 });
    states.push({ name: "Texas", value: 51 });
    states.push({ name: "Utah", value: 52 });
    states.push({ name: "Vermont", value: 53 });
    states.push({ name: "Virgin Islands", value: 54 });
    states.push({ name: "Virginia", value: 55 });
    states.push({ name: "Washington", value: 56 });
    states.push({ name: "West Virginia", value: 57 });
    states.push({ name: "Wisconsin", value: 58 });
    states.push({ name: "Wyoming", value: 59 });
    return states;
  }
}
