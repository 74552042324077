import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Config } from 'protractor';
// import AppConfig from "../../../assets/";//'assets/config.json';
// import AppConfig from '@app/assets/config.json';
import { AppSettings } from "../../shared/models/app-settings/app-settings";//'@app/shared/models/app-settings/app-settings';



@Injectable({
  providedIn: 'root'
})
export class AppConfigService extends AppSettings {
  config: Config;
  private configuration: AppSettings;

  constructor(
    private httpClient: HttpClient
  ) { super(); }

  setConfig() {
    
    return this.httpClient
      .get<AppSettings>('./assets/config.json')
      .toPromise()
      .then(config => {
        this.production = config.hmr;
        this.hmr = config.hmr;
        this.version = config.version;
        this.serverUrl = config.serverUrl;
        this.formBaseUrl = config.formBaseUrl;
        this.defaultLanguage = config.defaultLanguage;
        this.supportedLanguages = config.supportedLanguages;
        this.adb2c = config.adb2c;
        this.blobStorageBaseUrl = config.blobStorageBaseUrl;
        this.sessionTimeoutMins = config.sessionTimeoutMins;
        this.appInsights = config.appInsights;
        this.plaidWebhookUrl = config.plaidWebhookUrl;
        this.plaidKey = config.plaidKey;
        this.stripeKey = config.stripeKey;
        this.smartyStreet = config.smartyStreet;      });
  }

  readConfig(): AppSettings {
    return this.configuration;
  }
}
