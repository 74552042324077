import { Component, Input, Output, EventEmitter, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-multi-select-menu',
  templateUrl: './multi-select-menu.component.html',
  styleUrls: ['./multi-select-menu.component.scss']
})
export class MultiSelectMenuComponent {

  private selectedItems = [] as any[];

  public show = false;
  public marginLeft: number;

  @Input()
  public initialSelected: any[] = [];

  @Input()
  public width: number = 250;

  @Input()
  public maxHeight: number = 255;

  @Input()
  public anchor: Element

  @Input()
  public data: any[] = [];

  @Input()
  public displayField: string;

  @Output()
  public select: EventEmitter<any> = new EventEmitter<any>();

  private unsubscribeWindowClick: any;
  private unsubscribeMouseWheelMove: any;

  constructor(private renderer: Renderer2) {
    this.subscribeWindowClick();
    this.subscribeMouseWheelMove();
  }

  public ngOnInit() {

    this.selectedItems = this.initialSelected;
    this.marginLeft = -(this.width) + 22;

    this.anchor.addEventListener("click", ($event) => {
      this.show = true;
    });
  }

  public ngOnDestroy(): void {
    this.unsubscribe();
  }

  public get scrollableHeight(): string {
    return `${this.maxHeight}px`;
  }

  public menuItemSelected(item: any): void {
    
    const index = this.selectedItems.indexOf(item);
    const isChecked = index < 0;

    if (isChecked) {
      this.selectedItems.push(item);
    }
    else {
      this.selectedItems.splice(index, 1);
    }

    this.select.emit(this.selectedItems);
  }

  public setCheckState(item: any): boolean {
    return this.selectedItems.indexOf(item) > -1;
  }

  public clearAll(): void {
    this.selectedItems = [];
    this.select.emit(this.selectedItems);
  }

  private subscribeWindowClick(): void {

    this.unsubscribeWindowClick = this.renderer.listen('window', 'mousedown', ($event) => {

      const isMenuItemClicked = $event.target.attributes.role && $event.target.attributes.role.nodeValue === "menu-item";

      if ($event.target == this.anchor || isMenuItemClicked) {
        return;
      }

      this.show = false;
    });
  }

  private subscribeMouseWheelMove(): void {

    this.unsubscribeMouseWheelMove = this.renderer.listen('document', 'onclick', () => {
      this.show = false;
    });
  }

  private unsubscribe(): void {
    this.unsubscribeWindowClick();
    this.unsubscribeMouseWheelMove();
  }
}
