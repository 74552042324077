import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router, Params } from '@angular/router';
// import { environment } from 'src/environments/environment';
import { AppConfigService } from "../../app/services/app-config/app-config.service";
import { BrokerContactService } from '../services/broker-contact/broker-contact.service';
import { FormSettingService } from '../services/form-setting/form-setting.service';
import { SignerService } from '../services/signer/signer.service';
import { FormSetting } from '../shared/models/form-setting/form-setting.model';
import { Signer } from '../shared/models/signer/signer.model';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss']
})
export class NavMenuComponent implements OnInit {
  isExpanded = false;
  title = "";
  public href: string = "";
  public propertyId: number;
  public customerId: number;
  public formSetting: FormSetting;
  public logoPath: string = "assets/images/image.png";
  public ownerId: number;
  public signerId: number;
  public signer: Signer;
  public userName = "";
  public avatarShape = 'square';
  public n = "KK";
  public initials: string = "";
  public companyColor: string = "White";
  public signNowReturn: boolean;
  public allIds: string;
  public signnowRed: string | null = "";
  public tId: number;
  public leaseId: number;
  public entityTypeId: number;
  public bcid: number;
  public fontColor:string='white';

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  constructor(private router: Router,
    private route: ActivatedRoute,
    private formApi: FormSettingService,
    private signApi: SignerService,
    private titleService: Title,
    private configService: AppConfigService,
    private brokerContactService: BrokerContactService
  ) {

  }

  ngOnInit() {
    var brokerInfo = JSON.parse(sessionStorage.getItem("brokerInfo"));
    if(brokerInfo != null){
      this.customerId =  brokerInfo.cid;
      this.ownerId = brokerInfo.oid;
      this.bcid = brokerInfo.bcid;
      this.propertyId = brokerInfo.pid;
    }
   
    if (this.bcid) {
      this.brokerContactService.getBrokerName(this.bcid, this.ownerId, this.customerId).subscribe(respose => {

        if (respose != null) {
          this.userName = respose.broker.firstName + " " + respose.broker.lastName;
          this.initials = respose.broker.firstName.substring(0, 1).toUpperCase() + respose.broker.lastName.substring(0, 1).toUpperCase();

          sessionStorage.setItem("brokerName", respose.broker.firstName + " " + respose.broker.lastName);
        }
      })
    }
    if (this.ownerId > 0) {
      this.formApi.getFormSettingsByCustomerIdOrBuildingId(this.customerId, this.propertyId, this.ownerId).subscribe(response => {
        this.formSetting = response;
        if (this.formSetting != null) {
          if (this.formSetting.logo1 != "") {
            
            this.logoPath = this.configService.blobStorageBaseUrl + this.formSetting.logo1;
            this.companyColor = this.formSetting.backgroundColor;
            localStorage.setItem("companyColor", this.formSetting.backgroundColor);
            localStorage.setItem("companyLogo", this.logoPath);
            this.fontColor= this.formSetting.fontColor;
          }
          
        }
      });
    }
    this.route.queryParams.subscribe(params => {
      this.allIds = params['PidCidOidSid'];
      if (this.allIds) {

        this.signnowRed = localStorage.getItem("signnowRedirect");
        let idsList = this.allIds.split(",");
        this.signNowReturn = idsList[4] == "true" ? true : false;
        if (this.signNowReturn == false) {
          this.allIds = this.allIds.replace("false", "true");

          window.top.location.href = `/blank?PidCidOidSid=${this.allIds}`;
        }
        if (this.signNowReturn == true) {
          this.propertyId = Number(idsList[0]);
          this.customerId = Number(idsList[1]);
          this.ownerId = Number(idsList[2]);
          this.signerId = Number(idsList[3]);
          this.signNowReturn = idsList[4] == "true" ? true : false;
          this.leaseId = Number(idsList[5]);
          this.entityTypeId = Number(idsList[6]);
          this.getSigner();
        }

        // // if(this.signnowRed == null)
        // // {
        // //   let idsList = this.allIds.split(",");
        // //   this.propertyId = Number(idsList[0]);
        // //   this.customerId = Number(idsList[1]);
        // //   this.ownerId = Number(idsList[2]);
        // //   //this.contractId = Number(idsList[3]);  
        // //   this.signerId = Number(idsList[3]);
        // //   this.signNowReturn = idsList[4] == "true" ? true : false;
        // //   this.leaseId = Number(idsList[5]);
        // //   this.entityTypeId = Number(idsList[6]);
        // //   localStorage.setItem("signnowRedirect","true");
        // //   this.getSignerRedirect();
        // //   //window.top.location.href = "/lease-history?PidCidOidSid="+this.allIds;
        // //   //window.top.location.href = `/leasepayment/${this.ownerId}/${this.customerId}/${this.tId}/${this.leaseId}`;
        // //   //this.router.navigateByUrl(`/`);
        // //   if(this.router.url.includes("sign"))
        // //   {
        // //       this.title = "Signing";
        // //   }
        // //   if(this.router.url.includes("leasepayment"))
        // //   {
        // //       this.title = "Payments";
        // //       this.setTitle("Tenant Portal - RentTango - Payments");
        // //   }
        // // }
        // // else
        // // {
        // //   let idsList = this.allIds.split(",");
        // //   this.propertyId = Number(idsList[0]);
        // //   this.customerId = Number(idsList[1]);
        // //   this.ownerId = Number(idsList[2]);
        // //   //this.contractId = Number(idsList[3]);  
        // //   this.signerId = Number(idsList[3]);
        // //   this.signNowReturn = idsList[4] == "true" ? true : false;
        // //   localStorage.removeItem("signnowRedirect");
        // //   //this.router.navigateByUrl(`/`);
        // //   if(this.router.url.includes("sign"))
        // //   {
        // //       this.title = "Signing";
        // //   }
        // //   this.getSigner();
        // // }

        // // if(this.router.url.includes("lease-histor"))
        // // {
        // //     this.title = "Lease History";
        // //     this.setTitle("Tenant Portal - RentTango - Lease History");
        // // }        
      }
      else {
        this.propertyId = Number(params['pid']);
        this.customerId = Number(params['cid'] || params['cId']);
        this.ownerId = Number(params['oid'] || params['oId']);
        this.signerId = Number(params['snid']);
        if (this.router.url.includes("sign")) {
          this.title = "Signing";
          this.setTitle("Tenant Portal - RentTango - Document Signing");
        }
        if (this.router.url.includes("lease-histor")) {
          this.title = "Lease History";
          this.setTitle("Tenant Portal - RentTango - Lease History");
        }
        if (this.router.url.includes("leasepayment")) {
          this.title = "Payments";
          this.setTitle("Tenant Portal - RentTango - Payments");
        }
      }


      if (this.ownerId > 0) {
        this.formApi.getFormSettingsByCustomerIdOrBuildingId(this.customerId, this.propertyId, this.ownerId).subscribe(response => {
          this.formSetting = response;
          if (this.formSetting != null) {
            if (this.formSetting.logo1 != "") {
              
              this.logoPath = this.configService.blobStorageBaseUrl + this.formSetting.logo1;
              this.companyColor = this.formSetting.backgroundColor;
              localStorage.setItem("companyColor", this.formSetting.backgroundColor);
              localStorage.setItem("companyLogo", this.logoPath);
              this.fontColor= this.formSetting.fontColor;
            }
          }
        });

        this.getSigner();
      }
    });
  }

  public getSigner() {
    this.signApi.getById(this.signerId, this.ownerId).subscribe(
      response => {
        if (response != null) {
          this.signer = response.getSigner;
          if (this.signer) {
            this.signApi.getTenantInfo(this.signer.tenantId, this.signer.signerTypeId, this.ownerId).subscribe(
              response => {
                
                if (response != null) {
                  this.userName = response.firstName + " " + response.lastName;
                  this.initials = response.firstName.substring(0, 1) + response.lastName.substring(0, 1);
                  this.initials = this.initials.toUpperCase();
                  this.leaseId = this.signer.leaseId;
                  this.tId = this.signer.tenantId;
                  localStorage.setItem("userName", this.userName);
                  localStorage.setItem("initials", this.initials);

                }
              });
          }

        }
      });
  }

  public getSignerRedirect() {
    this.signApi.getById(this.signerId, this.ownerId).subscribe(
      response => {
        if (response != null) {
          this.signer = response.getSigner;
          if (this.signer) {
            this.signApi.getSignerStatusByLeaseId(this.leaseId, this.ownerId, this.entityTypeId, this.signer.signerId).subscribe(signerRes => {
              if (signerRes == true) {
                this.signApi.getTenantInfo(this.signer.tenantId, this.signer.signerTypeId, this.ownerId).subscribe(
                  response => {
                    
                    if (response != null) {
                      this.userName = response.firstName + " " + response.lastName;
                      this.initials = response.firstName.substring(0, 1).toUpperCase() + response.lastName.substring(0, 1).toUpperCase();
                      this.leaseId = this.signer.leaseId;
                      this.tId = this.signer.tenantId;
                      localStorage.setItem("userName", this.userName);
                      localStorage.setItem("initials", this.initials);
                      window.top.location.href = `/leasepayment?cId=${this.customerId}&oId=${this.ownerId}&leaseId=${this.leaseId}&snid=${this.signerId}`;
                    }
                  });
              }
              else {
                this.signApi.getTenantInfo(this.signer.tenantId, this.signer.signerTypeId, this.ownerId).subscribe(
                  response => {
                    if (response != null) {
                      this.userName = response.firstName + " " + response.lastName;
                      this.initials = response.firstName.substring(0, 1).toUpperCase() + response.lastName.substring(0, 1).toUpperCase();
                      this.leaseId = this.signer.leaseId;
                      this.tId = this.signer.tenantId;
                      window.top.location.href = `/lease-history?cId=${this.customerId}&oId=${this.ownerId}&leaseId=${this.leaseId}&snid=${this.signerId}`;
                    }
                  });
              }
            });

          }

        }
      });
  }

  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }
  public logout(){
    sessionStorage.removeItem("verifiedstatus");
    var brokerInfo = JSON.parse(sessionStorage.getItem("brokerInfo"));
    //cid=2&oid=2&pid=8&bid=71&bcid=73
    this.router.navigateByUrl(`broker/verification?cid=${brokerInfo.cid}&oid=${brokerInfo.oid}&bid=${brokerInfo.brid}&bcid=${brokerInfo.bcid}`);

  }
}
