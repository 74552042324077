<app-nav-menu></app-nav-menu>
<div class="edit-application-wrap" style="background-image: url('../../../../assets/images/RTLoader.gif');background-repeat: no-repeat;background-attachment: fixed;background-position: center;">
    <div class="settingHeader">
      <div class="breadcrumbHolder">
        <ul>
          <li>
            <a routerLink="/broker/activity">
              Broker Portal
              <span class="fas fa-long-arrow-alt-right float-none"></span>
            </a>
            </li>
          <li> Form Submissions</li>
        </ul>
      </div>
    </div>
    <!--<embed src="http://localhost:65150/?aid=1&isAdminEditMode=true" style="width:100%; height:100%" />-->
    <!-- <ngx-spinner type="null">
      <img src='../../../../assets/images/RTLoader.gif' /> 
    </ngx-spinner> -->
    <iframe #iframe [src]="urlSafe" style="width:100%; height:100%; border:none" *ngIf= "urlSafe !== ''" ></iframe>
  
  </div>
  