<kendo-popup *ngIf="show" [offset]="offset">
    <div class="wrapper" [ngStyle]="{'width': width+'px', 'margin-left': marginLeft + 'px'}">
        <div [hidden]="!showUpPointer" class="pointer-up"><img src="/assets/images/action_menu_pointer.png" /></div>
        <div *ngIf="!showUpPointer" style="height:7px;"></div>
        <ul class="menu">
            <li (click)="menuItemSelected(item)" *ngFor="let item of data">
                {{ item[displayField] }}
            </li>
        </ul>
        <div [hidden]="showUpPointer" class="pointer-down"><img src="/assets/images/action_menu_pointer_down.png" />
        </div>
        <div *ngIf="showUpPointer" style="height:7px;"></div>
    </div>
</kendo-popup>