import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {AppConfigService} from  "../app-config/app-config.service";
import { retry } from 'rxjs/operators';



@Injectable({
  providedIn: 'root'
})
export class UnitService {
  readonly apiUrl = `${this.config.serverUrl}/Unit`;
  constructor(
    private http: HttpClient, private config: AppConfigService
  ) { }

  public getByPropertyIdForDropdown(propertyId: number,ownerId:number): Observable<any> {

    const url = `${this.apiUrl}/GetByPropertyIdBrokerUI/${propertyId}`;
    const httpOpts = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'tid': ownerId.toString()
      })
    };
    return this.http.get(url,httpOpts);
  }
}
