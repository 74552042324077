export enum ScreeningProgress {
    Lead = 5,
    InviteSent = 10,
    InProgress = 20,
    Paid = 70,
    ConflictingData = 80,
    MissingDocments = 90,
    Screening = 95,
    Screened = 100
}
