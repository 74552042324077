
import { AuditableEntity } from '../auditable-entity/auditable-entity.model';

export class Broker extends AuditableEntity {
    brokerId: number
    brokerageId: number;
    firstName: string;
    middleName: string;
    lastName: string;
    phone1: string;
    phone2: string;
    mobilePhone: string;
    email1: string;
    email2: string;
    address1: string;
    address2: string;
    location: string;
    zipCode: string;
    city: string;
    state: string;
    countryId: number;
    countryCode: string;
    fax: string;
    website: string;
    profile: string;
    flag: number;
    customerId: number;
    ownerId: number;
    brokerContactId: number;
    accountStatus: number;
    propertyId:number;
    public constructor(init?: Partial<Broker>) {
        super(init);
        Object.assign(this, init);
    }
}
