import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DialogRef } from '@progress/kendo-angular-dialog';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styles: ['modal-title { font-size: 100px; }']
})
export class ConfirmDialogComponent implements OnInit {

  public closeDialogOnCancel = true;
  public showCancelButton = true;
  public showOkButton = true;
  public showInfoIcon = true;
  public cancelButtonText = 'No';
  public okButtonText = 'Yes';
  public headerText = 'Confirm Action?';
  public confirmationMsg = 'Are your sure?';
  public iconPath = "assets/images/ico_warning.png";
  public isReasonShown: boolean = false;
  public txtReason = '';
  public isInvalidReason: boolean = false;

  public onOkClick = new EventEmitter<any>();
  public onCancelClick = new EventEmitter<any>();
  public dialog: DialogRef;

  constructor() { }

  ngOnInit() {
  }

  closeDialog() {
    this.dialog.close();
  }

  onCancel(event: any) {
    this.onCancelClick.next(event);

    if (this.closeDialogOnCancel) {
      this.closeDialog();
    }
  }

  onOk(event: any) {
    this.onOkClick.next(event);
  }
}