import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { retry } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfigService } from '../app-config/app-config.service';



@Injectable({
  providedIn: 'root'
})
export class ActivityService {
  readonly apiUrl = `${this.config.serverUrl}/Activity`;
  readonly apiUrlApplicantSrch = `${this.config.serverUrl}`;

  constructor(private http: HttpClient, private config: AppConfigService) { }


  public getScreeningActivitiesByBrokerId(ownerId: number, customerId: number, brokerId: number): Observable<any> {
    const url = `${this.apiUrl}/GetScreeningActivitiesByBrokerId/${customerId}/${brokerId}`;
    const httpOpts = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'tid': ownerId.toString()
      })
    };
    return this.http.get(url, httpOpts);
  }
}
