import { AppConfigService } from '../../services/app-config/app-config.service';

//const adb2c = environment.adb2c;

export const AppConstants = {

    Urls: {
       // LoginUrl: `${adb2c.instance}/${adb2c.domain}/oauth2/v2.0/authorize?p=${adb2c.signinPolicy}&client_id=${adb2c.clientId}&redirect_uri=${adb2c.callbackPathBase}/signin&scope=${adb2c.scope}&response_type=id_token&prompt=login`,

      //  ResetPasswordUrl: `${adb2c.instance}/${adb2c.domain}/oauth2/v2.0/authorize?p=${adb2c.passwordResetPolicy}&client_id=${adb2c.clientId}&redirect_uri=${adb2c.callbackPathBase}/resetpassword&scope=${adb2c.scope}&response_type=id_token&prompt=login`,

    //ProductionWelcomeUrl: `${adb2c.callbackPathBase}/welcome`,

        WelcomeUrl: `${window.location.origin}/welcome`,

    //ProductionOnboardingUrl: `${adb2c.callbackPathBase}/onboarding`,

        OnboardingUrl: `${window.location.origin}/onboarding`,

        OnboardingStep2: `${window.location.origin}/setup-billing`,

        OnboardingStep3: `${window.location.origin}/setup-screening`,

        OnboardingStep4: `${window.location.origin}/receive-funds`,

        OnboardingStep5: `${window.location.origin}/thank-you`
    },

    HttpStatus: {
        Unauthorized: 401
    },

    Toaster: {
        CssClass: 'toast-notification',
    },

    ErrorMsgs: {
        HttpGeneralErrorMsg: "Something bad happened. Please try again later. If problem persists, please contact support team"
    },
    PropertyOptionCode: {
        FeeType: "FT01",
        RentalType: "RT01",
        StructureType: "ST01",
        AccessType: "AT01",
        LegalStatusType: "LGSTY01",
        AccountingStatusType: "ACSTY01"
    },
    UnitOptionCode: {
        RenovationType: "RV01",
        LeasingType: "LT01",
        ActivityType: "AV01",
        StatusType: "US01",
        LegalStatusType: "LGSTY01",
        AccountingStatusType: "ACSTY01"
        
    },
    SortCssClass: {
        Asc: "k-i-sort-asc-sm",
        Desc: "k-i-sort-desc-sm",
        None: "k-icon custom-sort"
    },
    Masking: {
        PhoneMask: '(000) 000-0000'
    },
    Filters: {
        Lead: 'app-lead-filter',
        Screening: 'app-screening-filter',
        Recommendation: 'app-recommendation-list',
        Leasing: 'app-leasing-filter',
        ContractStatus: 'app-contract-status'
    },
    Regex: {
        Longitude: "^(\\+|-)?(?:180(?:(?:\\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\\.[0-9]{1,12})?))$",
        Latitude: "^(\\+|-)?(?:90(?:(?:\\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\\.[0-9]{1,12})?))$",
        SecurityDeposit: "^\d{0,1}(\.\d{1,2})?$",
        SecurityDepositUnit:"^\d{0,5}(\.\d{1,2})?$"
    },
    LeadOptionCode: {
        LeadType: "LS01"
    },
    ContractManagement : {
        TagHolder : '<span style="color:white; background-color:#890e4f; display:inline-block; border-radius: 3px; padding: 0px 2px">@@tagHolder@@</span>'
    }
}
