import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { UtilityMethods } from 'src/helper/utility-methods';

@Pipe({
  name: 'date'
})
export class ExtendedDatePipe extends DatePipe implements PipeTransform {

  //just test
  constructor(
    private utilityMethods: UtilityMethods,
  ) {
    super(utilityMethods.Locale);
  }
  // transform(DateValue: null | undefined, args?: string): null;
  transform(value: any, format?: string): any {

    if (value) {

      value = new Date(value);

      if (format && format.indexOf('no-time-zone-effect') > -1) {

        format = (format.split('~'))[0];
        return super.transform(value, format);
      }

      if (format === 'moments') {
        return this.formatIntoMoments(value);
      }

    //   const offset = this.currentUser ? this.currentUser.timeZoneOffset || "00:00" : "00:00";
    //   const hoursMins = offset.split(":");
    //   const hours = parseInt(hoursMins[0]);
    //   const mins = parseInt(hoursMins[1]);
    //   value.setHours(value.getHours() + hours);
    //   value.setMinutes(value.getMinutes() + mins + this.currentUser.dstOffset);
    }

    return format ?
      super.transform(value, format) : super.transform(value);
  }

  private formatIntoMoments(date: any) {

    let moments = "";
    const now = this.utilityMethods.UtcNow as any;
    const daysPassed = Math.trunc((now - date) / (1000 * 60 * 60 * 24));
    const hoursPassed = Math.trunc((now - date) / (1000 * 60 * 60));
    const minutesPassed = Math.trunc((now - date) / (1000 * 60));

    if (daysPassed > 0) {
      moments = daysPassed > 1 ? `${daysPassed} days ago` : `${daysPassed} day ago`;
    }
    else if (hoursPassed > 0) {
      moments = hoursPassed > 1 ? `${hoursPassed} hours ago` : `${hoursPassed} hour ago`;
    }
    else {
      moments = (minutesPassed === 0 || minutesPassed > 1) ? `${minutesPassed} minutes ago` : `${minutesPassed} minute ago`;
    }

    return moments;
  }
};
