<section class="documentWrapper">
    <div class="inner">
      <div class="header">
        <p>View all of your previously signed documents here.</p>
      </div>
      <div class="content">
        <div class="tailWrapper">
          <div class="tail">
            <div class="description">
              <p class="text">Fair Market Parking Space Rider for Westchester</p>
              <p class="date">Optional Rider — 11/28/2019</p>
            </div>
            <a href="#" class="viewBtn">View</a>
          </div>
        </div>
        <div class="tailWrapper">
          <div class="tail">
            <div class="description">
              <p class="text">Fair Market Parking Space Rider for Westchester</p>
              <p class="date">Optional Rider — 11/28/2019</p>
            </div>
            <a href="#" class="viewBtn">View</a>
          </div>
        </div>
        <div class="tailWrapper">
          <div class="tail">
            <div class="description">
              <p class="text">Fair Market Parking Space Rider for Westchester</p>
              <p class="date">Optional Rider — 11/28/2019</p>
            </div>
            <a href="#" class="viewBtn">View</a>
          </div>
        </div>
        <div class="tailWrapper">
          <div class="tail">
            <div class="description">
              <p class="text">Fair Market Parking Space Rider for Westchester</p>
              <p class="date">Optional Rider — 11/28/2019</p>
            </div>
            <a href="#" class="viewBtn">View</a>
          </div>
        </div>
      </div>
    </div>
  </section>