import { state } from '@angular/animations';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { FormSettingService } from './services/form-setting/form-setting.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {
  isEnableMaitenance: boolean = false;
  constructor(

    private router: Router,
    private api: FormSettingService,
    private spinner: NgxSpinnerService

  ) {

  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    
    localStorage.setItem('oldURL', state.url);

    var res = this.getPortalSettings();
    this.spinner.hide();

    return res;
  }

  public async getPortalSettings(): Promise<boolean> {
    
    let response: any;
    response = await this.api.getPortalSettingDataBySettingCode("BROKER-MAINMODE").toPromise();
    if (response != null) {
      if (response.portalSetting.settingValue == 'true') {
        this.router.navigateByUrl(`/maintenance`);

        return false;
      }

    }
    return true;

  }

}
