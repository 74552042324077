<div class="SettingContents">
    <div class="setting-wrappers">
        <div class="dialogHolder">
            <div class="header">
                <h2>New Application</h2>
            </div>
            <form [formGroup]="form" (ngSubmit)="onSubmit()">
                <div class="formHolder pb-0">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="controlHolder">
                                <label>Select Property</label>
                                <kendo-floatinglabel>
                                    <kendo-combobox formControlName="propertyId" id="ddlPropertyList" [clearButton]="false" [data]="properties" [textField]="'propertyName'" [valueField]="'propertyId'" [valuePrimitive]="true" required (valueChange)="onPropertyChange($event)">
                                    </kendo-combobox>
                                </kendo-floatinglabel>
                                <div *ngIf="submitted && !isPropertyRequired" class="k-tooltip-validation">
                                    <div *ngIf="!isPropertyRequired" class="text-error">Property is required</div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="controlHolder">
                                <label>Select Unit</label>
                                <kendo-floatinglabel>
                                    <kendo-combobox formControlName="unitId" id="ddlUnitsList" [clearButton]="false" [data]="groupedUnits" [textField]="'unitNumber'" [valueField]="'unitId'" [valuePrimitive]="true" required>
                                    </kendo-combobox>
                                </kendo-floatinglabel>
                                <div *ngIf="submitted && formFields.unitId.errors" class="k-tooltip-validation">
                                    <div *ngIf="formFields.unitId.errors.required" class="text-error">Unit is required</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--  -->
                <div class="btnArea">
                    <div class="leftArea">
                        <button type="button" id="btnCancel" kendoButton class="btn-secondary" (click)="closeAddEdit()">Cancel</button>
                    </div>
                    <div class="RightArea">
                        <button kendoButton class="btn-primary" id="btnSelect" primary="true">Select</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>