import { Injectable } from '@angular/core';
import { HttpClient, HttpBackend, HttpHeaders } from '@angular/common/http';
import {AppConfigService} from  "../app-config/app-config.service";
import { Observable } from 'rxjs';
import { BrokerContact } from '../../shared/models/broker-contact/broker-contact.model';
import { retry } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class BrokerContactService {
  readonly apiUrl = `${this.config.serverUrl}/BrokerContact`;
  readonly apiBrokerUrl = `${this.config.serverUrl}/Broker`;
  readonly apiCustomerUrl = `${this.config.serverUrl}/Customer`;
  constructor(
    private http: HttpClient, 
    private config: AppConfigService,
    private httpHandler: HttpBackend,
  ) { }


  public add(brokerContact: BrokerContact, tId:number, cId:number): Observable<any> {
    const httpClient = new HttpClient(this.httpHandler);
    const httpOpts = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'tid': tId.toString(),
        'cId': cId.toString()
      })
    };
    
    return httpClient.post<any>(this.apiUrl, brokerContact, httpOpts);
  //  return this.http.post(this.apiUrl, brokerContact);
  }

  public update(brokerContact: BrokerContact, tId:number,cId:number): Observable<any> {
    const httpOpts = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'tid': tId.toString(),
        'cId': cId.toString()
      })
    };
    return this.http.put(this.apiUrl, brokerContact,httpOpts);
  }

  public getById(brokerContactId: number, brokerId: any, tId:number,cId:number): Observable<any> {
    const httpOpts = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'tid': tId.toString(),
        'cId': cId.toString()
      })
    };

    const url = `${this.apiUrl}/GetBrokerContactById/${brokerContactId}/${brokerId}`;
    return this.http
      .get<BrokerContact>(url,httpOpts)
      .pipe(retry(3));
  }
  
public getBrokerName(brokerContactId: number, tId:number,cId:number): Observable<any> {
  const httpOpts = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'tid': tId.toString(),
      'cId': cId.toString()
    })
  };
  

  const url = `${this.apiBrokerUrl}/GetLeadBrokerByContactId/${brokerContactId}`;
  return this.http
    .get<BrokerContact>(url,httpOpts)
    .pipe(retry(3));
}
public getCustomerInfo(cId:number,tId:number): Observable<any> {
  const httpOpts = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'tid': tId.toString(),
      'cId': cId.toString()
    })
  };
  const url = `${this.apiCustomerUrl}/${cId}`;
  return this.http
    .get(url,httpOpts)
    .pipe(retry(3));



}
}

