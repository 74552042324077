import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import {AppConfigService} from  "../app-config/app-config.service";
import {AppConfigService} from  "../app-config/app-config.service";


@Injectable({
  providedIn: 'root'
})
export class VerificationService {
public apiUrl:string="";
public apiUrlemail:string="";
public apiUrlsms:string="";
public apiUrlCustomer:string="";

  constructor(private http: HttpClient, private configService:AppConfigService) {
     this.apiUrl = `${this.configService.serverUrl}/Signer`;
    this.apiUrlemail = `${this.configService.serverUrl}/EmailSending`;
    this.apiUrlsms = `${this.configService.serverUrl}/SmsSending`;
    this.apiUrlCustomer = `${this.configService.serverUrl}/Customer`;

   }

  public GetinfobySNID(snid:number, ownerId:number): Observable<any> {
    
    const url = `${this.apiUrl}/InfoPortalTenant/${snid}/${ownerId}`;
    const httpOpts = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'tid': ownerId.toString()
      })
    };
    return this.http.get(url, httpOpts);
  }

  public SendEmail(brokerContactId:number,brokerId:number, ownerId:number, propertyId:number): Observable<any> {
    
    const url = `${this.apiUrlemail}/SendEmailToBroker/${brokerContactId}/${brokerId}/${propertyId}`;
    const httpOpts = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'tid': ownerId.toString()
      })
    };
    return this.http.get(url, httpOpts);
  }
  public SendSms(toNumber:string, message:string,ownerId:number): Observable<any> {
    
    const url = `${this.apiUrlemail}/SendSms/`;
    const httpOpts = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'tid': ownerId.toString()
      })
    };
    var mobilePhone ={"mobilePhone":toNumber};
    return this.http.post(url, mobilePhone,httpOpts);
  }

  public SendEmailTOEmail(email:string, ownerId:number, customerId:number): Observable<any> {
    
    const url = `${this.apiUrlemail}/SendToEmail/${customerId}`;
    const httpOpts = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'tid': ownerId.toString()
      })
    };
    var PhoneEmail ={"Phone":null,"Email":email};
    return this.http.post(url, PhoneEmail,httpOpts);
  }
  public VerifyEmailandPhone(email:string, ownerId:number, customerId:number,phone:string): Observable<any> {
    
    const url = `${this.apiUrl}/verifyEmailandPhone`;
    const httpOpts = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'tid': ownerId.toString()
      })
    };
    var PhoneEmail ={"Phone":phone,"Email":email};
    return this.http.post(url, PhoneEmail,httpOpts);
  }

  public SendCallVerificationForBrokerPortal(toNumber:string, customerId:number, brokerId:number, ownerId:number): Observable<any> {
     
    const url = `${this.apiUrlsms}/SendCallVerificationForBrokerPortal`;
    const httpOpts = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'tid': ownerId.toString()
      })
    };
    var mobilePhone ={"mobilePhone":toNumber, "customerId":customerId, "brokerId":brokerId, "ownerId":ownerId };
    return this.http.post(url, mobilePhone,httpOpts);
  }

  public GetCustomerData(cId:number, ownerId:number): Observable<any> { 
    const url = `${this.apiUrlCustomer}/GetCustomerByCustomerId/${cId}`;
    const httpOpts = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'tid': ownerId.toString()
      })
    };
    return this.http.get(url, httpOpts);
  }

}
