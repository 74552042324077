<kendo-popup *ngIf="show" [anchor]="anchor">
    <div class="wrapper" role="menu-item" [ngStyle]="{'width': width+'px', 'margin-left': marginLeft + 'px'}">
        <div role="menu-item" class="pointer-up"><img role="menu-item" src="/assets/images/action_menu_pointer.png" /></div>
        <div role="menu-item" [ngStyle]="{'max-height': maxHeight+'px'}" class="scrollableDiv">
            <ul class="menu">
                <li role="menu-item" (click)="menuItemSelected(item)" *ngFor="let item of data">
                    <input role="menu-item" type="checkbox" [checked]="setCheckState(item)" kendoCheckBox>
                    <span role="menu-item">{{ item[displayField] }}</span>
                </li>
            </ul>
        </div>
        <div role="menu-item" class="clearArea">
            <a role="menu-item" (click)="clearAll()" href="javascript:void(0)"> <i role="menu-item" class="fa fa-times"></i> &nbsp;Clear All</a>
        </div>
    </div>
</kendo-popup>