export enum LeaseStatus {
  NotStarted = 35001,
  NotSent = 35002,
  AwaitingTenantSign = 35003,
  AwaitingPayment = 35004,
  AwaitingCountersign = 35005,
  Executed = 35006,
  Rejected = 35007,
  Cancelled = 35008,
  Sending = 35009,
}
