import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-counter-component',
  templateUrl: './counter.component.html'
})
export class CounterComponent {
  public currentCount = 0;
  public customerId:number=0;
  public ownerId:number=0;
  public pid:number=0;
  vStatus: string = "";
/**
 *
 */
constructor(private router: Router,private route: ActivatedRoute) {
  
  sessionStorage.setItem("previouUrl", this.router.url);

}
  public incrementCounter() {
    this.currentCount++;
  
  }
  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      
      this.customerId = Number(params['cid']);
      this.ownerId = Number(params['oid']);
      this.pid = Number(params['pid'] == undefined ? 0 : params['pid']);
      this.vStatus= params["dv"]
    });
    
if(this.vStatus == 'Sx512_21123HD@112AHGDS'){
  sessionStorage.setItem("verifiedstatus", 'verified');
  }
    var verstatus = sessionStorage.getItem("verifiedstatus");
    if (verstatus == "verified") {

    } else {
      this.router.navigateByUrl(`/broker/verification?cid=${this.customerId}&oid=${this.ownerId}`);

    }

  }
}
