<body> 
  <ngx-spinner type="null">
    <img src='assets/images/RTLoader.gif' />
  </ngx-spinner>
  <!-- <app-nav-menu></app-nav-menu> -->
  <div>
    <router-outlet></router-outlet>
    <!-- <app-footer></app-footer> -->
  </div>
</body>
