import { Injectable } from '@angular/core';
import {AppConfigService} from  "../app-config/app-config.service";
import { HttpClient, HttpBackend, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Brokerage } from '../../shared/models/brokerage/brokerage.model';
import { retry } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class BrokerageService {
  readonly apiUrl = `${this.config.serverUrl}/Brokerage`;

  constructor(
    private http: HttpClient, 
    private httpHandler: HttpBackend,
    private config: AppConfigService
  ) { }

  public SearchByKey(searchKey: string, tId:number, cId:number): Observable<any> {
    const url = `${this.apiUrl}/GetBrokragesBySearchKey?searchKey=${encodeURIComponent(searchKey)}`;
    const httpClient = new HttpClient(this.httpHandler);
    const httpOpts = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'tid': tId.toString(),
        'cId': cId.toString()
      })
    };

    return httpClient.get<any>(url, httpOpts);
    //return this.http.get<any>(url);
  }

  public add(brokerage: Brokerage, tId:number, cId:number): Observable<any> {
    const httpClient = new HttpClient(this.httpHandler);
    const httpOpts = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'tid': tId.toString(),
        'cId': cId.toString()
      })
    };
    
    return httpClient.post<any>(this.apiUrl, brokerage, httpOpts);
   // return this.http.post(this.apiUrl, brokerage);
  }

  public getById(brokerageId: number, tId:number, cId:number): Observable<any> {
    const url = `${this.apiUrl}/${brokerageId}`;
    const httpClient = new HttpClient(this.httpHandler);
    const httpOpts = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'tid': tId.toString(),
        'cId': cId.toString()
      })
    };

    return httpClient.get<any>(url, httpOpts);
    // return this.http
    //   .get<Brokerage>(url)
    //   .pipe(retry(3));
  }

}
