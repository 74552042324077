import { Injectable, LOCALE_ID, Inject } from '@angular/core';
import { NotificationService } from '@progress/kendo-angular-notification';

declare var $: any;

@Injectable({ providedIn: 'root' })
export class UtilityMethods {

  private currentLocale: string;

  constructor(
    @Inject(LOCALE_ID) locale: string,
    private notificationService: NotificationService
  ) {
    this.currentLocale = locale;
  }

  public showSuccessNotification(message: string) {
    this.notificationService.show({
      content: message,
      cssClass: 'toast-notification',
      animation: { type: 'slide', duration: 400 },
      position: { horizontal: 'center', vertical: 'top' },
      type: { style: 'success', icon: true },
      hideAfter: 5000
    });
  }

  public showErrorNotification(message: string): void {
    this.notificationService.show({
      content: message,
      cssClass: 'toast-notification',
      animation: { type: 'slide', duration: 400 },
      position: { horizontal: 'center', vertical: 'top' },
      type: { style: 'error', icon: true },
      hideAfter: 5000
    });
  }

  public showPermissionErrorNotification(message: string): void {
    this.notificationService.show({
      content: message,
      cssClass: 'toast-notificationPermissionError',
      animation: { type: 'slide', duration: 400 },
      position: { horizontal: 'center', vertical: 'top' },
      type: { style: 'error', icon: true },
      hideAfter: 5000
    });
  }


  public get UtcNow(): Date {
    return new Date(new Date().toLocaleString(this.currentLocale, { timeZone: "UTC" }));
  }

  public get Locale(): string {
    return this.currentLocale;
  }

  public enableStickyScroll(parentContainer: string, childContainer: string) {

    var elementPosition = $(parentContainer).offset();

    if ($(window).scrollTop() >= elementPosition.top) {
      $(childContainer).addClass('fixedTop');
    } else {
      $(childContainer).removeClass('fixedTop');
    }
  }

  public getLocalStorageItem<T>(key: string): T | null {
    let item: T ;
    let keyData = localStorage.getItem(key);
    if (keyData == null) {
      return null;  // item = JSON.parse(keyData) as T;
    }
    else {
       item = JSON.parse(keyData) as T;
    }
   
    return item;

  }

  public setLocalStorageItem(key: string, item: any): void {
    localStorage.setItem(key, JSON.stringify(item));
  }

  public getUtcStartTimeOfDate(date: Date) {

    if (date) {

      date = new Date(date.getFullYear(), date.getMonth(), date.getDate());
      date.setMinutes(-(this.offsetMins));
    }

    return date;
  }

  public getUtcEndTimeOfDate(date: Date) {

    if (date) {

      date = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59);
      date.setMinutes(date.getMinutes() - this.offsetMins);
    }

    return date;
  }

  public convertToUtc(date: Date) {

    if (date) {
      date.setMinutes(date.getMinutes() - this.offsetMins);
    }

    return date;
  }

  public get offsetMins(): number {
    return new Date().getTimezoneOffset();
  }

  public setReportURLValue(startDate: any, endDate: any) {
    var url = new URL(window.location.href);
    var search_params = url.searchParams;
    search_params.set('fromdate', startDate);
    search_params.set('todate', endDate);

    url.search = search_params.toString();
    var new_url = url.toString();
    window.history.pushState("data", "Title", new_url);
  }
}
