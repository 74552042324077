import { Injectable } from '@angular/core';
// import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import {AppConfigService} from  "../app-config/app-config.service";


@Injectable({
  providedIn: 'root'
})
export class LeaseService {
  public apiUrl:string="";
  public apiUrlLeasePayment:string="";


  constructor(private http: HttpClient, private configService:AppConfigService) {
     this.apiUrl = `${this.configService.serverUrl}/Lease`;
     this.apiUrlLeasePayment = `${this.configService.serverUrl}/LeasePaymentDetail`;
   }

  getLeasePaymentByLeaseId(leaseId: number,tId:number, cId:number,oId:number, entityTypeId:number): Observable<any> {
    const url = `${this.apiUrlLeasePayment}/GetLeasePaymentByLeaseIdTenantUI/${leaseId}/${oId}/${entityTypeId}`;
    const httpOpts = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'tid': oId.toString(),
        'oId': oId.toString(),
        'cId': cId.toString()
      })
    };

    return this.http.get<any>(url, httpOpts);
  }

  getLeaseInfo(leaseId: number,tId:number,cId:number,oId:number, singerId:number, entityTypeId: number): Observable<any> {
    const url = `${this.apiUrl}/GetLeaseInfoTenantUI/${leaseId}/${oId}/${singerId}/${entityTypeId}`;
    const httpOpts = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'tid': oId.toString(),
        'oId': oId.toString(),
        'cId': cId.toString()
      })
    };
    return this.http.get<any>(url, httpOpts);
  }

  getPGaccount(customerId:number, oId:number): Observable<any> {
    const url = `${this.configService.serverUrl}/LeasePaymentDetail/GetPGAccountDetail/${customerId}`;
    const httpOpts = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'tid': oId.toString(),
        'cId': customerId.toString()
      })
    };
    return this.http.get<any>(url,httpOpts);
  }
  getCustomerInfo(customerId:number,ownerId:number): Observable<any> {
    const url = `${this.configService.serverUrl}/Customer/${customerId}`;
    const httpOpts = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'tid': ownerId.toString(),
        'cId': customerId.toString()
      })
    };
    return this.http.get<any>(url,httpOpts);
  }

  updateLeaseStatus(leaseId: number,cId:number,oId:number, entityTypeId: number): Observable<any> {
    const url = `${this.apiUrl}/UpdateModificationStatusById/${leaseId}/${oId}/${entityTypeId}`;
    const httpOpts = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'tid': oId.toString(),
        'oId': oId.toString(),
        'cId': cId.toString()
      })
    };
    return this.http.put<any>(url, httpOpts);
  }


}
