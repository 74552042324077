import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DialogRef } from "@progress/kendo-angular-dialog";
import { Subscription } from "rxjs";
import {
  GroupResult,
  groupBy,
  orderBy,
  SortDescriptor,
} from "@progress/kendo-data-query";
import { UnitService } from "../services/unit/unit.service";
import { PropertyService } from "../services/property/property.service";
import { Router } from "@angular/router";
import { AppConfigService } from "../services/app-config/app-config.service";
import * as CryptoJS from "crypto-js";

@Component({
  selector: "app-broker-application",
  templateUrl: "./broker-application.component.html",
  styleUrls: ["./broker-application.component.scss"],
})
export class BrokerApplicationComponent implements OnInit {
  private subscriptions: Subscription[] = [];
  private customerId: number;
  private ownerId: number;
  private brokerId: number;
  //private currentUser: User;

  public isReloadRequire = false;
  public submitted = false;
  public isAllowScreeningEdit: boolean;
  public form: FormGroup;
  public addEditDialog: DialogRef;
  public properties: any[] = [];
  public groupedUnits: any[] = [];
  public applicants: any[] = [];
  propertyId: any;
  unitId: any;
  public sort: SortDescriptor[] = [
    {
      field: "status",
      dir: "desc",
    },
  ];
  isPropertyRequired: boolean = false;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private unitApi: UnitService,
    private propertyApi: PropertyService,
    private config: AppConfigService
  ) {}

  ngOnInit(): void {
    var brokerInfo = JSON.parse(sessionStorage.getItem("brokerInfo"));
    this.customerId = brokerInfo.cid;
    this.ownerId = brokerInfo.oid;
    this.brokerId = brokerInfo.brid;
    this.initializeForm();
    this.loadProperties();
  }

  public get formFields() {
    return this.form.controls;
  }
  private initializeForm() {
    this.form = this.formBuilder.group({
      propertyId: [0, Validators.required],
      unitId: ["", Validators.required],
    });
  }

  public onPropertyChange(propertyId: number) {
    
    if(propertyId > 0){
      this.isPropertyRequired = true;
    }else{
      this.isPropertyRequired = false;

    }
    if (propertyId && propertyId > 0) {
      this.submitted = false;
      this.formFields.unitId.setValue("");

      const subscription = this.unitApi
        .getByPropertyIdForDropdown(propertyId, this.ownerId)
        .subscribe((response) => {
          this.groupedUnits = groupBy(orderBy(response, this.sort), [
            { field: "status" },
          ]);
        });

      this.subscriptions.push(subscription);
    }
  }

  private loadProperties(): void {
    let subscription: any;

    // if (this.currentUser.accountTypeId == AccountType.Customer) {

    //   subscription = this.propertyApi.getByUserId(this.currentUser.userId).subscribe(properties => {
    //     this.properties = properties;

    //   });
    // }
    // else {
    subscription = this.propertyApi
      .getByCustomrId(this.customerId, this.ownerId, false, 0)
      .subscribe((response) => {
        
        for (let jv = 0; jv < response.properties.length; jv++) {
          let obj = response.properties.find((x) => x.isActive == 3);
          let index = response.properties.indexOf(obj);
          if (index > -1) {
            response.properties.splice(index, 1);
          }
        }

        var res = response.properties.map(function (x, i) {
          if (x.isActive == 2) return i;
        });
        res = res.filter(function (x) {
          return x != undefined;
        });
        for (var i = res.length - 1; i >= 0; i--) {
          response.properties.splice(res[i], 1);
        }
        this.properties = response.properties.map(
          (property: { propertyId: any; marketingName: any }) => {
            return {
              propertyId: property.propertyId,
              propertyName: property.marketingName,
            };
          }
        );
      this.properties.unshift({ propertyId: 0, propertyName: "Select One" });     

      });
    // }

    this.subscriptions.push(subscription);
  }

  public closeAddEdit() {
    this.addEditDialog.close();
  }

  encryptUsingAES256(iv, key) {
    var encrypted = CryptoJS.AES.encrypt(
      CryptoJS.enc.Utf8.parse(JSON.stringify(this.brokerId)),
      key,
      {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    );
    this.decryptUsingAES256(encrypted.toString(), key, iv);
    return encrypted.toString();
  }

  decryptUsingAES256(decString, key, iv) {
    var decrypted = CryptoJS.AES.decrypt(decString, key, {
      keySize: 128 / 8,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
  }

  public onSubmit() {
    this.submitted = true;
    if(this.form.controls.propertyId.value > 0){
      this.isPropertyRequired = true;
    }else{
      this.isPropertyRequired = false;

    }
    if (this.form.status == "VALID" && this.isPropertyRequired) {
      this.closeAddEdit();
      let key = CryptoJS.enc.Utf8.parse("4512631236589784");
      let iv = CryptoJS.enc.Utf8.parse("4512631236589784");

      let conversionEncryptOutput = this.encryptUsingAES256(iv, key);
      window.open(
        `${this.config.formBaseUrl}?cid=${this.customerId}&tid=${this.ownerId}&pid=${this.form.controls.propertyId.value}&uid=${this.form.controls.unitId.value}&preview=1&formStatusId=2&fmode=true&FormTypeId=2&brId=${this.brokerId}`
      );
    }
  }
}
