<div class="unit-selector">
    <div class="unit-selector-dialog">
        <div class="btn-cancel">
            <span class="fas fa-times"></span>
        </div>
        <div class="unit-selector-header">
            <h4 class="unit-selector-title">New Application</h4>
        </div>

        <div class="unit-selector-form formHolder">
            <div class="row">
                <div class="col-md-6">
                    <div class="controlHolder">
                        <label>Select Property</label>
                        <kendo-floatinglabel>
                            <kendo-dropdownlist>
                            </kendo-dropdownlist>
                        </kendo-floatinglabel>
                    </div>
                </div>
                <!--  -->
                <div class="col-md-6">
                    <div class="controlHolder">
                        <label>Select Unit</label>
                        <kendo-floatinglabel>
                            <kendo-dropdownlist>
                            </kendo-dropdownlist>
                        </kendo-floatinglabel>
                    </div>
                </div>
            </div>
        </div>
        <!--  -->
        <div class="btnArea border-top-1">
            <div class="leftArea">
                <button kendoButton class="btn-secondary k-button">Cancel</button>
            </div>
            <div class="RightArea">
                <button kendoButton class="btn-primary k-button">Select</button>
            </div>
        </div>
    </div>
</div>