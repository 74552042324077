<main class="container" *ngIf="isshowDisplay">
  <header>
    <img src="../../../assets/images/RTLogo.png" alt="logo" class="img-fluid" srcset="../../../assets/images/RTLogo.png">
  </header>
  <section class="page-content">
    <div class="row align-items-center">
      <div class="col-12 col-md-6">
        <div class="page-content-header">
          <h1 class="heading-primary">
            upgrade<br>in progress
          </h1>
          <p class="heading-secondary">
            RentTango is adding new features<br>
            and making improvements.<br>
            We'll be back online shortly.<br>
            <br>
            Thank you for your patience.
            <br>
            <a href="https://renttango.statuspage.io/" target="_blank" class="view-status">VIEW STATUS</a>
          </p>
        </div>
      </div>
      <div class="col-12 col-md-6 px-lg-0">
        <img src="../../../assets/images/RentTango_SystemDown.png" alt="logo" class="img-fluid"
          srcset="../../../assets/images/RentTango_SystemDown.png">
      </div>
    </div>
  </section>
</main>