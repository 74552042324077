<div class="tenant-verificationWrapper">
  <section class="tenant-verification">
    <div
      class="wrap"
      id="uniqewrap"
      [ngStyle]="{ 'border-color': companyColor }"
    >
      <figure class="company-logo">
        <img [src]="companyLogo" alt="logo" class="img-fluid" />
      </figure>
      <div class="text-center">
        <h2 class="heading-primary">Login to our Broker Portal</h2>
        <p class="heading-tertiary">
          Don't have an account?
          <a (click)="redirect()" class="signUptext">Register here</a>
        </p>
      </div>
      <div class="tenant-verificationForm">
        <form [formGroup]="addEditForm" (ngSubmit)="enablebtn()">
          <div class="form-row mt-4">
            <div class="col-12 col-sm-6 pr-3">
              <div class="form-group">
                <label for="Email">Email</label>
                <kendo-textbox
                  formControlName="email"
                  id="Email"
                  class="input-field"
                ></kendo-textbox>
                <div
                  style="color: red"
                  *ngIf="submitted && formFields.email.errors"
                  class="k-tooltip-validation"
                >
                  <div *ngIf="formFields.email.errors.required">
                    Email is required
                  </div>
                  <div *ngIf="formFields.email.errors.pattern">
                    Pease Enter valid Email
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="col-12 col-sm-6">
              <div class="form-group">
                <div class="input-phone">
                  <label for="Phone">Phone</label>
                  <!-- <kendo-maskedtextbox formControlName="phone" class="input-field" [mask]="mask" id="phone">
                                    </kendo-maskedtextbox> -->
                  <input
                    class="input-phone-field"
                    type="text"
                    minlength="5"
                    id="cellphone_1"
                    maxlength="15"
                    formControlName="phone"
                    name="phone"
                    (input)="inputValidator($event)"
                  />
                </div>
                <div
                  style="color: red"
                  *ngIf="submitted && formFields.phone.errors"
                  class="k-tooltip-validation"
                >
                  <div *ngIf="formFields.phone.errors.required">
                    Phone number is required
                  </div>
                </div>
              </div>
            </div>
            <!--  -->
            <div id="verificationdiv" style="display: none" class="col-12">
              <!-- email start -->
              <!-- <div class="code-layoutClassic">
                                <div class="field-phone">
                                    <img src="assets/images/phone-button.png" alt="phone" srcset="assets/images/phone-button.png" class="img-fluid">
                                </div>
                                <div class="field-code-digitNum">
                                    <p class="codeLable">Email Code</p>
                                    <div>
                                        <input class="ml-0" type="text" id="emailCode1" maxLength="1" size="1" min="0" max="9" pattern="[0-9]{1}" />
                                    </div>
                                    <p id="emailcodeerror" style="display: none;" class="error-text">The code does not match</p>
                                </div>
                                <div class="verify">
                                    <button type="button" id="emailverifybtn" (click)="verifyEmail()" class="btn btn-verify">Verify</button>
                                </div>
                                <img src="assets/images/cancel.png" alt="img" srcset="assets/images/cancel.png" class="img-fluid img-cancel pl-lg-2">
    
                                <div class="text-resend pl-lg-3">
                                    <a href="javascript:void(0)" (click)="Onclick()">Resend Code to Email</a>
                                </div>
                            </div> -->
              <!-- email end -->

              <!-- ------------------------ -->
              <div class="bg-code mb-4">
                <div class="code-container">
                  <div class="code-icon img-email">
                    <img src="assets/images/icon_email@2x.png" />
                  </div>
                  <div class="code-fields code-email">
                    <label class="label w-100 d-block">Email Code</label>
                    <div class="d-flex align-items-center sm-flex-wrap">
                      <input
                        type="text"
                        id="emailCode1"
                        maxLength="1"
                        size="1"
                        min="0"
                        max="9"
                        pattern="[0-9]{1}"
                      />

                      <button
                        type="button"
                        style="display: none"
                        class="btn btn-theme-primary btn-theme-primary-display"
                        id="emailverifybtn"
                        (click)="verifyEmail()"
                      >
                        Verify
                      </button>
                      <button
                        type="button"
                        style="display: none"
                        class="btn btn-theme-primary btn-theme-primary-display"
                        id="againemail"
                        (click)="sendemailagain()"
                      >
                        Resend
                      </button>

                      <button
                        type="button"
                        class="btn btn-theme-primary btn-theme-primary-display"
                        id="sendemailbtn"
                        (click)="Onclick()"
                      >
                        Send Code to Email
                      </button>

                      <span class="img-status ml-3 mb-1">
                        <img
                          src="assets/images/icon_check-small.png"
                          class="img-check"
                          id="emailCheck"
                          style="display: none"
                        />
                        <img
                          src="assets/images/icon_deny-small.png"
                          class="img-deny"
                          id="emailDeny"
                          style="display: none"
                        />
                      </span>
                      <span
                        id="spanid"
                        class="text-match font-weight-bold small"
                      ></span>
                      <!-- <button class="text-match font-weight-bold small timerEmail"style="display:none;cursor:pointer"id="againemail" (click)="sendemailagain()">Resend</button> -->
                    </div>
                    <span
                      class="text-match font-weight-bold small errorEmail"
                      style="display: none"
                      id="emailcodeerror"
                      >The code does not match</span
                    >
                  </div>
                </div>
              </div>
              <!--  -->
              <div class="bg-code" id="phoneCode-div">
                <div class="code-container code-phone">
                  <div class="code-icon img-phone">
                    <img src="assets/images/icon_phone-button@2x.png" />
                  </div>
                  <div class="code-fields">
                    <label class="label w-100 d-block">Phone Code</label>
                    <div class="d-flex align-items-center sm-flex-wrap">
                      <input
                        id="smsCode1"
                        type="text"
                        maxLength="1"
                        size="1"
                        min="0"
                        max="9"
                        pattern="[0-9]{1}"
                      />
                      <button
                        type="button"
                        class="btn btn-theme-primary btn-theme-primary-display"
                        disabled
                        id="smsverifybtn"
                        (click)="verifySMS()"
                      >
                        Verify
                      </button>
                      <button
                        type="button"
                        class="btn btn-theme-primary btn-theme-primary-display"
                        style="display: none !important"
                        id="againphone"
                        (click)="sendsmsagain()"
                      >
                        Resend
                      </button>
                      <span class="img-status ml-3 mb-1">
                        <img
                          src="assets/images/icon_check-small.png"
                          class="img-check"
                          id="phoneCheck"
                          style="display: none"
                        />
                        <img
                          src="assets/images/icon_deny-small.png"
                          class="img-deny"
                          id="phoneDeny"
                          style="display: none"
                        />
                      </span>
                      <span
                        id="spanidphone"
                        class="text-match font-weight-bold small"
                      ></span>
                      <!-- <span class="text-match font-weight-bold small timerPhone" id="againphone" (click)="sendsmsagain()" style="display:none;cursor: pointer;">Resend code to phone</span> -->
                    </div>
                    <span
                      class="text-match font-weight-bold small errorPhone"
                      id="phoneerror"
                      style="display: none"
                      >The code does not match</span
                    >
                  </div>
                </div>
              </div>

              <div class="bg-code mb-5" id="callCode-div" style="display: none">
                <div class="code-container code-phone">
                    <div class="code-icon img-phone">
                      <img src="assets/images/icon_phone-button@2x.png" />
                    </div>
                    <div class="code-fields">
                        <label class="label w-100 d-block pb-md-1">Phone Code</label>
                        <div class="d-flex align-items-start" style="color: black;">
                            <div class="width-lg text-break">
                                <p>There seems to be an issue with your validation code. Click the Call Me button instead.</p>
                                <p>If you would like assistance, please reach out to the leasing team at {{leasingOfficePhone}} or {{leasingOfficeEmail}} and they will be able to assist you.</p>
                            </div>
                            <div class="ml-2 ml-lg-3">
                                <button type="button" class="btn btn-theme-primary btn-theme-primary-display ml-0" id="sendCallCode"  (click)="sendcallToNumber()">Call Me</button>
                                <button type="button" class="btn btn-theme-primary btn-theme-primary-display ml-0" id="callingCode" style="display:none">Calling</button>
                                <button type="button" class="btn btn-theme-primary btn-theme-primary-display ml-0" id="callingCodeVerify" style="display:none">Verify</button>
                                <span class="img-status">
                                    <img src="assets/images/icon_check-small.png" class="img-check ml-0 ml-md-2" id="callCheck" style="display:none" />
                                </span>
                                <div class="d-inline-block">
                                    <span class="text-match font-weight-bold small timerCall ml-2"></span>
                                    <span class="text-match font-weight-bold small errorCall ml-2"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

              <!--  -->
              <!-- ------------------------ -->
            </div>

            <div class="row" id="retryCallExpire" style="display:none">
              <div class="col-12">
                  <div class="form-group px-1">
                      <p class="peragraph text-danger">
                          If you would like assistance, please reach out to the leasing team at {{leasingOfficePhone}} or {{leasingOfficeEmail}} and they will be able to assist you.
                      </p>
                  </div>
              </div>
            </div>

            <div class="action pb-3">
              <button
                type="submit"
                id="loginbtn"
                [ngStyle]="{
                  'background-color': companyColor,
                  color: fontColor
                }"
                class="btn btn-login"
              >
                Log in
              </button>
            </div>
            <div
              style="display: none; color: red"
              class="text-center w-100 pt-3"
              id="permission"
            >
              <p>
                The login information entered does not match an active, approved
                user.
              </p>
            </div>
            <div
              style="display: none; color: red"
              class="text-center w-100 pt-3"
              id="blocker"
            >
              <p>
                Please contact {{ companyName }} at {{ companyPhone }} to update
                your mobile phone number and email address.
              </p>
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
</div>
