<header>
    <nav class="navbar navbar-expand-sm navbar-toggleable-sm navbar-light bg-white box-shadow  " [ngStyle]="{'border-color': companyColor}">
        <div class="container-fluid p-0">
            <a class="navbar-brand p-0" id="lnkLogoPath">
                <img [src]="logoPath" alt="img" class="logo-img">
            </a>
            <div class="title">
                {{title}}
            </div>
            <div class="userProfileWrapper">
                <div class="inner">
                    <!-- <kendo-avatar [shape]="'square'" [initials]="n" [size]="'large'" ></kendo-avatar> -->
                    <div class="profile" [ngStyle]="{'background-color': companyColor,'color': fontColor}">
                        {{initials}}
                    </div>
                    <!-- <div class="detail">
                        <span class="text">Welcome</span>
                        <span class="usrName">{{userName}}</span>
                    </div> -->
                    <!-- nw -->
                    <!-- <div class="detail">
                              <span class="usrName">{{userName}}</span>
                          </div> -->
                    <div class="rightArea">
                        <ul class="leftActionArea">
                            <li class="profiles">
                                <div class="infoArea">
                                    <span class="comapny">Welcome</span>
                                    <span class="name">{{userName}}</span>
                                </div>
                                <div class="dropdown">
                                    <div class="pointer-up" role="menu-item"><img role="menu-item" src="/assets/images/action_menu_pointer.png"></div>
                                    <ul class="topSpace">
                                        <li>
                                            <a class="cursor-pointer" id="lnk_Logout" (click)="logout()">
                                                <span class="icon-icon-4 mr-2"></span>Logout</a>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <!-- end nw -->
                </div>
            </div>
            <!-- <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target=".navbar-collapse"
      aria-label="Toggle navigation"
      [attr.aria-expanded]="isExpanded"
      (click)="toggle()"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div
      class="navbar-collapse collapse d-sm-inline-flex flex-sm-row-reverse"
      [ngClass]="{ show: isExpanded }"
    >
      <ul class="navbar-nav flex-grow">
        <li
          class="nav-item"
          [routerLinkActive]="['link-active']"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <a class="nav-link text-dark" [routerLink]="['/']">Home</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['link-active']">
          <a class="nav-link text-dark" [routerLink]="['/counter']"
            >Counter</a
          >
        </li>
        <li class="nav-item" [routerLinkActive]="['link-active']">
          <a class="nav-link text-dark" [routerLink]="['/fetch-data']"
            >Fetch data</a
          >
        </li>
      </ul>
    </div> -->
            <div class="mobileView">
                payment
            </div>
        </div>

    </nav>
</header>