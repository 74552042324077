import { Injectable } from '@angular/core';
import {AppConfigService} from  "../app-config/app-config.service";
import { HttpClient, HttpBackend, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Broker } from '../../shared/models/broker/broker.model';
import { retry } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class BrokerService {
  readonly apiUrl = `${this.config.serverUrl}/Broker`;
  readonly apiUrlCustomer = `${this.config.serverUrl}/Customer`;

  constructor(
    private http: HttpClient,
    private httpHandler: HttpBackend,
    private config: AppConfigService

  ) { }

  public add(broker: Broker, tId:number, cId:number): Observable<any> {
    const httpClient = new HttpClient(this.httpHandler);
    const httpOpts = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'tid': tId.toString(),
        'cId': cId.toString()
      })
    };
    
    return httpClient.post<any>(this.apiUrl, broker, httpOpts);
 //   return this.http.post(this.apiUrl, broker);
  }
  public getById(brokerId: number, brokerContactId: number, tId:number,cId:number) {
    const url = `${this.apiUrl}/${brokerId}/${brokerContactId}`;
    const httpClient = new HttpClient(this.httpHandler);
    const httpOpts = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'tid': tId.toString(),
        'cId': cId.toString()
      })
    };
    return httpClient.get<any>(url, httpOpts);
  }
  public SearchByKey(searchKey: string): Observable<any> {
    const url = `${this.apiUrl}/GetBrokrBySearchKey?searchKey=${encodeURIComponent(searchKey)}`;
    return this.http.get<any>(url);
  }

  public getBrokerByContactId(brokerContactId: number): Observable<any> {
    const url = `${this.apiUrl}/GetLeadBrokerByContactId/${brokerContactId}`;
    return this.http
      .get<Broker>(url)
      .pipe(retry(3));
  }

  public CheckBrokerExistByEmail(email: string, tId:number, cId:number) {
    const url = `${this.apiUrl}/CheckBrokerExistByEmail?email=${email}`;
    const httpClient = new HttpClient(this.httpHandler);
    const httpOpts = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'tid': tId.toString(),
        'cId': cId.toString()
      })
    };

    return httpClient.get<any>(url, httpOpts);
  }

  public CheckBrokerExistByPhone(phone: string, tId:number, cId:number) {
    const url = `${this.apiUrl}/CheckBrokerExistByPhone?phone=${phone}`;
    const httpClient = new HttpClient(this.httpHandler);
    const httpOpts = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'tid': tId.toString(),
        'cId': cId.toString()
      })
    };
    return httpClient.get<any>(url, httpOpts);
  }

  public CheckBrokerExistByBrokerageName(brokerage: string, tId:number, cId:number) {
    const url = `${this.apiUrl}/CheckBrokerExistByBrokerageName?brokerageName=${brokerage}`;
    const httpClient = new HttpClient(this.httpHandler);
    const httpOpts = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'tid': tId.toString(),
        'cId': cId.toString()
      })
    };
    return httpClient.get<any>(url, httpOpts);
  }

  public GetCustomerById(tId:number, cId:number) {
    
    const url = `${this.apiUrlCustomer}/${cId}`;
    const httpClient = new HttpClient(this.httpHandler);
    const httpOpts = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'tid': tId.toString(),
        'cId': cId.toString()
      })
    };
    return httpClient.get<any>(url, httpOpts);
  }

  public getBrokerDataById(brokerId: string, tId:number, cId:number) {
    const url = `${this.apiUrl}/GetBrokerDataById?brokerId=${brokerId}`;
    const httpClient = new HttpClient(this.httpHandler);
    const httpOpts = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'tid': tId.toString(),
        'cId': cId.toString()
      })
    };
    return httpClient.get<any>(url, httpOpts);
  }

  public updateCallConnection(brokerId: number, tId:number, cId:number, isCallConnection:boolean, isCallVerifiedStatus: boolean) {
    const url = `${this.apiUrl}/UpdateCallConnection?brokerId=${brokerId}&isCallConnection=${isCallConnection}&isCallVerifiedStatus=${isCallVerifiedStatus}`;
    const httpClient = new HttpClient(this.httpHandler);
    const httpOpts = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'tid': tId.toString(),
        'cId': cId.toString()
      })
    };
    return httpClient.get<any>(url, httpOpts);
  }

}
