<div class="brokerSignUpWrapper">
  <div class="brokerSignUp">
    <div
      class="dialogHolder brokerAddEdit"
      [ngStyle]="{ 'border-top': '5px solid' + companyColor }"
    >
      <div class="btn-cancel">
        <span class=""></span>
      </div>
      <!--  -->
      <div [hidden]="!isSaved" class="thankYouPageWrapper">
        <figure class="company-logo">
          <img [src]="companyLogo" alt="logo" class="img-fluid" />
        </figure>
        <div class="thanks-content pb-4">
          <div>
            <img
              src="{{ baseUrl }}assets/images/report_identity-verification.png"
              class="img-fluid"
            />
          </div>
          <h1>Thank You</h1>
          <p class="thanks-message">
            Your registration has been successfully completed. Thank you for
            your interest in {{ customer?.companyName }}. We will review the
            information you have submitted and get back to you shortly.
          </p>
        </div>
        <div class="thanks-footer">
          <p>Powered by</p>
          <img
            src="../../assets/images/Logo.svg"
            alt="img"
            class="img-fluid brandingLogo pb-3"
          />
        </div>
      </div>

      <div [hidden]="isSaved">
        <div class="header">
          <figure class="mb-3">
            <img [src]="companyLogo" alt="logo" class="img-logo" />
          </figure>
          <h4>Thank you for your interest in becoming a broker</h4>
          <p>
            Already have an account?
            <a (click)="redirectToLogin()">Login here</a>
          </p>
        </div>

        <p class="formDes">Please fill out the form below with your details</p>
        <form [formGroup]="addEditForm">
          <div class="formHolder py-0 px-4">
            <div class="row">
              <div class="col-md-6">
                <div class="controlHolder has-error">
                  <label>Email</label>
                  <kendo-floatinglabel>
                    <input
                      kendoTextBox
                      formControlName="email1"
                      (keyup)="onEmailChange($event)"
                      (onfocusout)="onEmailChange($event)"
                      minlength="5"
                      id="txtEmail"
                      pattern="^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$"
                      maxlength="50"
                    />
                  </kendo-floatinglabel>
                  <div
                    *ngIf="submitted && formFields.email1.errors"
                    class="k-tooltip-validation"
                  >
                    <div *ngIf="formFields.email1?.errors?.required">
                      Email is required
                    </div>
                    <div
                      *ngIf="
                        !formFields.email1.errors.required &&
                        formFields.email1.errors?.pattern
                      "
                    >
                      Email is invalid&nbsp;
                    </div>
                    <div
                      *ngIf="formFields.email1.errors.minlength"
                      class="k-tooltip-validation"
                    >
                      Enter 5 characters or more.
                    </div>
                  </div>
                  <div
                    *ngIf="emailExist && formFields.email1.errors == null"
                    class="k-tooltip-validation"
                  >
                    <div *ngIf="emailExist">Email Address already exists.</div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="controlHolders has-error">
                  <div class="input-phone">
                    <label>Mobile Phone</label>
                    <kendo-floatinglabel>
                      <!-- <kendo-maskedtextbox formControlName="mobilePhone" [maskOnFocus]="true" id="txtPhone" [mask]="phoneMask" [maskValidation]="true" (keyup)="onPhoneChange($event)" (onfocusout)="onPhoneChange($event)">
                                        </kendo-maskedtextbox> -->
                      <input
                        class="input-phone-field"
                        type="text"
                        minlength="5"
                        (ngModelChange)="onPhoneChange($event)"
                        id="cellphone_1"
                        maxlength="15"
                        formControlName="mobilePhone"
                        name="mobilePhone"
                        (input)="inputValidator($event)"
                      />
                    </kendo-floatinglabel>
                  </div>
                  <div
                    *ngIf="submitted && formFields.mobilePhone.errors"
                    class="k-tooltip-validation"
                  >
                    <div *ngIf="formFields.mobilePhone?.errors?.required">
                      Phone is required
                    </div>
                    <div *ngIf="!formFields.mobilePhone?.errors?.required">
                      Invalid Phone
                    </div>
                  </div>
                  <div
                    *ngIf="phoneExist && formFields.mobilePhone.errors == null"
                    class="k-tooltip-validation"
                  >
                    <div *ngIf="phoneExist">Mobile Phone already exists.</div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="controlHolder">
                  <label>First Name</label>
                  <kendo-floatinglabel>
                    <input
                      kendoTextBox
                      formControlName="firstName"
                      minlength="2"
                      id="txtFirstName"
                      maxlength="30"
                    />
                  </kendo-floatinglabel>
                  <div
                    *ngIf="submitted && formFields.firstName.errors"
                    class="k-tooltip-validation"
                  >
                    <div *ngIf="formFields.firstName.errors.required">
                      First Name is required
                    </div>
                    <div *ngIf="formFields.firstName.errors.minlength">
                      Enter 2 characters or more.
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="controlHolder">
                  <label>Last Name</label>
                  <kendo-floatinglabel>
                    <input
                      kendoTextBox
                      formControlName="lastName"
                      id="txtLastName"
                      minlength="2"
                      maxlength="30"
                    />
                  </kendo-floatinglabel>
                  <div
                    *ngIf="submitted && formFields.lastName.errors"
                    class="k-tooltip-validation"
                  >
                    <div *ngIf="formFields.lastName.errors.required">
                      Last Name is required
                    </div>
                    <div *ngIf="formFields.lastName.errors.minlength">
                      Enter 2 characters or more.
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="controlHolder" style="padding-bottom: 18px">
                  <label>Brokerage</label>
                  <kendo-floatinglabel>
                    <kendo-combobox
                      [data]="brokerages"
                      formControlName="brokerageId"
                      [filterable]="true"
                      [textField]="'name'"
                      [valueField]="'brokerageId'"
                      [placeholder]="'Search'"
                      style="min-width: 250px"
                      [kendoDropDownFilter]="filterSettings"
                      [valuePrimitive]="true"
                      [clearButton]="false"
                      [allowCustom]="true"
                      id="ddlBrokerages"
                      (filterChange)="
                        onBrokerageSearch($event, brokerageSearchControl)
                      "
                      (valueChange)="onBrokerageChange($event)"
                      #brokerageSearchControl
                    >
                      <ng-template kendoComboBoxItemTemplate let-dataItem>
                        <div class="search-box-item-template">
                          <div class="left-container">
                            <div class="name">{{ dataItem.name }}</div>
                          </div>
                        </div>
                      </ng-template>
                    </kendo-combobox>
                  </kendo-floatinglabel>
                  <div
                    *ngIf="submitted && formFields.brokerageId.errors"
                    class="k-tooltip-validation"
                  >
                    <div *ngIf="formFields.brokerageId.errors.required">
                      Brokerage is required
                    </div>
                    <div *ngIf="formFields.brokerageId.errors.minlength">
                      Enter 2 characters or more.
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mx-0" *ngIf="!isLogin">
                <div class="col-md-12 label-work-address">
                  <h4 class="headingText">Work Address</h4>
                </div>

                <div class="col-md-6">
                  <div class="controlHolder">
                    <label> Location Name</label>
                    <kendo-floatinglabel>
                      <input
                        kendoTextBox
                        maxlength="30"
                        id="txtLocationName"
                        formControlName="location"
                      />
                    </kendo-floatinglabel>
                    <div
                      *ngIf="submitted && formFields.location.errors"
                      class="k-tooltip-validation"
                    >
                      <div *ngIf="formFields.countryId.errors.required">
                        Location is required
                      </div>
                    </div>
                    <div class="k-tooltip-validation" style="display: none">
                      <div>Phone is required</div>
                      <div>Proper format required</div>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="controlHolder">
                    <label>Country</label>
                    <kendo-floatinglabel>
                      <kendo-dropdownlist
                        formControlName="countryId"
                        [data]="countries"
                        (valueChange)="onCountryChange($event)"
                        [textField]="'name'"
                        id="ddlCountry"
                        [valueField]="'lookupId'"
                        [valuePrimitive]="true"
                      >
                      </kendo-dropdownlist>
                    </kendo-floatinglabel>
                    <div
                      *ngIf="submitted && formFields.countryId.errors"
                      class="k-tooltip-validation"
                    >
                      <div *ngIf="formFields.countryId.errors.required">
                        Country is required
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="controlHolder">
                    <label>Address 1</label>
                    <div id="autoComplete">
                      <kendo-floatinglabel>
                        <kendo-autocomplete
                          formControlName="address1"
                          maxlength="50"
                          minlength="3"
                          [data]="addressData"
                          [kendoDropDownFilter]="filterSettings"
                          id="ddlAddress1"
                          (filterChange)="changeFilterOperator($event)"
                          [filterable]="true"
                          #autocomplete
                          [valueField]="'secondary'"
                          class="form-control"
                          (valueChange)="onChangeAddress($event)"
                        >
                          <ng-template
                            kendoAutoCompleteItemTemplate
                            let-dataItem
                          >
                            <span>{{ dataItem.secondary }}</span>
                          </ng-template>
                        </kendo-autocomplete>
                      </kendo-floatinglabel>
                    </div>

                    <div
                      *ngIf="submitted && formFields.address1.errors"
                      class="k-tooltip-validation"
                    >
                      <div *ngIf="formFields.address1.errors.required">
                        Address 1 is required
                      </div>
                      <div *ngIf="formFields.address1.errors.minlength">
                        Enter 3 characters or more.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="controlHolder">
                    <label>Address 2</label>
                    <kendo-floatinglabel>
                      <input
                        kendoTextBox
                        maxlength="50"
                        id="txtAddress2"
                        formControlName="address2"
                      />
                    </kendo-floatinglabel>
                    <div class="k-tooltip-validation" style="display: none">
                      <div>50 Characters Only</div>
                      <div>Enter 3 characters or more</div>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="controlHolder">
                    <label>City</label>
                    <kendo-floatinglabel>
                      <input
                        kendoTextBox
                        formControlName="city"
                        id="txtCity"
                        class="form-control"
                        maxlength="64"
                        minlength="3"
                        autocomplete="city"
                      />
                    </kendo-floatinglabel>
                    <div
                      *ngIf="submitted && formFields.city.errors"
                      class="k-tooltip-validation"
                    >
                      <div *ngIf="formFields.city.errors.required">
                        City is required
                      </div>
                      <div *ngIf="formFields.city.errors.minlength">
                        Enter 3 characters or more.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="controlHolder">
                    <label>State/Province</label>
                    <kendo-floatinglabel>
                      <kendo-dropdownlist
                        formControlName="state"
                        [data]="filteredStates"
                        [textField]="'name'"
                        [valueField]="'keyValue1'"
                        id="ddlState"
                        (valueChange)="onStateChange($event)"
                        [valuePrimitive]="true"
                      >
                      </kendo-dropdownlist>
                    </kendo-floatinglabel>
                    <div
                      *ngIf="submitted && formFields.state.errors"
                      class="k-tooltip-validation"
                    >
                      <div *ngIf="formFields.state.errors.required">
                        State/Province is required
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="controlHolder">
                    <label>Zip Code</label>
                    <kendo-floatinglabel>
                      <input
                        kendoTextBox
                        formControlName="zipCode"
                        class="form-control"
                        autocomplete="zipcode"
                        (keydown)="allowNum($event)"
                        id="txtZipCode"
                        (keypress)="usZipCodeCheck($event)"
                        (keyup)="usZipMinLengthCheck($event)"
                      />
                    </kendo-floatinglabel>
                    <div
                      *ngIf="submitted && formFields.zipCode.errors"
                      class="k-tooltip-validation"
                    >
                      <div *ngIf="formFields.zipCode.errors.required">
                        Zip Code is required
                      </div>
                      <div *ngIf="formFields.zipCode.errors.minlength">
                        Enter 2 characters or more.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="bText borderB" *ngIf="!isLogin">
            <p class="mb-4">
              By registering, you agree to Terms of Use and Privacy Policy and
              consent to be contacted at this phone number by text message,
              and/or autodialer by acting purpose, including marketing, by the
              property and anyone for any on their behalf. Consent not required
              to purchase or rent. <br />
            </p>
            <!-- <p class="m-0"> By registering, you agree to the Terms of Use and Privacy Policy.</p> -->
          </div>
          <div class="w-100">
            <div class="btnArea text-center">
              <button
                type="button"
                id="loginbtn"
                [ngStyle]="{
                  'background-color': companyColor,
                  color: fontColor
                }"
                class="btn btn-login mt-4"
                (click)="onSubmit()"
                *ngIf="!isLogin"
              >
                Apply Now
              </button>
              <button
                type="button"
                id="loginbtn"
                [ngStyle]="{
                  'background-color': companyColor,
                  color: fontColor
                }"
                class="btn btn-login mt-4"
                (click)="redirectToLogin()"
                *ngIf="isLogin"
              >
                Login
              </button>
              <p class="brandingText">Powered by</p>
              <img
                src="../../assets/images/Logo.svg"
                alt="img"
                class="img-fluid brandingLogo pb-3"
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
