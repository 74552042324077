import {
  Component,
  EventEmitter,
  OnInit,
  ViewChild,
  NgZone,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  DialogService,
  DialogCloseResult,
  DialogRef,
} from "@progress/kendo-angular-dialog";
import { GridDataResult } from "@progress/kendo-angular-grid";
import { LabelSettings } from "@progress/kendo-angular-progressbar";
import { orderBy, SortDescriptor } from "@progress/kendo-data-query";
import { NgxSpinnerService } from "ngx-spinner";
import { Subscription } from "rxjs";
import { UtilityMethods } from "src/helper/utility-methods";
import { BrokerApplicationComponent } from "../broker-application/broker-application.component";
import { MultiSelectMenuComponent } from "../common/multi-select-menu/multi-select-menu.component";
import { ConfirmDialogComponent } from "../confirm-dialog/confirm-dialog.component";
import { ActivityService } from "../services/activity/activity.service";
import { AppConfigService } from "../services/app-config/app-config.service";
import { ApplicantService } from "../services/applicant/applicant.service";
import { LookupService } from "../services/lookup/lookup.service";
import { AppConstants } from "../shared/app-constants/AppConstants";
import { DecisionStatus } from "../shared/enum/decision-status";
import { LeaseStatus } from "../shared/enum/lease-status";
import { LookupCategory } from "../shared/enum/lookup-category";
import { RecommendationStatus } from "../shared/enum/recommendation-status";
import { ScreeningProgress } from "../shared/enum/screening-progress";
import { ScreeningFilter } from "../shared/models/filters/screening-filter.model";
import { Lookup } from "../shared/models/lookup/lookup.model";
import * as CryptoJS from "crypto-js";
@Component({
  selector: "app-broker-lister",
  templateUrl: "./broker-lister.component.html",
  styleUrls: ["./broker-lister.component.scss"],
})
export class BrokerListerComponent implements OnInit {
  public value = 75;
  public chunks = 5;
  public dataItem: any;
  public selectedDataItem: any;
  public mouseEvent: MouseEvent;
  public propertyId: number;
  public unitId: number;
  public opportunityId: number;
  public customerId: number;
  public ownerId: number;
  public brokerId: number;
  public countShownInTab = 0;
  private skip = 0;
  public pageSize = 30;
  public gridView: GridDataResult;
  private subscriptions = [] as Subscription[];
  private gridData = [] as any[];
  private gridFilteredData = [] as any[];
  public progressItems = [] as any[];
  public initialSelectedLeaseStatuses = [] as any[];
  public leaseStatuses = [] as any[];
  public statusItems = [] as any[];
  public selectedProgressItems = [] as number[];
  public selectedProgressItemList = [] as number[];
  public selectedStatusItems = [] as number[];
  public selectedStatusItemsList = [] as number[];
  public selectedLeaseStatuses = [] as any[];
  public initialSelectedProgressItems = [] as any[];
  public initialSelectedStatusItems = [] as any[];
  public heightMinus = 320;
  public innerHeight = window.innerHeight - this.heightMinus;
  public actionItems = [];
  public screeningShow = false;
  public leasingShow = false;
  public sort: SortDescriptor[] = [
    {
      field: "modifiedOn",
      dir: "desc",
    },
  ];
  @ViewChild("txtSearch", { static: true }) txtSearch: any;
  public searchTxt: string = "";
  applicantTypeLookups: Lookup[];

  public isApplicantLookupAvailable : boolean;
  public isCoApplicantLookupAvailable : boolean;
  public isGuarntorLookupAvailable : boolean;
  public isOccupantLookupAvailable : boolean;
  public isBusinessLookupAvailable : boolean;
  
  @ViewChild(MultiSelectMenuComponent) child;
  //readonly apiUrl = `${this.config.serverUrl}/Activity`;
  readonly formUrl = `${this.config.formBaseUrl}`;
  public clearIntervalId: any;
  public firstLoad: number = 0;
  constructor(
    private router: Router,
    private dialogService: DialogService,
    private appApi: ApplicantService,
    private activityApi: ActivityService,
    private lookupApi: LookupService,
    private utilityMethods: UtilityMethods,
    private config: AppConfigService,
    private spinner: NgxSpinnerService,
    private ngZone: NgZone,
    private route: ActivatedRoute
  ) {
    sessionStorage.setItem("previouUrl", this.router.url);
  }

  public ngOnDestroy() {
    clearInterval(this.clearIntervalId);
  }

  ngOnInit(): void {
    var brokerInfo = JSON.parse(sessionStorage.getItem("brokerInfo"));
    if(brokerInfo !== null){
      this.customerId = brokerInfo.cid;
      this.ownerId = brokerInfo.oid;
      this.brokerId = brokerInfo.brid;
    }
    var customerId = 0;
    var ownerId = 0;
    this.route.queryParams.subscribe(params => {
       customerId = Number(params['cid']);
       ownerId = Number(params['oid']);
   
      });
    if(this.ownerId == null || this.ownerId == 0){
      this.ownerId = ownerId;
    }

    if(this.customerId == null || this.customerId == 0){
      this.customerId = customerId;
    }
    if(this.brokerId == null || this.brokerId == 0){
    var brokerId = sessionStorage.getItem("brokerid");
    this.brokerId = parseInt(brokerId);
    }
    
    var verstatus = sessionStorage.getItem("verifiedstatus");
    if (verstatus == "verified" && (this.brokerId !== null && this.brokerId !== 0)) {
    } else {
      this.router.navigateByUrl(
        `/broker/verification?cid=${this.customerId}&oid=${this.ownerId}`
      );
    }

    if (this.firstLoad == 0) {
      this.loadLookups();
      this.loadData();
      this.loadApplicantTypeLookups();
    }

    this.refreshDataLoad();
  }
  encryptUsingAES256(iv, key) {
    var encrypted = CryptoJS.AES.encrypt(
      CryptoJS.enc.Utf8.parse(JSON.stringify(this.brokerId)),
      key,
      {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    );
    this.decryptUsingAES256(encrypted.toString(), key, iv);
    return encrypted.toString();
  }

  decryptUsingAES256(decString, key, iv) {
    var decrypted = CryptoJS.AES.decrypt(decString, key, {
      keySize: 128 / 8,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
  }

  public onActionPerform(selectedItem: any) {
    if (selectedItem.text === "View") {
      this.router.navigate([`/editapplictaion`], {
        queryParams: {
          aid: this.selectedDataItem.applicantId
            ? this.selectedDataItem.applicantId
            : 0,
          pid: this.dataItem.propertyId ? this.dataItem.propertyId : 0,
          uid: this.dataItem.unitId ? this.dataItem.unitId : 0,
          cid: this.customerId ? this.customerId : 0,
          tid: this.ownerId ? this.ownerId : 0,
          sid: this.dataItem.screeningGroupId
            ? this.dataItem.screeningGroupId
            : 0,
          actorId: this.brokerId,
          usname: sessionStorage.getItem("brokerName"),
        },
      });
    }
    if (
      this.selectedDataItem.applicationProgress == 90 &&
      selectedItem.text === "Continue"
    ) {
      window.open(
        `${this.config.formBaseUrl}Form/Step11?cid=${this.customerId}&tid=${this.ownerId}&pid=${this.propertyId}&uid=${this.unitId}&oid=${this.opportunityId}&preview=1&formStatusId=2&fmode=true&aid=${this.selectedDataItem.applicantId}&brid=${this.brokerId}`
      );
    } else if (selectedItem.text === "Continue") {
      let key = CryptoJS.enc.Utf8.parse("4512631236589784");
      let iv = CryptoJS.enc.Utf8.parse("4512631236589784");

      let conversionEncryptOutput = this.encryptUsingAES256(iv, key);
      window.open(
        `${this.config.formBaseUrl}?cid=${this.customerId}&tid=${this.ownerId}&pid=${this.propertyId}&uid=${this.unitId}&oid=${this.opportunityId}&preview=1&formStatusId=2&fmode=true&aid=${this.selectedDataItem.applicantId}&brId=${this.brokerId}&afu=${conversionEncryptOutput}`
      );
    }
    if (selectedItem.text === "Add Co-Applicant") {
      window.open(
        `${this.config.formBaseUrl}?cid=${this.customerId}&tid=${this.ownerId}&pid=${this.propertyId}&uid=${this.unitId}&oid=${this.opportunityId}&atid=2001&preview=1&formStatusId=2&fmode=true&maid=${this.selectedDataItem.applicantId}&brid=${this.brokerId}`
      );
    }
    if (selectedItem.text === "Add Guarantor") {
      window.open(
        `${this.config.formBaseUrl}?cid=${this.customerId}&tid=${this.ownerId}&pid=${this.propertyId}&uid=${this.unitId}&oid=${this.opportunityId}&atid=2002&preview=1&formStatusId=2&fmode=true&maid=${this.selectedDataItem.applicantId}&brid=${this.brokerId}`
      );
    }
    if (selectedItem.text === "Add Occupant") {
      window.open(
        `${this.config.formBaseUrl}?cid=${this.customerId}&tid=${this.ownerId}&pid=${this.propertyId}&uid=${this.unitId}&oid=${this.opportunityId}&atid=2003&preview=1&formStatusId=2&fmode=true&maid=${this.selectedDataItem.applicantId}&brid=${this.brokerId}`
      );
    }
    if (selectedItem.text === "Submit") {
      this.showLeaseStartConfirmation(this.selectedDataItem.applicantId);

      // this.submitApplicantByBroker(this.selectedDataItem.applicantId);
    }

    if (selectedItem.text === "Remove") {
      this.showDeleteConfirmation();
    }

    if (selectedItem.text === "Delete") {
      this.showDeleteBrokerConfirmation();
    }

  }

  public showDeleteConfirmation() {
    event!.stopPropagation();

    const dialogRef: DialogRef = this.dialogService.open({
      content: ConfirmDialogComponent,
      width: 340,
    });

    const confirmDialog = dialogRef.content.instance as ConfirmDialogComponent;
    confirmDialog.headerText = "Remove Applicant?";
    confirmDialog.confirmationMsg = `You will delete this application and all of the existing data. Are you sure you want to do this?`;
    confirmDialog.dialog = dialogRef;
    confirmDialog.cancelButtonText = "Cancel";
    confirmDialog.okButtonText = "Remove";
    confirmDialog.iconPath = "assets/images/ico_warning.png";
    confirmDialog.onOkClick = new EventEmitter<any>();

    confirmDialog.onOkClick.subscribe(() => {
      if (this.selectedDataItem.applicantId > 0) {
        this.removeApplicant(this.selectedDataItem.applicantId);
      }

      dialogRef.close();
    });
  }
  public removeApplicant(applicantId: number) {
    sessionStorage.getItem("brokerInfo");
    const actorId = this.brokerId;
    const actorName = sessionStorage.getItem("brokerName");
    let customerId = this.customerId;
    let ownerId = this.ownerId;
    this.appApi
      .removeApplicant(
        applicantId,
        this.dataItem.screeningGroupId,
        ownerId,
        customerId,
        actorId,
        actorName
      )
      .subscribe((data) => {
        this.loadData();
        this.utilityMethods.showSuccessNotification(
          "Applicant has been removed successfully"
        );
      });
  }

  public showMenu(event: MouseEvent, applicant: any, dataItem: any) {
    this.mouseEvent = event;
    this.dataItem = dataItem;
    this.selectedDataItem = applicant;
    this.opportunityId = dataItem.opportunityId;
    this.propertyId = dataItem.propertyId ? dataItem.propertyId : 0;
    this.unitId = dataItem.unitId ? dataItem.unitId : 0;
    this.actionItems = [];

    if (
      applicant.applicationProgress >= 20 &&
      applicant.applicationProgress < 95
    ) {
      this.actionItems.push({ text: "Continue" });
    }

    if (applicant.applicationProgress > 20){
      this.actionItems.push({ text: "View" });
      if(this.isCoApplicantLookupAvailable == true){
        this.actionItems.push({ text: "Add Co-Applicant" });
      }
      if(this.isGuarntorLookupAvailable == true){
        this.actionItems.push({ text: "Add Guarantor" });
      }

      if(this.isOccupantLookupAvailable == true){
        this.actionItems.push({ text: "Add Occupant" });
      }
  
    }
  
    if (applicant.applicationProgress == 95) {
      this.actionItems.push({ text: "Submit" });
    }

    if (applicant.workflowId == 0 && this.dataItem.applicants.length > 1) {
      this.actionItems.push({ text: "Remove" });
    }

    if (applicant.workflowId == 0 &&
      applicant.applicationProgress >= 20 &&
      applicant.applicationProgress <= 95
    ) {
      this.actionItems.push({ text: "Delete" });
    }
  }

  public getLeaseStatusClass(leaseStatusId: number): string {
    // if (isUnitAvailable == false) {
    //   return 'bg-burning-orange';
    // }

    switch (leaseStatusId) {
      case LeaseStatus.NotStarted:
      case LeaseStatus.NotSent:
      case LeaseStatus.Sending:
      case LeaseStatus.Rejected:
        return "bg-periwinkle-gray";
      case LeaseStatus.AwaitingCountersign:
        return "bg-sushi";
      case LeaseStatus.AwaitingTenantSign:
      case LeaseStatus.AwaitingPayment:
        return "bg-webOrange";
      case LeaseStatus.Executed:
        return "bg-SanFelix";
      default:
        return "bg-transparent";
    }
  }

  public getLeseStatusName(leaseStatusId: number): string {
    let name = LeaseStatus[leaseStatusId];
    switch (name) {
      case "NotStarted":
        //name = "Not Started"; //In Progress
        name ="";
        break;
      case "NotSent":
        //name = "Not Started"; //In Progress
        name ="";
        break;
      case "AwaitingTenantSign":
        name = "Awaiting Tenant";
        break;
      case "AwaitingPayment":
        name = "Awaiting Tenant";
        break;
      case "AwaitingCountersign":
        name = "Awaiting Countersign";
        break;
      case "Rejected":
        name = "Cancelled";
        break;
      case "Executed":
        name = "Completed";
        break;
      case "Sending":
        //name = "Not Sent";
        name ="";
        break;
      default:
        name = name;
    }
    return name;
  }

  public getLeaseStatusPercent(leaseStatusId: number): number {
    switch (leaseStatusId) {
      case LeaseStatus.NotSent:
        return 10;
      case LeaseStatus.Sending:
        return 10;
      case LeaseStatus.NotStarted:
        return 10;
      case LeaseStatus.AwaitingTenantSign:
        return 50;
      case LeaseStatus.AwaitingPayment:
        return 50;
      case LeaseStatus.AwaitingCountersign:
        return 75;
      case LeaseStatus.Executed:
        return 100;
      case LeaseStatus.Cancelled:
        return 100;
      case LeaseStatus.Rejected:
        return 100;
      default:
        return 0;
    }
  }

  public onProgressFilter(selectedItems: any[]): void {
    this.selectedProgressItems = selectedItems.map((x) => x.value);
    this.selectedProgressItemList = selectedItems;

    
    
    const filter = this.utilityMethods.getLocalStorageItem<ScreeningFilter>(
      AppConstants.Filters.Screening
    );
    // filter!.progressItems = this.selectedProgressItems;
    // this.utilityMethods.setLocalStorageItem(AppConstants.Filters.Screening, filter);
    this.filterGridData();
  }

  public onLeaseFilter(selectedItems: any[]): void {
    var leaseStat = [];
    for (let i = 0; i < selectedItems.length; i++) {
      var csLeaseArr = selectedItems[i].value.toString().split(",");
      csLeaseArr.forEach((element) => {
        leaseStat.push(parseInt(element));
      });
    }

    this.selectedStatusItems = leaseStat; // selectedItems.map(x => x.value);
    this.selectedStatusItemsList = selectedItems; // selectedItems.map(x => x.value);
    // this.selectedStatusItems.length;
    // var text = selectedItems.map(x => x.displayText);
    // var index = text.indexOf("Not Started");
    // if (text[index] == "Not Started") {
    //   var statuses = this.selectedStatusItems[index].toString().split(",");
    //   this.selectedStatusItems.splice(index, 1);
    //   this.selectedStatusItems[++this.selectedStatusItems.length] = parseInt(statuses[0]);
    //   this.selectedStatusItems[++this.selectedStatusItems.length] = parseInt(statuses[1]);

    // }
    // index = text.indexOf("Awaiting Tenant");
    // if (text[index] == "Awaiting Tenant") {
    //   var statuses = this.selectedStatusItems[index].toString().split(",");
    //   this.selectedStatusItems.splice(index, 1);
    //   this.selectedStatusItems[++this.selectedStatusItems.length] = parseInt(statuses[0]);
    //   this.selectedStatusItems[++this.selectedStatusItems.length] = parseInt(statuses[1]);
    //   this.selectedStatusItems[++this.selectedStatusItems.length] = parseInt(statuses[2]);

    // }
    // index = text.indexOf("Cancelled");
    // if (text[index] == "Cancelled") {
    //   var statuses = this.selectedStatusItems[index].toString().split(",");
    //   this.selectedStatusItems.splice(index, 1);
    //   this.selectedStatusItems[++this.selectedStatusItems.length] = parseInt(statuses[0]);
    //   this.selectedStatusItems[++this.selectedStatusItems.length] = parseInt(statuses[1]);

    // }
    // const filter = this.utilityMethods.getLocalStorageItem<ScreeningFilter>(AppConstants.Filters.Screening);
    // filter!.progressItems = this.selectedProgressItems;
    // this.utilityMethods.setLocalStorageItem(AppConstants.Filters.Screening, filter);
    this.filterGridData();
  }

  public sortGrid(spanElement: Element, sortingFields: string[]): void {
    this.sort = [];
    const sortingSpanElements = document.getElementsByClassName("custom-sort");
    const sortDirection = spanElement.classList.length === 2 ? "asc" : "desc";

    for (var i = 0; i < sortingSpanElements.length; i++) {
      if (spanElement != sortingSpanElements[i]) {
        sortingSpanElements[i].classList.value = "k-icon custom-sort";
      }
    }

    if (spanElement.classList.length === 2) {
      spanElement.classList.add("k-i-sort-asc-sm");
    } else if (spanElement.classList.contains("k-i-sort-asc-sm")) {
      spanElement.classList.replace("k-i-sort-asc-sm", "k-i-sort-desc-sm");
    } else if (spanElement.classList.contains("k-i-sort-desc-sm")) {
      spanElement.classList.remove("k-i-sort-desc-sm");
      this.bindData();
      return;
    }

    sortingFields.forEach((field) => {
      this.sort.push({ field: field, dir: sortDirection });
    });

    this.bindData();
  }

  public labelSettings: LabelSettings = {
    position: "end",
    format: () => "",
  };

  public loadMore(): void {
    this.skip = this.skip + 10;
    this.bindData();
  }

  public onResize(event: { target: { innerHeight: number } }) {
    this.innerHeight = event.target.innerHeight - this.heightMinus;
    document
      .getElementById("dgGrid")!
      .setAttribute("height", "" + this.innerHeight + "");
  }

  public showScreeningMenu() {
    this.child.show = !this.screeningShow;
  }

  public openDetail(selectedItem: any): void {
    //this.router.navigate([`/editapplictaion`], { queryParams: { aid: this.selectedDataItem.applicantId, pid: this.unitInfo.propertyId, uid: this.unitInfo.unitId, cid: this.selectedDataItem.customerId, tid: this.tenantId, sid: this.screeningGroupId } });
    //const url = `activity/screening/${screeningGroupId}`;
    //this.router.navigate([url]);
  }

  checkData = false;
  private loadLookups(): void {
    const categories = [
      LookupCategory.ScreeningProgress,
      LookupCategory.DecisionStatus,
      LookupCategory.LeaseStatus,
    ];

    const subscription = this.lookupApi
      .getByCategoryIds(categories)
      .subscribe((response) => {
        const lookups = response.lookups as Lookup[];
        this.progressItems = lookups
          .filter((x) => x.lookupCategoryID == LookupCategory.ScreeningProgress)
          .map((l) => {
            if (
              l.name != "Invite Sent" &&
              l.name != "Paid" &&
              l.name != "Have Issues"
            ) {
              return {
                displayText: this.changeScreeningStatusText(
                  Number(l.keyValue1)
                ),
                value: Number(l.keyValue1),
              };
            }
          });
        this.progressItems = this.progressItems.filter(function (element) {
          return element !== undefined;
        });
        var indexToRemove = this.progressItems.findIndex(
          (x) => x.displayText == "Missing Documents"
        );
        this.progressItems.splice(indexToRemove, 1);
        indexToRemove = this.progressItems.findIndex(
          (x) => x.displayText == "Issue"
        );
        this.progressItems.splice(indexToRemove, 1);
        // let index = this.progressItems.findIndex(x => x.displayText == 'Invite Sent');

        // if (index > -1) {

        //   this.progressItems.splice(index, 1);
        // }
        // let index1 = this.progressItems.findIndex(x => x.displayText == 'Paid');
        // if (index1 > -1) {
        //   this.progressItems.splice(index1, 1);
        // }

        var abclookus = lookups.filter(
          (x) => x.lookupCategoryID == LookupCategory.LeaseStatus
        );
        // for (let index = 0; index < abclookus.length; index++) {
        //   const element = abclookus[index];
        //   var description = element.name;
        //   description = description.replace(/\s/g, '');
        //   var statusid = LeaseStatus[description as keyof typeof LeaseStatus]
        //   this.leaseStatuses.push({
        //     displayText: description,
        //     value: Number(statusid)
        //   });
        // }

        // this.leaseStatuses.push({
        //   displayText: "Not Started", //"In Progress",
        //   value: LeaseStatus.NotStarted + "," + LeaseStatus.NotSent,
        // });
        this.leaseStatuses.push({
          displayText: "Awaiting Tenant",
          value:
            LeaseStatus.AwaitingTenantSign +
            "," +
            LeaseStatus.AwaitingPayment 
            // +
            // "," +
            // LeaseStatus.AwaitingCountersign,
        });
        // this.leaseStatuses.push({
        //   displayText: "Awaiting Payment",
        //   value: LeaseStatus.AwaitingPayment
        // });
        this.leaseStatuses.push({
          displayText: "Awaiting Countersign",
          value: LeaseStatus.AwaitingCountersign
        });
        this.leaseStatuses.push({
          displayText: "Completed",
          value: LeaseStatus.Executed,
        });
        this.leaseStatuses.push({
          displayText: "Cancelled",
          value: LeaseStatus.Cancelled + "," + LeaseStatus.Rejected,
        });
        // Awaiting Tenant Sign

        this.initialSelectedLeaseStatuses = this.leaseStatuses.filter((x) =>
          this.selectedLeaseStatuses.includes(Number(x.lookupId))
        );
        this.statusItems = lookups
          .filter(
            (x) =>
              x.lookupCategoryID == LookupCategory.DecisionStatus &&
              x.lookupId != DecisionStatus.Approved
          )
          .map((l) => {
            if (l.name == "Pending") {
              return {
                displayText: "Recommendation: " + l.name,
                value: RecommendationStatus.Pending,
              };
            } else {
              return {
                displayText: l.name,
                value: l.lookupId,
              };
            }
          });

        this.statusItems.push({
          displayText: `Recommendation: ${
            RecommendationStatus[RecommendationStatus.Deny]
          }`,
          value: RecommendationStatus.Deny,
        });

        this.statusItems.push({
          displayText: `Recommendation: ${
            RecommendationStatus[RecommendationStatus.Approve]
          }`,
          value: RecommendationStatus.Approve,
        });

        this.statusItems.push({
          displayText: `Recommendation: Maybe`,
          value: RecommendationStatus.MayBe,
        });

        this.statusItems.sort((a, b) =>
          a.displayText > b.displayText ? 1 : -1
        );

        this.initialSelectedProgressItems = this.progressItems.filter((x) =>
          this.selectedProgressItems.includes(x.value)
        );
        this.initialSelectedStatusItems = this.statusItems.filter((x) =>
          this.selectedStatusItems.includes(x.value)
        );
        this.checkData = true;
      });

    this.subscriptions.push(subscription);
  }

  public getRowClassesForRecommendation(
    recommendedStatusId: number,
    decisionStatusId: number
  ): string[] {
    if (decisionStatusId == DecisionStatus.Denied) {
      return ["tbRow", "deny"];
    }

    switch (recommendedStatusId) {
      case RecommendationStatus.Approve:
        return ["tbRow", "complete"];
      case RecommendationStatus.Deny:
        return ["tbRow", "Screened"];
      case RecommendationStatus.MayBe:
        return ["tbRow", "maybe"];
      case RecommendationStatus.Pending:
        return ["tbRow", "inprogress"];
      default:
        return [];
    }
  }
  public getLeaseStatusDescription(leaseStatusDescription: string): string {
    switch (leaseStatusDescription) {
      case "NotStarted":
        return "Not Started";
      case "NotSent":
        return "Not Sent";
      case "AwaitingTenantSign":
        return "Awaiting TenantSign";
      case "AwaitingPayment":
        return "Awaiting Payment";
      case "AwaitingCountersign":
        return "Awaiting Countersign";
      case "Executed":
        return "Completed";
      case "Rejected":
        return "Cancelled";
      case "Cancelled":
        return "Cancelled";
      default:
        return "";
    }
  }

  public getLeaseStatusIconPath(leaseStatusId: number): string {
    switch (leaseStatusId) {
      case LeaseStatus.NotStarted:
      case LeaseStatus.NotSent:
      case LeaseStatus.Sending:
      case LeaseStatus.Rejected:
        return "assets/images/icon_hourglass@2x.png";
      case LeaseStatus.AwaitingTenantSign:
        return "assets/images/icon_LeftCombined Shape@2x.png";
      case LeaseStatus.AwaitingPayment:
        return "assets/images/icon_round-dollar@2x.png";
      case LeaseStatus.AwaitingCountersign:
        return "assets/images/icon_Combined Shape@2x.png";
      case LeaseStatus.Cancelled:
        return "assets/images/icon_banT@2x.png";
      case LeaseStatus.Executed:
        return "assets/images/icon_Executed@2x.png";
      default:
        return "";
    }
  }
  public getProgressBarColorUpdated( leaseStatusId: number) {
       switch (leaseStatusId) {
         
         case LeaseStatus.Cancelled:
          case LeaseStatus.Rejected:
           return "progress-bruning-orange";
         default:
           return "progress-sushi";
       }
     
     
   }
  public getIconClassesForRecommendation(
    recommendedStatusId: number,
    decisionStatusId: number
  ): string[] {
    if (decisionStatusId == DecisionStatus.Denied) {
      return ["fas", "fa-ban"];
    }

    switch (recommendedStatusId) {
      case RecommendationStatus.Approve:
        return ["fas", "fa-star"];
      case RecommendationStatus.Deny:
        return ["fas", "fa-ban"];
      case RecommendationStatus.MayBe:
        return ["far", "fa-star"];
      case RecommendationStatus.Pending:
        return ["fas", "fa-hourglass-half"];
      default:
        return [];
    }
  }

  public getAppProgress(appProgress: number): string {
    switch (appProgress) {
      case ScreeningProgress.Lead:
        return `${appProgress}% - Lead`;
      case ScreeningProgress.InviteSent:
        return `${appProgress}% - Invite Sent`;
      case ScreeningProgress.InProgress:
        return `${appProgress}% - In Progress`; //
      case ScreeningProgress.Paid:
        return `${appProgress}% - Paid`;
      case ScreeningProgress.ConflictingData:
        return `${appProgress}% - Issue`; //Conflicting Data
      case ScreeningProgress.MissingDocments:
        return `${appProgress}% - Missing Documents`;
      case ScreeningProgress.Screening:
        return `${appProgress}% - Not Submitted`;
      case ScreeningProgress.Screened:
        return `${appProgress}% - Submitted`;
      default:
        return `${appProgress}%`;
    }
  }

  public changeScreeningStatusText(appProgress: number): string {
    switch (appProgress) {
      case ScreeningProgress.Lead:
        return `Lead`;
      case ScreeningProgress.InviteSent:
        return `Invite Sent`;
      case ScreeningProgress.InProgress:
        return `In Progress`;
      case ScreeningProgress.Paid:
        return `Paid`;
      case ScreeningProgress.ConflictingData:
        return `Issue`; //Conflicting Data
      case ScreeningProgress.MissingDocments:
        return `Missing Documents`;
      case ScreeningProgress.Screening:
        return `Not Submitted`;
      case ScreeningProgress.Screened:
        return `Submitted`;
      default:
        return `${appProgress}%`;
    }
  }

  public getScreenStatus(screenStatus: number): string {
    switch (screenStatus) {
      case 4:
        return ` - Issue`;

      default:
        return ``;
    }
  }

  public submitApplicantByBroker(
    applicantId: any,
    confirmDialog: ConfirmDialogComponent
  ) {
    let isAllScreened = true;
    this.dataItem.applicants.forEach((element) => {
      if (element.applicationProgress < 95) {
        isAllScreened = false;
        confirmDialog.closeDialog();
        this.utilityMethods.showErrorNotification(
          "Please complete all data before submitting."
        );
        return false;
      }
    });
    if (isAllScreened) {
      let applicants = "";
      this.dataItem.applicants.forEach((element) => {
        applicants += element.applicantId + ",";
      });

      const subscription = this.appApi
        .SubmitApplicantByBroker(applicants, this.ownerId)
        .subscribe((data) => {
          confirmDialog.closeDialog();
          this.utilityMethods.showSuccessNotification(
            "Application submitted successfully"
          );
          this.loadData();
        });

      this.subscriptions.push(subscription);
    }
  }

  public loadData() {
    const subscription = this.activityApi
      .getScreeningActivitiesByBrokerId(
        this.ownerId,
        this.customerId,
        this.brokerId
      )
      .subscribe((data) => {
        this.setData(data);
        this.spinner.hide();
      });

    this.subscriptions.push(subscription);
  }

  private setData(data: any) {
    this.gridData = data.activities;
    this.gridFilteredData = data.activities;

    this.filterGridData();
  }

  public onKeywordFilter(): void {
    // const filter = this.utilityMethods.getLocalStorageItem<ScreeningFilter>(AppConstants.Filters.Screening);
    // filter!.searchKey = this.txtSearch.nativeElement.value;
    // this.utilityMethods.setLocalStorageItem(AppConstants.Filters.Screening, filter);
    this.filterGridData();
  }

  private filterGridData() {
    const searchText = this.txtSearch.nativeElement.value;
    this.searchTxt = searchText;

    this.gridFilteredData = this.gridData;

    for (let activity of this.gridData) {
      let keepActivityItem =
        this.selectedStatusItems.length === 0 ||
        this.selectedStatusItems.includes(activity.decisionStatusId) ||
        this.selectedStatusItems.includes(activity.recommendedStatusId) ||
        this.selectedStatusItems.includes(activity.leaseStatusId);

      if (keepActivityItem) {
        keepActivityItem =
          !searchText || this.searchTextValue(searchText, activity);
      }

      if (keepActivityItem) {
        for (let applicant of activity.applicants) {
          keepActivityItem =
            this.selectedProgressItems.length === 0 ||
            this.selectedProgressItems.includes(applicant.applicationProgress);

          if (keepActivityItem) {
            break;
          }
        }
      }

      if (keepActivityItem === false) {
        this.gridFilteredData = this.gridFilteredData.filter(
          (x) => x.screeningGroupId !== activity.screeningGroupId
        );
      }
    }

    this.countShownInTab =
      !searchText &&
      this.selectedStatusItems.length === 0 &&
      this.selectedProgressItems.length === 0
        ? this.gridFilteredData.filter((x) => x.decisionStatusId != 12053)
            .length
        : this.gridFilteredData.length;

    this.bindData();
  }

  private searchTextValue(searchValue: string, activity: any) {
    searchValue = searchValue.toLowerCase();

    var isFound =
      activity.propertyName.toLowerCase().indexOf(searchValue) > -1 ||
      activity.propertyAddress.toLowerCase().indexOf(searchValue) > -1 ||
      (activity.unitNumber != null &&
        activity.unitNumber.toLowerCase().indexOf(searchValue) > -1);

    if (isFound === false) {
      for (let applicant of activity.applicants) {
        isFound =
          applicant.fullName.toLowerCase().indexOf(searchValue) > -1 ||
          applicant.email.toLowerCase().indexOf(searchValue) > -1 ||
          applicant.brokerFullName.toLowerCase().indexOf(searchValue) > -1 ||
          applicant.brokerageName.toLowerCase().indexOf(searchValue) > -1;

        if (isFound) {
          break;
        }
      }
    }

    return isFound;
  }

  private bindData(): void {
    this.gridView = {
      data: orderBy(this.gridFilteredData, this.sort).slice(
        0,
        this.skip + this.pageSize
      ),
      total: this.gridData.length,
    };
  }

  public onChangeAppartment() {
    this.openChangeAppartmentDialog();
  }

  private openChangeAppartmentDialog(): any {
    const dialogRef = this.dialogService.open({
      content: BrokerApplicationComponent,
      width: 700,
      maxHeight: 630,
      autoFocusedElement: "btn-cancel",
    });

    const instance = dialogRef.content.instance;
    instance.addEditDialog = dialogRef;
    // instance.screeningGroupId = this.screeningGroupId;
    // instance.unitId = this.unitInfo.unitId;
    // instance.propertyId = this.unitInfo.propertyId;

    dialogRef.result.subscribe((result) => {
      if (result instanceof DialogCloseResult && instance.isReloadRequire) {
        // this.loadUnitInfo();
        // this.loadScreeningInfo();
        // this.loadApplicantsInfo();
      }
    });

    return instance;
  }
  public showleasingProgressMenu() {
    this.child.show = !this.leasingShow;
  }

  public showLeaseStartConfirmation(applicantId) {
    const dialogRef: DialogRef = this.dialogService.open({
      content: ConfirmDialogComponent,
      width: 340,
    });

    const confirmDialog = dialogRef.content.instance as ConfirmDialogComponent;
    confirmDialog.headerText = "Nice Work!";
    confirmDialog.confirmationMsg =
      "Glad you are ready to send this application to our leasing team for review. We will get back to you shortly with a decision."; //'Your application has been sent. The leasing team will review the information you have submitted and get back to you shortly.';
    confirmDialog.cancelButtonText = "Not Now";
    confirmDialog.okButtonText = "Submit";
    confirmDialog.iconPath = "assets/images/thumb-up@2x.png";

    confirmDialog.dialog = dialogRef;
    confirmDialog.onOkClick = new EventEmitter<any>();

    confirmDialog.onOkClick.subscribe(() => {
      this.submitApplicantByBroker(applicantId, confirmDialog);
      //confirmDialog.closeDialog();
    });
  }

  public refreshDataLoad() {
    // this.ngZone.runOutsideAngular(() => {
    this.clearIntervalId = setInterval(() => {
      this.loadData();
      if (this.firstLoad != 0) {
        this.spinner.hide();
      }
    }, 6000);
    //});
  }
  private loadApplicantTypeLookups() {
    const sub = this.lookupApi.getLookupListByCategoryIds(LookupCategory.ApplicantType , this.ownerId).subscribe((response) => {
      const lookups = response.lookups as Lookup[];
      let isValueExist: boolean;
      this.applicantTypeLookups = lookups.filter((x) => x.lookupCategoryID == LookupCategory.ApplicantType);
      
     var applicant =  this.applicantTypeLookups.filter(x=>x.lookupId == 2000);
      this.isApplicantLookupAvailable = applicant.length > 0 ? true : false;

      var coApplicant =  this.applicantTypeLookups.filter(x=>x.lookupId == 2001);
      this.isCoApplicantLookupAvailable = coApplicant.length > 0 ? true : false;

      var guarantor  =  this.applicantTypeLookups.filter(x=>x.lookupId == 2002);
      this.isGuarntorLookupAvailable = guarantor.length > 0 ? true : false;

      var occupant  =  this.applicantTypeLookups.filter(x=>x.lookupId == 2003);
      this.isOccupantLookupAvailable = occupant.length > 0 ? true : false;

      var business  =  this.applicantTypeLookups.filter(x=>x.lookupId == 2004);
      this.isBusinessLookupAvailable = business.length > 0 ? true : false;

    });

  }

  public showDeleteBrokerConfirmation() {
    event!.stopPropagation();

    const dialogRef: DialogRef = this.dialogService.open({
      content: ConfirmDialogComponent,
      width: 340,
    });

    const confirmDialog = dialogRef.content.instance as ConfirmDialogComponent;
    confirmDialog.headerText = "Please confirm";
    confirmDialog.confirmationMsg = `Are you sure you want to delete ${this.selectedDataItem.fullName}'s application? This can not be undone.`;
    confirmDialog.dialog = dialogRef;
    confirmDialog.cancelButtonText = "No";
    confirmDialog.okButtonText = "Yes";
    confirmDialog.iconPath = "assets/images/ico_warning.png";
    confirmDialog.onOkClick = new EventEmitter<any>();

    confirmDialog.onOkClick.subscribe(() => {
      if (this.selectedDataItem.applicantId > 0) {
        this.removeBrokerApplicant(this.selectedDataItem.applicantId);
      }

      dialogRef.close();
    });
  }
  public removeBrokerApplicant(applicantId: number) {
    let ownerId = this.ownerId;
    this.appApi
      .removeBrokerApplicant(
        applicantId,
        ownerId
      )
      .subscribe((data) => {
        this.loadData();
        this.utilityMethods.showSuccessNotification(
          "Applicant deleted successfully"
        );
      });
  }
}
