<div class="confirm-dialog">
    <div *ngIf="isReasonShown" class="btn-cancel" (click)="closeDialog()">
        <span class="fas fa-times"></span>
    </div>
    <div class="confirm-dialog-box">
        <div class="text-center">
            <div class="icon-info">
                <img [src]="iconPath" alt="!">
            </div>
            <div class="text-header">
                {{headerText}}
            </div>
            <div class="text-msg">
                {{confirmationMsg}}
            </div>
        </div>
    </div>
    <div class="confirm-dialog-box" *ngIf="isReasonShown">
        <div class="text-center">
            <div class="text-header">
                Void Reason
            </div>
            <div class="text-msg">
                <kendo-textbox-container>
                    <textarea kendoTextArea style="width: 400px; height:100px;" [(ngModel)]="txtReason"></textarea>
                </kendo-textbox-container>
            </div>
            <div *ngIf="isReasonShown && isInvalidReason" class="k-tooltip-validation">
                <p class="pl-4">Please enter void reason.</p>
            </div>
        </div>
    </div>

    <div class="btnArea border-top">
        <div *ngIf="showCancelButton && !isReasonShown" class="leftArea">
            <button type="button" kendoButton class="btn-secondary" id="btnCancel" (click)="onCancel($event)">{{cancelButtonText}}</button>
        </div>
        <div *ngIf="showCancelButton && isReasonShown" class="leftArea">
            <button type="button" kendoButton class="btn-primary" id="btnCancelConfirm" (click)="onCancel($event)">{{cancelButtonText}}</button>
        </div>
        <div *ngIf="showOkButton" class="RightArea">
            <button type="button" kendoButton class="btn-primary" id="btnYes" primary="true" (click)="onOk($event)">
                    {{okButtonText}}
            </button>
        </div>
    </div>
</div>